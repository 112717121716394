import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { ContactUs } from "@red-build/leadcentre";
import { BASE_URI } from '../shared/Constants';
import DealsFilter from "../SpecialDeals/DealsFilter";
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px 15px",
  },
  innerHolder: {
    maxWidth: "1170px",
    width: "100%",
  },
  CardHolder: {
    background: "white",
    boxShadow: "0 0 5px 0 #d7d6d6 ",
    padding: "30px 0px",
    marginTop: "20px",
    marginBottom: '20px',
  },
  CardHolder50: {
    background: "white",
    boxShadow: "0 0 5px 0 #d7d6d6 ",
    padding: "30px 0px",
    marginTop: "0px 10px",
    marginTop: "20px",
    width: 'calc(100%/2 - 10px)',
    '@media (max-width: 959px)': {
      width: 'calc(100%)',
    }
  },
  cardContent: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    padding: "0px 30px",
    flexDirection: "column",
  },
  cardContent50: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    padding: "0px 0px",
    padding: '20px',
    flexDirection: "column",
  },
  title: {
    borderLeft: ({ websiteColors }) => `3px solid${websiteColors.accentColor}`,
    padding: "5px 30px",
    alignItems: 'center',
    display: "flex",
    justifyContent: "space-between",
  },
  cardContentItem: {
    display: 'flex',
    padding: '10px 0px',
    borderBottom: '2px #8080802e solid',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  CardHolderWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  accent: {
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
  },
  tc: {
    padding: '10px',
    paddingTop: '20px'
  },
  titleTop: {
    fontSize: '20px',
    fontWeight: 'unset'
  },
  Link: {
    border: "none",
    borderRadius: '50px',
    padding: "10px 40px",
    background: ({ websiteColors }) => `${websiteColors.accentColor}`,
    color: "white",
    cursor: "pointer",
    "&:hover": {
      background: "  #9b161c",
      boxShadow: "0 0 5px 0  #9b161c",
    },
  },
  titleFlex: {
    display: "flex",
    alignItems: "center",
  },
  divider: {
    margin: '0px 10px',
    background: ({ websiteColors }) => `${websiteColors.accentColor}`,
    minWidth: '3px',
    minHeight: '35px'
  },
  headline: {
    fontWeight: 'bold',
  }
}));

const DetailedSpecialDeals = () => {
  let { websiteColors } = useContext(DealerContext);

  const [offer, setOffer] = useState();
  const history = useHistory();
  const params = useParams();
  const sortDirection = 'Desc';
  const pageNumber = 1;
  const classes = useStyles({ websiteColors });
  const { deals } = DealsFilter(pageNumber, sortDirection)

  useEffect(() => {
    setOffer(deals.filter((v => v.id == params?.stockId))[0]);
  }, [params, deals]);

  var formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 2
  });

  const handleRedirectFinance = (vehicle) => {
    let data = {
      vehicle: vehicle,
    }
    history.push(`/apply-for-finance`, data)
  }

  return (
    <div className={classes.root}>
      <div className={classes.innerHolder}>
        {offer?.isCashDeal ? (
          <div>
            <div className={classes.CardHolder}>
              <div className={classes.title}>
                <div className={classes.titleFlex}>
                  <Typography variant="h2" className={classes.titleTop}>
                    {offer?.make} {offer?.model}{' '}
                  </Typography>
                  <div className={classes.divider} />
                  <Typography variant="h4" className={classes.titleTop}>
                    {formatter.format(offer?.price)}
                  </Typography>
                </div>
                <button
                  type="button"
                  className={classes.Link}
                  onClick={() => {
                    handleRedirectFinance(offer);
                  }}
                >
                  Pre-Qualify
                </button>
              </div>
            </div>
            <img alt="" src={offer?.imageUrl} width="100%" />
            {/* Is cash Deal */}
            <div className={classes.CardHolderWrap}>
              <div className={classes.CardHolder50}>
                <div className={classes.cardContent}>
                  <div>
                    <p className={classes.titleTop}>{offer?.headline}</p>
                    <hr />
                    <Typography variant="h6" className={classes.introParagraph}>
                      {offer?.introParagraph}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={classes.CardHolder50}>
                <div className={classes.title}>
                  <Typography variant="h4" className={classes.titleTop}>
                    Contact Us
                  </Typography>
                </div>
                <div className={classes.cardContent50}>
                  <ContactUs
                    dealerId={offer?.dealerId}
                    base_uri={BASE_URI}
                    leadTypeId={1}
                    offerId={offer?.offerId}
                    dealerName={offer?.dealershipName}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={classes.CardHolder}>
              <div className={classes.title}>
                <div className={classes.titleFlex}>
                  <Typography variant="h4" className={classes.titleTop}>
                    {offer?.make} {offer?.model}{' '}
                  </Typography>
                  <div className={classes.divider} />
                  <Typography variant="h4" className={classes.titleTop}>
                    {formatter.format(offer?.price)} *PM
                  </Typography>
                </div>
                <button
                  type="button"
                  className={classes.Link}
                  onClick={() => {
                    handleRedirectFinance(offer);
                  }}
                >
                  Pre-Qualify
                </button>
              </div>
            </div>
            <img alt="" src={offer?.imageUrl} />
            {/* Is finance Deal */}
            <div className={classes.CardHolderWrap}>
              <div className={classes.CardHolder50}>
                <div className={classes.cardContent}>
                  <div>
                    <p className={classes.headlineText}>{offer?.headline}</p>
                    <hr />
                    <Typography variant="h6" className={classes.introParagraph}>
                      {offer?.introParagraph}
                    </Typography>
                  </div>
                  <div className={classes.cardContentItem}>
                    <Typography variant="h6" className={classes.titleTop}>
                      Purchase Price
                    </Typography>
                    <Typography variant="body1" className={classes.accent}>
                      {formatter.format(offer?.price)}
                    </Typography>
                  </div>
                  <div className={classes.cardContentItem}>
                    <Typography variant="h6" className={classes.titleTop}>
                      Monthly Instalment
                    </Typography>
                    <Typography variant="body1" className={classes.accent}>
                      R {offer?.monthlyInstallment}
                    </Typography>
                  </div>
                  <div className={classes.cardContentItem}>
                    <Typography variant="h6" className={classes.titleTop}>
                      Term
                    </Typography>
                    <Typography variant="body11" className={classes.accent}>
                      {offer?.term}
                    </Typography>
                  </div>
                  <div className={classes.cardContentItem}>
                    <Typography variant="h6" className={classes.titleTop}>
                      Linked Interest Rate
                    </Typography>
                    <Typography variant="body1" className={classes.accent}>
                      {offer?.rate}
                    </Typography>
                  </div>
                  <div className={classes.cardContentItem}>
                    <Typography variant="h6" className={classes.titleTop}>
                      Deposit
                    </Typography>
                    <Typography variant="body1" className={classes.accent}>
                      {offer?.deposit} %
                    </Typography>
                  </div>
                  <div className={classes.cardContentItem}>
                    <Typography variant="h6" className={classes.titleTop}>
                      Balloon Payment
                    </Typography>
                    <Typography variant="body1" className={classes.accent}>
                      {offer?.balloonPayment} %
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={classes.CardHolder50}>
                <div className={classes.title}>
                  <Typography variant="h4" className={classes.titleTop}>
                    Contact Us
                  </Typography>
                </div>
                <div className={classes.cardContent50}>
                  <ContactUs
                    dealerId={offer?.dealerId}
                    base_uri={BASE_URI}
                    leadTypeId={1}
                    offerId={offer?.offerId}
                    dealerName={offer?.dealershipName}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Typography variant="body1" className={classes.tc}>
        {offer?.termsAndConditions}
      </Typography>
    </div>
  );
};

export default DetailedSpecialDeals;



const data = [
    {
        blog_id:1,
        title:"Dorado Ski Boat Club Golf Day – Goldfields Private Golf Club",
        img:'',
        date:'05/05',
        content1:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,


        content2Heading:
        `
        Lorem ipsum dolor sit amet consectetur
        `,
        content2:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,
        content3Heading:
        `
        Lorem ipsum dolor sit amet consectetur
        `,
        content3:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,
    
    },
    {
        blog_id:1,
        title:" March – NAMPO Harvest Show – NAMPO Park, Bothaville",
        img:'',
        date:'16-19',
        content1:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,


        content2Heading:
        `
        Lorem ipsum dolor sit amet consectetur
        `,
        content2:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,
        content3Heading:
        `
        Lorem ipsum dolor sit amet consectetur
        `,
        content3:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,
    
    },
    {
        blog_id:1,
        title:" Fundraiser Golf Day – Waterpan Golf Club",
        img:'',
        date:'10/06',
        content1:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,


        content2Heading:
        `
        Lorem ipsum dolor sit amet consectetur
        `,
        content2:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,
        content3Heading:
        `
        Lorem ipsum dolor sit amet consectetur
        `,
        content3:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,
    
    },
    {
        blog_id:1,
        title:" Rant & Dal Prisma Golfday – Krugersdorp Golf Club",
        img:'',
        date:'22/06',
        content1:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,


        content2Heading:
        `
        Lorem ipsum dolor sit amet consectetur
        `,
        content2:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,
        content3Heading:
        `
        Lorem ipsum dolor sit amet consectetur
        `,
        content3:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,
    
    }
    ,
    {
        blog_id:1,
        title:"Laerskool Randfontein Rugby Day",
        img:'',
        date:'23/06',
        content1:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,


        content2Heading:
        `
        Lorem ipsum dolor sit amet consectetur
        `,
        content2:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,
        content3Heading:
        `
        Lorem ipsum dolor sit amet consectetur
        `,
        content3:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,
    
    },
    {
        blog_id:1,
        title:"Sionspoort Vleisfees",
        img:'',
        date:'05/08 ',
        content1:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,


        content2Heading:
        `
        Lorem ipsum dolor sit amet consectetur
        `,
        content2:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,
        content3Heading:
        `
        Lorem ipsum dolor sit amet consectetur
        `,
        content3:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,
    
    },
    {
        blog_id:1,
        title:"Fundraiser Golf Day – Krugersdorp Golf Club",
        date:'10/08',
        img:'',
        content1:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,


        content2Heading:
        `
        Lorem ipsum dolor sit amet consectetur
        `,
        content2:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,
        content3Heading:
        `
        Lorem ipsum dolor sit amet consectetur
        `,
        content3:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,
    
    }
    ,
    {
        blog_id:1,
        title:"Hervormde Kerk Golf Day – Goldfields Private Gof Club",
        img:'',
        date:'25/08',
        content1:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,


        content2Heading:
        `
        Lorem ipsum dolor sit amet consectetur
        `,
        content2:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,
        content3Heading:
        `
        Lorem ipsum dolor sit amet consectetur
        `,
        content3:
        `
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, earum. Ad iusto, placeat explicabo saepe exercitationem esse labore veritatis facilis autem voluptate nostrum, harum repudiandae porro adipisci debitis voluptatem inventore corporis reiciendis in sed. Nihil, repudiandae at quos esse, incidunt fugit nostrum vero quisquam adipisci fugiat vitae laudantium cum sequi ratione debitis repellat, facilis facere quibusdam soluta? Ex ab provident alias, quisquam eaque libero reiciendis saepe debitis, quod repellat explicabo similique pariatur, aperiam eum quos voluptatem eveniet consectetur numquam earum a temporibus perferendis quam voluptatum. Voluptates sequi distinctio quis consequatur eius ea quos, repellat, soluta nesciunt omnis doloribus veritatis rerum.
        `,
    
    }
];

export default data;

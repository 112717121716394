import React, { useContext } from "react";
import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import DashboardContainer from './components/MainContainer'
import DashboardContainerFord from './components/MainContainerFord'
import DashboardContainerHaval from './components/MainContainerHaval';
import DashboardContainerGwm from './components/MainContainerGwm';
import DashboardContainerChery from './components/MainContainerChery';
import DashboardContainerMazda from './components/MainContainerMazda';
import DashboardContainerMahindra from './components/MainContainerMahindra';
import DashboardContainerMahindraRise from './components/MainContainerMahindraRise';
import Mazda from '../src/components/shared/assets/mazda.ico'
import Gwm from '../src/components/shared/assets/gwm.ico'
import Chery from '../src/components/shared/assets/chery.ico'
import Haval from '../src/components/shared/assets/haval.ico'
import Mahindra from '../src/components/shared/assets/mahindra.ico'
import Ford from '../src/components/shared/assets/ford.ico'
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { DealerContext } from "./components/shared/contexts/DealerContext";
import { MakeProvider } from "./components/shared/contexts/MakeContext";
import { CompareProvider } from './components/shared/contexts/CompareContext';
import { FavouriteProvider } from './components/shared/contexts/FavouriteContext';
import { VehicleProvider } from './components/shared/contexts/VehicleContext';
function App() {
  const { globalDealer } = useContext(DealerContext)
  localStorage.clear()
  const parsedData = window.location.pathname.split("/");
  var domain = "/"

  if (parsedData.length > 1 && parsedData[1] == "dealer") {
    domain = "/dealer/" + parsedData[2];
  }

  const favicon = document.getElementById("favi")

  function changeFavi(img) {
    favicon.setAttribute("href", img)

  }
  if (globalDealer?.name?.toLowerCase()?.includes("ben morgenrood ford - carletonville")) {
    document.title = "Bma Ford - Carletonville"
    changeFavi(Ford)
  }

  if (globalDealer?.name?.toLowerCase()?.includes("ben morgenrood ford - randfontein")) {
    document.title = "Bma Ford - Randfontein"
    changeFavi(Ford)
  }
  if (globalDealer?.name?.toLowerCase()?.includes("ben morgenrood haval - randfontein")) {
    document.title = "Bma Haval - Randfontein"
    changeFavi(Haval)
  }
  if (globalDealer?.name?.toLowerCase()?.includes("ben morgenrood mahindra - randfontein")) {
    document.title = "Bma Mahindra - Randfontein"
    changeFavi(Mahindra)
  }
  if (globalDealer?.name?.toLowerCase()?.includes("ben morgenrood mahindra agri - randfontein")) {
    document.title = "Bma Mahindra Agri - Randfontein"
    changeFavi(Mahindra)
  }

  if (globalDealer?.name?.toLowerCase()?.includes("ben morgenrood gwm - randfontein")) {
    document.title = "Bma Gwm - Randfontein"
    changeFavi(Gwm)
  }
  if (globalDealer?.name?.toLowerCase()?.includes("ben morgenrood chery - randfontein")) {
    document.title = "Bma Chery - Randfontein"
    changeFavi(Chery)
  }
  if (globalDealer?.name?.toLowerCase()?.includes("ben morgenrood mazda - randfontein")) {
    document.title = "Bma Mazda - Randfontein"
    changeFavi(Mazda)
  }

  return (
    <Router basename={domain}>
      <FavouriteProvider>
        <CompareProvider>
          <ThemeProvider >
            <SnackbarProvider maxSnack={3} anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}>
              <CssBaseline />
              <VehicleProvider>
                <MakeProvider>
                  {globalDealer?.name?.toLowerCase()?.includes("master") && <DashboardContainer />}
                  {globalDealer?.name?.toLowerCase()?.includes("ben morgenrood ford - randfontein") && <DashboardContainerFord />}
                  {globalDealer?.name?.toLowerCase()?.includes("ben morgenrood ford - carletonville") && <DashboardContainerFord />}
                  {globalDealer?.name?.toLowerCase()?.includes("ben morgenrood haval - randfontein") && <DashboardContainerHaval />}
                  {globalDealer?.name?.toLowerCase()?.includes("ben morgenrood mahindra - randfontein") && <DashboardContainerMahindra />}
                  {globalDealer?.name?.toLowerCase()?.includes("ben morgenrood mahindra agri - randfontein") && <DashboardContainerMahindraRise />}
                  {globalDealer?.name?.toLowerCase()?.includes("ben morgenrood gwm - randfontein") && <DashboardContainerGwm />}
                  {globalDealer?.name?.toLowerCase()?.includes("ben morgenrood chery - randfontein") && <DashboardContainerChery />}
                  {globalDealer?.name?.toLowerCase()?.includes("ben morgenrood mazda - randfontein") && <DashboardContainerMazda />}
                </MakeProvider>
              </VehicleProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </CompareProvider>
      </FavouriteProvider>
    </Router>
  );
}

export default App;

import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import data from './EventObjects';
import { useParams } from "react-router-dom";
import { DealerContext } from "../shared/contexts/DealerContext";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '100px 15px',
    "@media (max-width: 920px)": {
      padding: '0px',
    },
  },
  rootInner: {
    maxWidth: '1770px',
    margin: '0 auto',
  },
  rootInnerTop: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rootInnerTopRight: {
    width: '50%',
    "@media (max-width: 920px)": {
      width: '100%',
    },
  },
  rootInnerTopLeft: {
    width: '50%',
    "@media (max-width: 920px)": {
      width: '100%',
    },
  },
  h1: {
    padding: '1rem 20px',
    fontSize: "30px"
  },
  text: {
    padding: '10px 20px',
    fontSize: "18px"
  },
  dividerTitle: {
    backgroundColor: ({ websiteColors }) => `${websiteColors.accentColor}`,
    width: '33%',
    marginBottom: '0px',
    height: '3px',
    margin: '0rem 20px',
  },
  blogCardImg: {
    borderRadius: '5px 5px 0px 0px',
    "@media (max-width: 920px)": {
      borderRadius: '0px',
    },
  },
  textHeading: {
    padding: '0px 20px',
    fontWeight: 'bold',
    fontSize: "20px"
  }
}));

function EventDetailed() {
  const { websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });
  const [blogFound, setBlogFound] = useState()
  const params = useParams()

  useEffect(() => {
    setBlogFound(data.filter((b) => b.blog_id == params.event_id)[0])
  }, [data])

  return (
    <div className={classes.root}>
      <div className={classes.rootInner}>
        <div className={classes.rootInnerTop}>
          <div className={classes.rootInnerTopLeft}>
            <img src={blogFound?.img} width="100%" className={classes.blogCardImg} />
          </div>
          <div className={classes.rootInnerTopRight}>
            <div className={classes.h1}>{blogFound?.title}</div>
            <Divider className={classes.dividerTitle} />
            <p className={classes.text}>{blogFound?.content1}</p>
          </div>
        </div>
        <p className={classes.text}>{blogFound?.content1}</p>
        <h3 className={classes.textHeading}>{blogFound?.content2Heading}</h3>
        <p className={classes.text}>{blogFound?.content2}</p>

        <h3 className={classes.textHeading}>{blogFound?.content3Heading}</h3>
        <p className={classes.text}>{blogFound?.content3}</p>

        <h3 className={classes.textHeading}>{blogFound?.content4Heading}</h3>
        <p className={classes.text}>{blogFound?.content4}</p>

        <h3 className={classes.textHeading}>{blogFound?.content5Heading}</h3>
        <p className={classes.text}>{blogFound?.content5}</p>

        <h3 className={classes.textHeading}>{blogFound?.content6Heading}</h3>
        <p className={classes.text}>{blogFound?.content6}</p>

        <h3 className={classes.textHeading}>{blogFound?.content7Heading}</h3>
        <p className={classes.text}>{blogFound?.content7}</p>

        <h3 className={classes.textHeading}>{blogFound?.content8Heading}</h3>
        <p className={classes.text}>{blogFound?.content8}</p>

        <h3 className={classes.textHeading}>{blogFound?.content9Heading}</h3>
        <p className={classes.text}>{blogFound?.content9}</p>
      </div>
    </div>
  )
}

export default EventDetailed


function createData(partnumber, description, type, brand, vehicle, retail) {
  return { partnumber, description, type, brand, vehicle, retail };
}

const rows = [
  //       part_number    accessory                                 type         brand  model      retail_proce
createData('GWM-ST6-NBS', 'GWM STEED 6 NUDGE BAR STAINLESS 72030T', 'Nudge Bar', 'GWM', 'Steed 6', '6 866,00', '5 492,80', '1 373,20'),
createData('73035BB', 'NUDGE & OVAL CROSS MEMBER BEST OF BOTH - STEED 6', 'Nudge Bar', 'GWM', 'Steed 6', '3 309,60', '2 647,68', '661,92'),
createData('GWM-ST6-SBS', 'GWM STEED 6 SPORTS BAR STAINLESS 72031T', 'Roll Bar', 'GWM', 'Steed 6', '6 366,00', '5 092,80', '1 273,20'),
createData('73036BB', 'SPORTS ROLL BAR WITH OVAL SIDE PLATES- BEST OF BOTH -STEED 6', 'Roll Bar', 'GWM', 'Steed 6', '4 029,60', '3 223,68', '805,92'),
createData('COSTCDCS6NRB', 'STEED 6 DOUBLE CAB TONNEAU COVER - NO ROLL BAR', 'Tonneau Cover', 'GWM', 'Steed 6', '3 150,00', '2 520,00', '630,00'),
createData('GSTB1004', 'STEED 6 TOWBAR& WIRING HARNESS STEED 6', 'Towbar', 'GWM', 'Steed 6', '3 180,00', '2 544,00', '636,00'),
createData('8509500BP00XB', 'ROLL BAR (FACTORY INSTALLED)-STEED 5E', 'Roll Bar', 'GWM', 'Steed 5E', '3 412,63', '2 730,10', '682,53'),
createData('GWM-ST5FL2-NBS', 'GWM STEED 5 FACELIFT NUDGE BAR STAINLESS (FITS 2.0 VGT FACELIFT)72050T', 'Nudge Bar', 'GWM', 'Steed 5 ', '5 100,00', '4 080,00', '1 020,00'),
createData('BS-7060', 'STEED 5 FACELIFT NUDGE BAR BLACK', 'Nudge Bar', 'GWM', 'Steed 5 ', '4 275,00', '3 420,00', '855,00'),
createData('72040T', 'STEED 5 NUDGE BAR NEW 2016 MODEL (FITS VGT & MPI) GWM-ST5FL-NBS', 'Nudge Bar', 'GWM', 'Steed 5 ', '4 875,00', '3 900,00', '975,00'),
createData('BS-72052', 'STEED 5 & 5E SPORTS BAR W OVAL SIDE PLATES BLACK (FITS ALL MODELS - DC & SC)', 'Roll Bar', 'GWM', 'Steed 5 ', '5 925,00', '4 740,00', '1 185,00'),
createData('GWM-ST5-SBS-DC', 'STEED 5 & 5E SPORTS BAR W OVAL SIDE PLATES STAINLESS (FITS ALL DC MODELS)  72052T', 'Roll Bar', 'GWM', 'Steed 5 ', '6 636,00', '5 308,80', '1 327,20'),
createData('GWMALUS5DCCABPRB', 'STEED 5 DC WITH ROLL BAR AND CAB PROTECTOR ALUMINIUM FRAME CLIP ON COVER', 'Roll Bar', 'GWM', 'Steed 5 ', '3 400,00', '2 720,00', '680,00'),
createData('DCTCCP', 'D/CAB TONNEAU COVER CAB PROTECTER - GWMDCTC', 'Tonneau Cover', 'GWM', 'Steed 5 Double Cab', '990,00', '792,00', '198,00'),
createData('GWM-ST5E-NBS', 'GWM STEED 5E NUDGE BAR STAINLESS 72018T', 'Nudge Bar', 'GWM', 'Steed 5', '5 088,00', '4 070,40', '1 017,60'),
createData('BS-72060', 'STEED 5 FACELIFT NUDGE BAR BLACK', 'Nudge Bar', 'GWM', 'Steed 5', '4 275,00', '3 420,00', '855,00'),
createData('72060T', 'STEED 5 FACELIFT NUDGE BAR STAINLESS', 'Nudge Bar', 'GWM', 'Steed 5', '5 512,50', '4 410,00', '1 102,50'),
createData('BS-72061', 'STEED 5 FACELIFT TRI BUMPER PROTECTOR BLACK', 'Nudge Bar', 'GWM', 'Steed 5', '4 725,00', '3 780,00', '945,00'),
createData('72061T', 'STEED 5 FACELIFT TRI BUMPER PROTECTOR STAINLESS', 'Nudge Bar', 'GWM', 'Steed 5', '5 625,00', '4 500,00', '1 125,00'),
createData('BS-72050', 'STEED 5 NUDGE BAR BLACK (FITS 2L VGT 4X2 SX & VGT 4X4 SX)', 'Nudge Bar', 'GWM', 'Steed 5', '3 920,00', '3 136,00', '784,00'),
createData('72050T', 'STEED 5 NUDGE BAR STAINLESS (FITS 2L VGT 4X2 SX & VGT 4X4 SX)', 'Nudge Bar', 'GWM', 'Steed 5', '4 492,50', '3 594,00', '898,50'),
createData('BS-72051', 'STEED 5E NUDGE BAR W OVAL CROSS MEMBER BLACK (FITS ALL STEED MODELS', 'Nudge Bar', 'GWM', 'Steed 5', '4 500,00', '3 600,00', '900,00'),
createData('BS-72053', 'STEED 5 & 5E DOUBLE TUBE OVAL SIDE STEPS BLACK (FITS ALL DC MODELS)', 'Side Steps', 'GWM', 'Steed 5', '4 500,00', '3 600,00', '900,00'),
createData('GWM-ST5-SSS-DC', 'STEED 5 & 5E DOUBLE TUBE OVAL SIDE STEPS STAINLESS (FITS ALL DC MODELS)  72053T', 'Side Steps', 'GWM', 'Steed 5', '6 225,00', '4 980,00', '1 245,00'),
createData('GWMALUSTDCCABP', 'ALUMINIUM FRAME CLIP ON COVER GWM STEED 5 DC WITH CAB PROTECTOR NO ROLL BAR', 'Tonneau Cover', 'GWM', 'Steed 5', '3 400,00', '2 720,00', '680,00'),
createData('GWMALUS5LWBPLAIN', 'ALUMINIUM FRAME CLIP ON COVER GWM STEED 5 SINGLE CAB NO ROLL BAR AND NO CAB PROTECTOR', 'Tonneau Cover', 'GWM', 'Steed 5', '3 900,00', '3 120,00', '780,00'),
createData('GWMALUSLNCRBARTAV', 'ALUMINIUM FRAME CLIP ON COVER GWM STEED 5 SINGLE CAB WITH ARTAV ROLL BAR NO CAB PROTECTOR', 'Tonneau Cover', 'GWM', 'Steed 5', '3 388,00', '2 710,40', '677,60'),
createData('GWMALUCTCPRC', 'BACK CORNER FOR ALUMINIUM FRAME', 'Tonneau Cover', 'GWM', 'Steed 5', '300,00', '240,00', '60,00'),
createData('DCTCRB', 'D/CAB TONNEAU COVER ROLL BAR GWMDCTCRB', 'Tonneau Cover', 'GWM', 'Steed 5 Double Cab', '1 210,00', '968,00', '242,00'),
createData('GWMROPES5EBRB', 'STEED 5/5E D/C ROPE TONNEAU COVER - BLACK OVAL ROLL BAR  .', 'Tonneau Cover', 'GWM', 'Steed 5', '1 720,00', '1 376,00', '344,00'),
createData('ALUSTDCRBARTAV', 'STEED DOUBLE CAB TONNEAU COVER ROLL BAR', 'Tonneau Cover', 'GWM', 'Steed 5', '3 400,00', '2 720,00', '680,00'),
createData('ALUSTDCRBARTNG', 'STEED DOUBLE CAB TONNEAU COVER W/O CAB GUARD WITH ARTAV R/B', 'Tonneau Cover', 'GWM', 'Steed 5', '3 388,00', '2 710,40', '677,60'),
createData('GWMSLTCRBARTNG', 'STEED S/C W/H R/B NO C/GUARD TONNEAU  - ROPE', 'Tonneau Cover', 'GWM', 'Steed 5 Single Cab', '2 057,00', '1 645,60', '411,40'),
createData('GWMALUSLTCCAB', 'STEED SINGLE CAB TONNEAU COVER CAB GUARD WITH ALUMINIUM RAILS', 'Tonneau Cover', 'GWM', 'Steed 5', '3 900,00', '3 120,00', '780,00'),
createData('GWMSLTCCAB', 'STEED SINGLE CAB TONNEAU COVER CAB PROTECTOR', 'Tonneau Cover', 'GWM', 'Steed 5', '3 621,32', '2 897,06', '724,26'),
createData('GWMALUSTDC', 'STEED TONNEAU COVER NO CAB PROTEC NO ROLLBAR DOUBLE CAB STEED', 'Tonneau Cover', 'GWM', 'Steed 5', '3 400,00', '2 720,00', '680,00'),
createData('TAC 598CPA', 'NEW LEVEL HARNESS FOR GW05 AND GW12  STEED 5/6', 'Towbar', 'GWM', 'Steed 5', '763,63', '610,90', '152,73'),
createData('15GW05CPA', 'STEED REAR STEP TOW BAR FITS ALL STEEDS INCLUDING STEED6', 'Towbar', 'GWM', 'Steed 5', '4 412,61', '3 530,09', '882,52'),
createData('14GW12CPA', 'STEED UNDER MOUNT TOW BAR & WIRING HARNESS INCLUDES STEED6', 'Towbar', 'GWM', 'Steed 5', '4 966,36', '3 973,09', '993,27'),
createData('10108', 'STEED 3 SINGLE CAB SIDE STEPS 2.2MPI', 'Side Steps', 'GWM', 'Steed 3', '2 640,00', '2 112,00', '528,00'),
createData('CSJP02834X', 'P- SERIES GREEN BULLET SHAPE VACUUM CUP-GWM BRAND', 'Brand Merchandise', 'GWM', 'P-Series', '210,48', '168,38', '42,10'),
createData('CSJP01546X', 'P-SERIES PORTABLE VACUUM CUP', 'Brand Merchandise', 'GWM', 'P-Series', '277,99', '222,39', '55,60'),
createData('CSJP02897X', 'OFF ROAD SHOVEL(SPADE)', 'Brand Merchandise', 'GWM', 'P-Series LTD OFF-Roader', '740,13', '592,10', '148,03'),
createData('CSJP02887X', 'OUTDOOR PORTABLE COLLAPSIBLE BUCKET', 'Brand Merchandise', 'GWM', 'P-Series LTD OFF-Roader', '296,03', '236,82', '59,21'),
createData('GJFJ00002P', 'CARPET MAT SET -P SERIES CV AT', 'Carpet', 'GWM', 'P-Series', '513,30', '410,64', '102,66'),
createData('WR-GPPX30', 'SMART MULTIMEDIA P SERIES', 'Infotainment', 'GWM', '', '10 292,65', '8 234,12', '2 058,53'),
createData('GJFJ00023P', 'MUD GUARD SET-P SERIES CV', 'Mudflaps', 'GWM', 'P-Series', '280,80', '224,64', '56,16'),
createData('GJFJ00004P', 'PLASTICS MUDGUARD (1SET BY 4 PCS ) -P SERIES PV(APPLIES TO VEHICLES WITH WHEEL ARCHES)', 'Mudflaps', 'GWM', 'P-Series', '248,40', '198,72', '49,68'),
createData('BS-72056', 'GWM P-SERIES NUDGE BAR BLACK', 'Nudge Bar', 'GWM', 'P-Series', '4 675,00', '3 740,00', '935,00'),
createData('72056T', 'GWM P-SERIES NUDGE BAR STAINLESS', 'Nudge Bar', 'GWM', 'P-Series', '5 091,50', '4 073,20', '1 018,30'),
createData('CSJP02729X', 'UNDER  BODY COVER-SET OF 5 ?4MM ALUMINUM MAGNESIUM ALLOY?-P SERIES', 'Protection', 'GWM', 'P-Series Skid Plate', '10 053,14', '8 042,51', '2 010,63'),
createData('CSJP02730X', 'UNDER  BODY COVER-SET OF 5 ?4MM ALUMINUM MAGNESIUM ALLOY?-P SERIES', 'Protection', 'GWM', 'P-Series Skid Plate', '10 053,14', '8 042,51', '2 010,63'),
createData('CSJP02723X', 'UNDER ENGINE COVER ?4MM ALUMINUM MAGNESIUM ALLOY)-P SERIES', 'Protection', 'GWM', 'P-Series Skid Plate', '1 855,28', '1 484,22', '371,06'),
createData('BS-72057', 'GWM P-SERIES SPORTS BAR DOUBLE CAB BLACK', 'Roll Bar', 'GWM', 'P-Series', '4 930,00', '3 944,00', '986,00'),
createData('72057T', 'GWM P-SERIES SPORTS BAR DOUBLE CAB STAINLESS', 'Roll Bar', 'GWM', 'P-Series', '5 865,00', '4 692,00', '1 173,00'),
createData('BS-72058', 'GWM P-SERIES SPORTS BAR SINGLE CAB BLACK', 'Roll Bar', 'GWM', 'P-Series', '4 930,00', '3 944,00', '986,00'),
createData('72058T', 'GWM P-SERIES SPORTS BAR SINGLE CAB STAINLESS', 'Roll Bar', 'GWM', 'P-Series', '5 865,00', '4 692,00', '1 173,00'),
createData('CSJP02195X', 'ROOF RACK CROSS BARS-P SERIES PV', 'Roof Rails', 'GWM', 'P-Series', '1 765,55', '1 412,44', '353,11'),
createData('CSJP02194X', 'ROOF RACKS FOR POER WITHOUT ROOF RAILS-P SERIES', 'Roof Rails', 'GWM', 'P-Series', '1 765,55', '1 412,44', '353,11'),
createData('GWMPSERIESDCPLAIN', 'ALUMINIUM FRAME CLIP  COVER  P-SERIES DC PLAIN NO ROLL BAR AND NO CAB PROTECTOR', 'Tonneau Cover', 'GWM', 'P-Series', '3 400,00', '2 720,00', '680,00'),
createData('GWMPSERIESDC4X4', 'ALUMINIUM FRAME CLIP GWMP-SERIES DC OFFROAD -WFROLL BAR', 'Tonneau Cover', 'GWM', '', '4 170,00', '3 336,00', '834,00'),
createData('GWMPSERIESDCSTDRB', 'ALUMINIUM FRAME CLIP GWMP-SERIES DC PASSENGER MODEL -WFROLL BAR', 'Tonneau Cover', 'GWM', 'P-Series', '3 900,00', '3 120,00', '780,00'),
createData('GWMPSERIESDCARTAVRB', 'ALUMINIUM FRAME CLIP ON COVER GWM P-SERIES DC WITH ARTAV ROLL BAR', 'Tonneau Cover', 'GWM', 'P-Series', '3 400,00', '2 720,00', '680,00'),
createData('GWMPSERIESLWBARTAVRB', 'ALUMINIUM FRAME CLIP ON COVER GWM P-SERIES LWB WITH ARTAV ROLL BAR', 'Tonneau Cover', 'GWM', 'P-Series', '3 900,00', '3 120,00', '780,00'),
createData('GJFJ00001P', 'HAND MANUAL RETRACTABLE TONNEAU COVER-P SERIES PV WITH ROLL BAR', 'Tonneau Cover', 'GWM', 'P-Series', '24 632,08', '19 705,66', '4 926,42'),
createData('8516100XPW04A', 'HARD TONNEAU ROLLER COVER (LOADBIN WITHOUT ROLL BAR)-P SERIES PV', 'Tonneau Cover', 'GWM', 'P-Series', '38 102,64', '30 482,11', '7 620,53'),
createData('GWMALUPGCLAMB', 'HOLD DOWN BRACKET FOR P-SERIES TONNEAU COVER', 'Tonneau Cover', 'GWM', 'P-Series', '280,00', '224,00', '56,00'),
createData('GWMPLWB10HCL', 'P SERIES SINGLE CAB  ALUMINIUM FRAME CLIP ON COVER', 'Tonneau Cover', 'GWM', 'P-Series', '6 500,00', '5 200,00', '1 300,00'),
createData('GWMPLWB10HEL', 'P SERIES SINGLE CAB  ELASTICATED ROPE TIE DOWN', 'Tonneau Cover', 'GWM', 'P-Series', '2 900,00', '2 320,00', '580,00'),
createData('14GW14PP0', 'P-SERIES FIXED TOWBAR', 'Towbar', 'GWM', 'P-Series', '5 400,00', '4 320,00', '1 080,00'),
createData('682263', 'P-SERIES TOW BAR HARNESS', 'Towbar', 'GWM', 'P-Series', '2 353,31', '1 882,65', '470,66'),
createData('14GW14CPA', 'TOWBAR UNDER MOUNT P-SERIES', 'Towbar', 'GWM', 'P-Series', '5 999,00', '4 799,20', '1 199,80'),
createData('GJFJ00005P', 'WHEATER SHIELD (WIND SHIELD)  (1SET BY 4 PCS )-P SERIES', 'Windshield', 'GWM', 'P-Series', '568,56', '454,85', '113,71'),
createData('14GW09CPA', 'GWM M4 FIXED HEAD TOW BAR M4', 'Towbar', 'GWM', 'M4 H1', '3 000,00', '2 400,00', '600,00'),
createData('5509170XST01A', 'RADIATOR GRILLE ASSY-HAVAL H2J OPTIONAL VERSION WITH FRONT CAMERA', 'Grille', 'HAVAL', 'Jolion', '5 638,18', '4 510,54', '1 127,64'),
createData('5509180XST01A', 'RADIATOR GRILLE ASSY-HAVAL H2J OPTIONAL VERSION WITHOUT FRONT CAMERA', 'Grille', 'HAVAl', 'Jolion', '5 638,18', '4 510,54', '1 127,64'),
createData('GJFJ00010P', 'PLASTICS MUDGUARD (1SET BY 4 PCS) -HAVAL JOLION', 'Mudflaps', 'HAVAL', 'Jolion', '248,40', '198,72', '49,68'),
createData('710025T', 'HAVAL JOLION NUDGE BAR STAINLESS - FITS HYBRID AND S MODELS ONLY', 'Nudeg', 'HAVAL', 'Jolion S and Jolion HEV', '6 210,00', '4 968,00', '1 242,00'),
createData('710019T', 'HAVAL JOLION NUDGE BAR STAINLESS', 'Nudge Bar', 'HAVAL', 'Jolion', '5 175,00', '4 140,00', '1 035,00'),
createData('GJFJ00051P', 'UNDER  BODY COVER-SET-HAVAL JOLION', 'Protection', 'HAVAL', 'Jolion', '1 450,36', '1 160,29', '290,07'),
createData('CSJP02193X', 'CROSS BAR OF ROOF RACK(1 SET BY 2 PCS) -HAVAL JOLION', 'Roof Rails', 'HAVAL', 'Jolion', 'Roof rack', '1 756,85', '1 405,48', '351,37'),
createData('GJFJ00047P', 'SIDE STEP-HAVAL JOLION', 'Side Steps', 'HAVAL', 'Jolion', '3 630,49', '2 904,39', '726,10'),
createData('CSJP02485X', 'ELECTRICAL TAIL DOOR  SYSTEM-HAVAL JOLION', 'Tailgate Lift', 'HAVAL', 'Jolion', '8 330,63', '6 664,50', '1 666,13'),
createData('14GW16CPA', 'TOW BAR FIX TYPE- HAVAL JOLION', 'Towbar', 'HAVAL', 'Jolion', '4 341,65', '3 473,32', '868,33'),
createData('GJFJ00009P', 'TRUNK MAT -HAVAL JOLION', 'Trunk Mat', 'HAVAL', 'Jolion', '866,05', '692,84', '173,21'),
createData('GJFJ00006P', 'WIND SHIELD WHEATER SHIELD (WIND SHIELD)  (1SET BY 4 PCS )-HAVAL JOLION', 'Windshield', 'HAVAL', 'Jolion', '568,56', '454,85', '113,71'),
createData('GJFJ00020P', 'MUD GUARD (ONE SET)-HAVAL H6GT', 'Mudflaps', 'HAVAL', 'H6GT', '205,86', '164,69', '41,17'),
createData('HS-9018', 'H6 NAVIGATION MULTIMEDIA PLAYER', 'Infotainment', 'HAVAL', 'H6C', '10 292,65', '8 234,12', '2 058,53'),
createData('HS-9016', 'H6C NAVIGATION MULTIMEDIA PLAYER', 'Infotainment', 'HAVAL', 'H6C', '11 427,14', '9 141,71', '2 285,43'),
createData('14GW11CPA', 'TOW BAR HAVAL H6 COUPE -GOOSENECK, 2 HOLE DROP PLATE AND HARNESS', 'Towbar', 'HAVAL', 'H6C', '4 680,00', '3 744,00', '936,00'),
createData('GJFJ00012P', 'PLASTICS MUDGUARD (1SET BY 4 PCS) -HAVAL 3RD GEN H6', 'Mudflaps', 'HAVAL', 'H6 3rd Gen', '248,40', '198,72', '49,68'),
createData('710021T', 'HAVAL 3RD GEN H6 2021 FACELIFT NUDGE BAR STAINLESS', 'Nudge Bar', 'HAVAL', 'H6 3rd Gen', '4 350,00', '3 480,00', '870,00'),
createData('CSJP02991X', 'ROOF RACK-HAVAL 3RD GEN H6', 'Roof Rails', 'HAVAL', 'H6 3rd Gen', '1 765,55', '1 412,44', '353,11'),
createData('GJFJ00048P', 'SIDE STEP-HAVAL 3RD GEN H6', 'Side Steps', 'HAVAL', 'H6 3rd Gen', '3 630,49', '2 904,39', '726,10'),
createData('710022T', 'HAVAL 3RD GEN H6 2021 FACELIFT SIDE BARS STAINLESS', 'Side Steps', 'HAVAL', 'H6 3rd Gen', '4 650,00', '3 720,00', '930,00'),
createData('CSJP02469X', 'ELECTRICAL TAIL DOOR  SYSTEM-HAVAL 3RD GEN H6', 'Tailgate Lift', 'HAVAL', 'H6 3rd Gen', '8 330,63', '6 664,50', '1 666,13'),
createData('14GW17CPA', 'TOW BAR FIX TYPE- HAVAL 3RD GEN & H6 GT', 'Towbar', 'HAVAL', 'H6 3rd Gen', '4 808,00', '3 846,40', '961,60'),
createData('GJFJ00013P', 'TRUNK MAT(WITH REAR ROW SEAT BACK PROTEC-HAVAL 3RD GEN H6', 'Trunk Mat', 'HAVAL', 'H6 3rd Gen', '866,05', '692,84', '173,21'),
createData('GJFJ00011P', 'WHEATER SHIELD  (WIND SHIELD)(1SET BY 4 PCS )-HAVAL 3RD GEN H6', 'Windshield', 'HAVAL', 'H6 3rd Gen', '568,56', '454,85', '113,71'),
createData('710005T', 'H6 3INCH OVAL STYLING BAR', 'Nudge Bar', 'HAVAL', 'H6', '3 168,00', '2 534,40', '633,60'),
createData('HAV-H6-NBS', 'HAVAL H6 NUDGE BAR STAILESS', 'Nudge Bar', 'HAVAL', 'H6', '4 473,60', '3 578,88', '894,72'),
createData('10114', 'H6 SIDE BARS', 'Side Steps', 'HAVAL', 'H6', '3 436,40', '2 749,12', '687,28'),
createData('14GW08CPA', 'GWM H6 FIXED HEAD TOW BAR H6', 'Towbar', 'GWM', 'H6', '3 774,24', '3 019,39', '754,85'),
createData('WC-HT9018 NAVIGATION', 'H2 NAVIGATION MULTIMEDIA MEDIA PLAYER', 'Infotainment', 'HAVAL', 'H2', '10 292,65', '8 234,12', '2 058,53'),
createData('HAV-H2-SBB', 'HAVAL H2 SIDE BARS BLACK MS-710011', 'Side Steps', 'HAVAL', 'H2', '4 907,41', '3 925,93', '981,48'),
createData('14GW10CPAWH', 'HAVAL H2 TOWBAR  WIRING HARNESS', 'Towbar', 'HAVAL', 'H2', '1 150,00', '920,00', '230,00'),
createData('TAC595CPA', 'TOW BAR HARNESS FOR HAVAL H2 PRE-FACELIFT', 'Towbar', 'HAVAL', 'H2', '938,00', '750,40', '187,60'),
createData('14GW10CPA', 'TOW BAR HAVAL H2 GOOSENECK, 2 HOLE DROP PLATE AND HARNESS', 'Towbar', 'HAVAL', 'H2', '4 624,80', '3 699,84', '924,96'),
createData('WC-HU9018', 'H1 SMARTNAVI UNIT ONLY', 'Infotainment', 'HAVAL', 'H1', '10 292,65', '8 234,12', '2 058,53'),
createData('WC-HU9018R', 'HAVAL H1 SMARTNAVI UNIT WITH REVERSE CAMERA', 'Infotainment', 'HAVAL', 'H1', '11 027,94', '8 822,35', '2 205,59'),
createData('CSJP02899X', 'CAR & HOUSEHOLD HANDHELD VACUMM', 'Brand Merchandise', 'All Vehicles', '715,48', '572,38', '143,10'),
createData('CSJP02901X', 'CAR & OUTDOOR INFLATABLE AIR CUSHION', 'Brand Merchandise', 'All Vehicles', 'Outdoor Inflatable Bed', '986,73', '789,38', '197,35'),
createData('GJFJ00018P', 'CUP HOLDER-HAVAL BRAND', 'Brand Merchandise', 'All Vehicles', 'Cup / Drink Holder', '64,52', '51,62', '12,90'),
createData('CSJP02058X', 'HAVAL DRIVING RECORDER WITH SCREEN', 'Brand Merchandise', 'HAVAL', 'All Vehicles', '1 931,79', '1 545,43', '386,36'),
createData('CSJP01149X', 'HAVAL INFLATOR PUMP', 'Brand Merchandise', 'HAVAL', 'All Vehicles', '862,62', '690,10', '172,52'),
createData('CSJP02892X', 'CAR SEAT HEADREST PILLOW', 'Brand Merchandise', 'GROUP MERCHANDISE', '', '575,64', '460,51', '115,13'),
createData('CSJP00222X', 'OUTDOOR BACKPACK', 'Brand Merchandise', 'GROUP MERCHANDISE', '', '517,36', '413,89', '103,47'),
createData('CSJP00170X', 'STUFFED BEAR TOY', 'Brand Merchandise', 'GROUP MERCHANDISE', '', '329,11', '263,29', '65,82'),
createData('CSJP02487X', 'DRIVING RECORDER-HAVAL BRAND', 'Infotainment', 'All Vehicles', 'A Dashcam', '1 858,52', '1 486,82', '371,70'),
createData('HV-NAVMIR', 'NAVIGATION MIRROR', 'Infotainment', 'GROUP MERCHANDISE', '', '6 495,00', '5 196,00', '1 299,00'),
createData('CSJP00141X', '(CSJP00434X) KEY CASE- HAVAL H2', 'Key Pouch', 'All Vehicles', 'Image on IAL', '275,69', '220,55', '55,14'),
createData('CSJP00145X', '(CSJP00437X) KEY CASE-HAVAL H6 COUPE', 'Key Pouch', 'HAVAL', 'All Vehicles', '275,69', '220,55', '55,14'),
createData('CSJP02198X', 'ROOF BOX (LARGE)-ALL MODELS', 'Roof Box', 'GROUP MERCHANDISE', '', '7 201,71', '5 761,37', '1 440,34'),
createData('CSJP02893X', 'FOLDABLE CAR STORAGE ORGANIZER-HAVAL BRAND', 'Storage Organiser', 'HAVAL', 'Boot organiser', '320,67', '256,54', '64,13'),
createData('CSJP02228X', 'GWM BACKPACK', 'Brand Merchandise', 'GWM', '', '130,42', '104,34', '26,08'),
createData('CSJP02230X', 'HAVAL 1.6L TERMO JUG', 'Brand Merchandise', 'HAVAL', 'Flask  ', '318,07', '254,46', '63,61'),
createData('CSJP01120X', 'HAVAL BACKPACK OUTDOOR', 'Brand Merchandise', 'HAVAL', '', '500,00', '400,00', '100,00'),
createData('CSJP01131X', 'HAVAL BASEBALL CAPS', 'Brand Merchandise', 'HAVAL', '', '100,00', '80,00', '20,00'),
createData('CSJP00809X', 'HAVAL BELT AUTOMATIC BUCKLE', 'Brand Merchandise', 'HAVAL', '', '200,00', '160,00', '40,00'),
createData('CSJP00916X', 'HAVAL BELT GLOD PLATE BUCKLE', 'Brand Merchandise', 'HAVAL', '', '450,00', '360,00', '90,00'),
createData('CSJP00810X', 'HAVAL BELT SILVER BAR BUCKLE', 'Brand Merchandise', 'HAVAL', '', '450,00', '360,00', '90,00'),
createData('CSJP01662X', 'HAVAL BLACK POLO SHIRT L-170', 'Brand Merchandise', 'HAVAL', '', '455,77', '364,62', '91,15'),
createData('CSJP01661X', 'HAVAL BLACK POLO SHIRT M-165', 'Brand Merchandise', 'HAVAL', '', '455,77', '364,62', '91,15'),
createData('CSJP01660X', 'HAVAL BLACK POLO SHIRT S-160', 'Brand Merchandise', 'HAVAL', '', '455,77', '364,62', '91,15'),
createData('CSJP01663X', 'HAVAL BLACK POLO SHIRT XL-175', 'Brand Merchandise', 'HAVAL', '', '455,77', '364,62', '91,15'),
createData('CSJP01664X', 'HAVAL BLACK POLO SHIRT XXL-180', 'Brand Merchandise', 'HAVAL', '', '455,77', '364,62', '91,15'),
createData('CSJP01665X', 'HAVAL BLACK POLO SHIRT XXXL-185', 'Brand Merchandise', 'HAVAL', '', '455,77', '364,62', '91,15'),
createData('RED-SHIRT-4XL', 'HAVAL BRANDED GOLF SHIRT RED COLOR-4XL', 'Brand Merchandise', 'HAVAL', '', '235,00', '188,00', '47,00'),
createData('RED-SHIRT-5XL', 'HAVAL BRANDED GOLF SHIRT RED COLOR-5XL', 'Brand Merchandise', 'HAVAL', '', '235,00', '188,00', '47,00'),
createData('CSJP01123X', 'HAVAL BROOCH', 'Brand Merchandise', 'HAVAL', '', '130,00', '104,00', '26,00'),
createData('CSJP01136X', 'HAVAL CASUAL BACKPACK', 'Brand Merchandise', 'HAVAL', '', '624,81', '499,85', '124,96'),
createData('CSJP02226X', 'HAVAL CASUAL BAG', 'Brand Merchandise', 'HAVAL', '', '117,68', '94,14', '23,54'),
createData('CSJP00990X', 'HAVAL FASHION SUNGLASS FOR FEMALE', 'Brand Merchandise', 'HAVAL', '', '230,00', '184,00', '46,00'),
createData('CSJP01127X', 'HAVAL FOLDING UMBRELLA', 'Brand Merchandise', 'HAVAL', '', '191,26', '153,01', '38,25'),
createData('CSJP00813X', 'HAVAL KIDS SPORT BOTTLE', 'Brand Merchandise', 'HAVAL', '', '140,00', '112,00', '28,00'),
createData('CSJP01543X', 'HAVAL PORTABLE VACUUM CUP', 'Brand Merchandise', 'HAVAL', '', '277,99', '222,39', '55,60'),
createData('CSJP00658X', 'HAVAL SCUBA KNITTING FABRIC SWEATRSHIRT', 'Brand Merchandise', 'HAVAL', '', '913,35', '730,68', '182,67'),
createData('CSJP00659X', 'HAVAL SCUBA KNITTING FABRIC SWEATRSHIRT', 'Brand Merchandise', 'HAVAL', '', '913,35', '730,68', '182,67'),
createData('CSJP00660X', 'HAVAL SCUBA KNITTING FABRIC SWEATRSHIRT', 'Brand Merchandise', 'HAVAL', '', '913,35', '730,68', '182,67'),
createData('CSJP00661X', 'HAVAL SCUBA KNITTING FABRIC SWEATRSHIRT', 'Brand Merchandise', 'HAVAL', '', '913,35', '730,68', '182,67'),
createData('CSJP00662X', 'HAVAL SCUBA KNITTING FABRIC SWEATRSHIRT', 'Brand Merchandise', 'HAVAL', '', '913,35', '730,68', '182,67'),
createData('CSJP00663X', 'HAVAL SCUBA KNITTING FABRIC SWEATRSHIRT', 'Brand Merchandise', 'HAVAL', '', '913,35', '730,68', '182,67'),
createData('CSJP00664X', 'HAVAL SCUBA KNITTING FABRIC SWEATRSHIRT', 'Brand Merchandise', 'HAVAL', '', '913,35', '730,68', '182,67'),
createData('CSJP00665X', 'HAVAL SCUBA KNITTING FABRIC SWEATRSHIRT', 'Brand Merchandise', 'HAVAL', '', '913,35', '730,68', '182,67'),
createData('CSJP00666X', 'HAVAL SCUBA KNITTING FABRIC SWEATRSHIRT', 'Brand Merchandise', 'HAVAL', '', '913,35', '730,68', '182,67'),
createData('CSJP00667X', 'HAVAL SCUBA KNITTING FABRIC SWEATRSHIRT', 'Brand Merchandise', 'HAVAL', '', '913,35', '730,68', '182,67'),
createData('CSJP00644X', 'HAVAL SPORT JACKET ( BLUE-L-175/96B )', 'Brand Merchandise', 'HAVAL', '', '1 413,67', '1 130,94', '282,73'),
createData('CSJP00643X', 'HAVAL SPORT JACKET ( BLUE-M-170/92B )', 'Brand Merchandise', 'HAVAL', '', '1 413,67', '1 130,94', '282,73'),
createData('CSJP00645X', 'HAVAL SPORT JACKET ( BLUE-XL-180/100B )', 'Brand Merchandise', 'HAVAL', '', '1 413,67', '1 130,94', '282,73'),
createData('CSJP00646X', 'HAVAL SPORT JACKET ( BLUE-XXL-185/104B )', 'Brand Merchandise', 'HAVAL', '', '1 413,67', '1 130,94', '282,73'),
createData('CSJP00647X', 'HAVAL SPORT JACKET ( BLUE-XXXL-190/106B', 'Brand Merchandise', 'HAVAL', '', '1 413,67', '1 130,94', '282,73'),
createData('CSJP01128X', 'HAVAL STRAIGHT SHANK UMBRELLA', 'Brand Merchandise', 'HAVAL', '', '180,00', '144,00', '36,00'),
createData('CSJP01143X', 'HAVAL SUNGLASS', 'Brand Merchandise', 'HAVAL', '', '420,00', '336,00', '84,00'),
createData('CSJP01028X', 'HAVAL SUNPROOF SKIN COAT-BLUE-L', 'Brand Merchandise', 'HAVAL', '', '790,00', '632,00', '158,00'),
createData('CSJP01029X', 'HAVAL SUNPROOF SKIN COAT-BLUE-XL', 'Brand Merchandise', 'HAVAL', '', '790,00', '632,00', '158,00'),
createData('CSJP01030X', 'HAVAL SUNPROOF SKIN COAT-BLUE-XXL', 'Brand Merchandise', 'HAVAL', '', '790,00', '632,00', '158,00'),
createData('CSJP02432X', 'MODEL CAR(1:18)-HAVAL 3RD GEN H6', 'Brand Merchandise', 'HAVAL', '', '1 348,86', '1 079,09', '269,77'),
createData('CSJP00178X', 'MODEL CAR(1:18)-HAVAL H6 COUPE', 'Brand Merchandise', 'HAVAL', '', '1 072,51', '858,01', '214,50'),
createData('CSJP02888X', 'OUTDOOR FULLY AUTOMATIC CAMPING TENT-HAVAL BRAND', 'Brand Merchandise', 'HAVAL', 'Camping Tent', '1 496,66', '1 197,33', '299,33'),
createData('HAVBW0064', 'STAINLESS STEEL FLASK WITH CARRY HANDLE', 'Brand Merchandise', 'HAVAL', '', '294,90', '235,92', '58,98'),
createData('HAVBW0071', 'VACUM FLASK', 'Brand Merchandise', 'HAVAL', '', '295,24', '236,19', '59,05'),
createData('CSJP00079X', 'VEHICLE MOUNTED VACUUM CUP CSJP00428X', 'Brand Merchandise', 'HAVAL', '', '216,97', '173,58', '43,39'),
createData('CSJP00428X', 'VEHICLE THERMAL CUP', 'Brand Merchandise', 'HAVAL', '', '0,00', '0,00', '0,00', '0,00'),
];

export default rows;

import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { DealerContext } from "../shared/contexts/DealerContext";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "100px 15px",
    '@media (max-width: 500px)': {
      display: "block",
    },
  },
  inner: {
    maxWidth: "1170px",
  },
  textIntro: {
    fontSize: "14px",
    padding: "10px 0px",
    marginBottom: "0px",
    display: "block",
  },
  section: {
    width: '50%',
    paddingRight: "15px"
  },
  sectionHolder: {
    display: 'flex'
  },
  titleTopAccent: {
    fontSize: '44px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold'
  }
}));

const AboutUsFord = () => {

  const { websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });

  return (
    <>
      <div className={classes.root}>
        <div className={classes.inner}>
          <Typography variant="h1" className={classes.titleTopAccent}>
            About Us
          </Typography>
          <div className={classes.sectionHolder}>
            <div className={classes.section}>

              <Typography variant="p" className={classes.textIntro}>
              Welcome to Ben Morgenrood Ford , an integral part of the esteemed Ben Morgenrood Group. Our legacy in Randfontein spans several years, and we take pride in being a longstanding member of the community. The iconic Ben Morgenrood Ford building has illuminated Main Reef Road for years, symbolizing our enduring commitment to providing unparalleled service and a comprehensive range of Ford vehicles.
              </Typography>

              <Typography variant="p" className={classes.textIntro}>
              Our Rich History: Ben Morgenrood Ford Randfontein has been an integral part of the local landscape, shaping the automotive experience for the community. Over the years, we have become synonymous with quality, professionalism, and a diverse range of Ford vehicles.
              </Typography>

              <Typography variant="p" className={classes.textIntro}>
              Explore Our Range: Step into our showroom and explore the latest Ford models, each embodying innovation, performance, and iconic design. Whether you're looking for a dynamic city car or a robust pickup truck, our diverse range of Ford vehicles caters to every lifestyle.
              </Typography>
            </div>
            <div className={classes.section}>
              <Typography variant="p" className={classes.textIntro}>
              Branch in Carletonville: Having stood the test of time in Randfontein, Ben Morgenrood Ford later expanded its reach with a branch in Carletonville. Our Carletonville location shares the same commitment to excellence and offers the same range of quality Ford vehicles and services.
              </Typography>

              <Typography variant="p" className={classes.textIntro}>
              On-Site Services: Our commitment goes beyond sales. Ben Morgenrood Ford features dedicated on-site workshops and parts departments staffed by skilled professionals. Whether you need routine maintenance, repairs, or genuine Ford parts, our team is here to keep your vehicle running at its best.
              </Typography>

              <Typography variant="p" className={classes.textIntro}>
              Join us at Ben Morgenrood Ford, where the spirit of Ford has been an integral part of the community for years, and the iconic building lights up Main Reef Road with a legacy of excellence.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsFord;

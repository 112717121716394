import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { DealerContext } from "../shared/contexts/DealerContext";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "100px 15px",
    '@media (max-width: 500px)': {
      display: "block",
    },
  },
  inner: {
    maxWidth: "1170px",
  },
  textIntro: {
    fontSize: "14px",
    padding: "10px 0px",
    marginBottom: "0px",
    display: "block",
  },
  section: {
    width: '50%',
    paddingRight: "15px"
  },
  sectionHolder: {
    display: 'flex'
  },
  titleTopAccent: {
    fontSize: '44px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold'
  }
}));

const AboutUsGWMHaval = () => {

  const { websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });

  return (
    <>
      <div className={classes.root}>
        <div className={classes.inner}>
          <Typography variant="h1" className={classes.titleTopAccent}>
            About Us
          </Typography>
          <div className={classes.sectionHolder}>
            <div className={classes.section}>

              <Typography variant="p" className={classes.textIntro}>
              Welcome to GWM Haval Randfontein, a proud member of the esteemed Ben Morgenrood Group. Since opening our doors in  we've been dedicated to delivering exceptional service and a diverse range of GWM and Haval vehicles to our valued customers.
              </Typography>

              <Typography variant="p" className={classes.textIntro}>
              Our Commitment: At GWM Haval Randfontein, we prioritize your satisfaction. As part of the Ben Morgenrood Group, we bring a fresh approach and modern perspective to ensure your experience with GWM and Haval is unparalleled.
              </Typography>

              <Typography variant="p" className={classes.textIntro}>
              Discover Our Range: Explore our showroom to discover the latest GWM and Haval models, each representing cutting-edge technology, style, and reliable performance. Whether you're navigating city streets or embracing off-road adventures, our range caters to diverse lifestyles.
              </Typography>
            </div>
            <div className={classes.section}>
              <Typography variant="p" className={classes.textIntro}>
              On-Site Excellence: Our commitment extends beyond sales. GWM Haval Randfontein features dedicated on-site workshops and parts departments, staffed by skilled professionals. From routine maintenance to genuine parts, our team is here to keep your vehicle performing at its peak.
              </Typography>

              <Typography variant="p" className={classes.textIntro}>
              Experience the thrill of GWM and Haval at GWM Haval Randfontein, where innovation meets the excellence of the Ben Morgenrood Group.
              </Typography>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsGWMHaval;

import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { Link, useHistory } from "react-router-dom";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { BASE_URI } from '../shared/Constants';
import axios from 'axios';
import { DealerContext } from '../shared/contexts/DealerContext';
import { VehicleContext } from '../shared/contexts/VehicleContext';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: 'center',
    margin: '0px 0px',
    borderTop: ({ websiteColors }) => `8px ${websiteColors.accentColor} solid`,
    flexDirection: 'column',
    marginBottom: '0px',
    padding: '40px 100px',
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    '@media (min-width:0px) and (max-width:768px)': {
      padding: '20px 15px'
    }
  },
  innerHolder: {
    width: "100%",
    justifyContent: 'space-evenly',
    display: "flex",
    flexDirection: 'row',
    '@media (min-width:0px) and (max-width:768px)': {
      flexDirection: 'column'
    }
  },
  section: {
    width: 'calc(100%/3)',
    '@media (min-width:0px) and (max-width:768px)': {
      width: "100%",
      padding: '0px 15px'
    }
  },
  GroupedSection: {
    display: 'flex',
    width: 'calc(100%/3)',
    flexDirection: 'column',
    '@media (min-width:0px) and (max-width:768px)': {
      width: "100%",
      padding: '0px 15px'
    }
  },
  heading: {
    color: 'white',
    marginTop: '21px',
    marginBottom: '30px',
    fontSize: '16px',
  },
  headingText: {
    fontSize: '16px!important',
    margin: '10px 0px',
    color: 'white',
  },
  headingTextP: {
    fontSize: '16px!important',
    margin: '10px 0px',
    color: 'white',
    padding: '0px 25px',
    paddingLeft: '0px'
  },
  img: {
    display: 'block',
    margin: '0px auto'
  },
  socialLinkHolder: {
    display: 'flex'
  },
  socialLink: {
    width: '50px!important',
    color: 'black!important',
    '@media (min-width:769px) and (max-width:1024px)': {
      width: '50px!important',
    }
  },
  socialLinkIcon: {
    width: '80%!important',
    height: '80%!important',
  },
  LogoHolder: {
    display: 'block'
  },
  LegalSection: {
    borderTop: '2px solid grey',
    paddingTop: '20px',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    color: 'white',
    flexWrap: 'wrap',
  },
  LegalSectionG: {
    display: 'flex',
    color: 'white',
  }
}))

const FooterHaval = () => {
  const { globalDealer, websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });
  const [Logo, setLogo] = useState('');
  const { newVehiclesList } = useContext(VehicleContext);
  const history = useHistory();
  useEffect(() => {
    axios({
      method: 'GET',
      url: `${BASE_URI}/DealerWebsites/${globalDealer.id} `
    }).then((response) => {
      setLogo(response.data.image)
    });

  }, [globalDealer]);

  const handleRedirect = (data) => {
    history.push(`/show-room/${data?.make}/${data?.ownedModelId}`, data)
  };

  return (
    <div className={classes.root}>
      <Link
        to="/"
        className={classes.LogoHolder}
      >
        <img src={Logo} width="175px"></img>
      </Link>
      <div className={classes.innerHolder}>
        <div className={classes.section}>
          <Typography variant='h1' className={classes.heading} ><strong>About the brand </strong></Typography>
          <Link to="/special-deals"> <Typography variant='h1' className={classes.headingText} > Promotions   </Typography></Link>
          <Link to="/contact-us"> <Typography variant='h1' className={classes.headingText} > Contact Us   </Typography></Link>
        </div>
        <div className={classes.section}>
          <Typography variant='h1' className={classes.heading} ><strong>Range Overview </strong></Typography>
          <Link to="/show-room"> <Typography variant='h1' className={classes.headingText} > New vehicles   </Typography></Link>
          {newVehiclesList.map((v) => {
            return (
              <Typography variant='h1' className={classes.headingText} onClick={() => {
                handleRedirect(v)
              }}>{v.title}</Typography>
            )
          })}
        </div>

        <div className={classes.section}>
          <Typography variant='h1' className={classes.heading} >
            <strong>Owners area</strong>
          </Typography>
          <Link to="/show-room">
            <Typography variant='h1' className={classes.headingText} >
              new vehicles
            </Typography>
          </Link>
          <Link to="/special-deals">
            <Typography variant='h1' className={classes.headingText} >
              our special deals
            </Typography>
          </Link>
        </div>
        <div className={classes.GroupedSection}>
          <div>
            <Typography variant='h1' className={classes.heading} >
              <strong>Tools</strong>
            </Typography>
            <Link to="/apply-for-finance">
              <Typography variant='h1' className={classes.headingText} >
                apply for finance
              </Typography>
            </Link>
          </div>
        </div>
        <div className={classes.section}>
          <Typography variant='h1' className={classes.heading} >
            <strong>Connect Socially</strong>
          </Typography>
          <div className={classes.socialLinkHolder}>
            <div className={classes.socialLink}>
              <a href={globalDealer?.social?.facebook} target="_blank" style={{ color: 'white' }} rel="noreferrer">
                <FacebookIcon className={classes.socialLinkIcon} />
              </a>
            </div>
            <div className={classes.socialLink}>
              <a href={globalDealer?.social?.instagram} target="_blank" style={{ color: 'white' }} rel="noreferrer">
                <InstagramIcon className={classes.socialLinkIcon} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.LegalSection}>
        <div>
          {`2010 - ${new Date().getFullYear()} Haval Motors South Africa PTY Limited.`}
        </div>
        <div className={classes.LegalSectionG}>
          <div>
            <Link to="/cookies">
              <Typography variant='h1' className={classes.headingTextP} >
                Cookies
              </Typography>
            </Link>
          </div>
          <div>
            <Link to="/privacy">
              <Typography variant='h1' className={classes.headingTextP} >
                Privacy Policy
              </Typography>
            </Link>
          </div>
          <div>
            <Link to="/requestergws">
              <Typography variant='h1' className={classes.headingTextP} >
                PAIA
              </Typography>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterHaval
import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { BASE_URI } from "../shared/Constants";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { DealerContext } from "../shared/contexts/DealerContext";
import { useHistory } from "react-router-dom";
import RightImg from '../shared/assets/Chery/Chery-Book-A-Test-Drive.webp'
import LeftImgTop from '../shared/assets/Chery/Chery-Range-Service.webp'
import LeftImgBot from '../shared/assets/Chery/Chery-Genuine-Parts.webp'
import useMediaQuery from "../shared/useMediaQuery";
import ModelsBG from '../shared/assets/Chery/Chery_ModelsBG.jpg'
import TravelPartner from '../shared/assets/Chery/Chery_Travel_Partner.webp'

const useStyles = makeStyles(() => ({
  root: {
    background: "#fff",
  },
  img: {
    height: "100%",
    maxHeight: "160px",
    margin: '10px 0px',
    transition: '0.5s ease all',
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.1)"
    },
  },
  list: {
    width: 'calc(100% / 2);',
    textAlign: "left"
  },
  MainImg: {
    filter: 'brightness(0.7)',
  },
  vCardHeadingOutterHolderU: {
    position: 'relative',
    width: '95%',
    margin: '0 auto',
  },
  vCardHeadingOutterHolderC: {
    position: 'relative',
    display: 'flex',
    width: '80%',
    margin: '0 auto',
    justifyContent: 'space-around',
    overflow: 'hidden',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    '@media (max-width: 959px)': {
      width: '100%',
    }
  },
  vCardHeadingOutterHolderMainTop: {
    padding: '0px 0px',
  },
  vCardHolder: {
    overflow: "hidden",
    width: '80%',
    margin: '0 auto',
    background: `url(${ModelsBG})`,
    backgroundSize: '800px',  // Set your desired width value
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    "@media (max-width: 600px)": {
      backgroundSize: 'contain',  // Set your desired width value
    },
  },
  vCardHolderInner: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    transition: 'all 0.5s ease',
    transform: ({ slidePosition }) => (!slidePosition == 0 ? `translateX(${slidePosition}%)` : `translateX(0%)`),
    "@media (max-width: 600px)": {
      transform: ({ slidePosition }) => (!slidePosition <= 0 ? `translateX(${slidePosition}%)` : `translateX(0%)`),
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      transform: ({ slidePosition }) => (!slidePosition <= 0 ? `translateX(${slidePosition}%)` : `translateX(0%)`),
    },
  },
  vCard1Img: {
    width: "40%",
    margin: '0 auto',
    cursor: 'pointer',
    "@media (max-width: 1000px)": {
      width: "80%",
    },
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  vCard1: {
    minWidth: "calc(100% / 1 )",
    cursor: 'pointer',
    borderRadius: '10px',
    "@media (max-width: 600px)": {
      minWidth: "calc(100% / 1 - 20px)",
      margin: '0px 10px',
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      minWidth: "calc(100% / 1 - 20px)",
      margin: '0px 10px',
    },
  },
  viewAllLink: {
    padding: '100px 50px',
    color: 'black',
    cursor: 'pointer',
    fontFamily: 'TacticSansExdReg !important',
    fontSize: '1rem',
    fontWeight: '400',
  },
  aboutCherySection: {
    padding: '100px 0px 50px 0px',
    backgroundColor: '#eee',
  },
  vCardButtonPrimary: {
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.87)',
    padding: '16px 48px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    background: 'transparent',
    cursor: 'pointer',
    fontSize: '16px'
  },
  vCardButtonNav: {
    padding: "10px 10px",
    borderRadius: '50px',
    margin: "10px 0px",
    fill: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    cursor: 'pointer'
  },
  vCardButtonNavFlex: {
    display: 'flex',
    width: '80px'
  },
  vCardButtonNavHolder: {
    display: 'flex',
    justifyContent: 'center',
    padding: '50px 0px',
  },
  model: {
    color: 'black',
    padding: '10px 20px',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '18px',
    fontFamily: 'TacticSansExdReg !important',
    textWrap: 'nowrap',
    '@media (max-width: 700px)': {
      width: 'calc(100% /3)',
    }
  },
  modelActive: {
    color: 'black',
    padding: '10px 20px',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '18px',
    borderBottom: ({ websiteColors }) => `4px solid ${websiteColors.accentColor}`,
    fontFamily: 'TacticSansExdReg !important',
  },
  slideHolder: {
    position: 'relative',
    height: 'calc(100% - 99px)',
    '@media (max-width: 425px)': {
      marginTop: '0px',
    },
  },
  slideHolderText: {
    position: 'absolute',
    zIndex: '2',
    color: 'white',
    textTransform: 'uppercase',
    bottom: '230px',
    margin: '0px 80px',
  },
  slideHolderTextInner: {
    color: 'white',
    textTransform: 'uppercase',
    bottom: '230px',
    margin: '0px 0px',
    fontSize: '120px',
    borderBottom: '4px solid white',
    marginBottom: '40px',
  },
  slideHolderBtn: {
    background: '#ce0100',
    border: 'none',
    padding: '15px 80px',
    borderRadius: '50px',
    color: 'white',
    fontSize: '25px',
  },
  introTextBackground: {
    backgroundColor: '#fff',
    color: 'black',
    textAlign: 'center',
    fontSize: '50px',
    '@media (max-width: 613px)': {
      fontSize: '40px',
    },
  },
  OwnersArea: {
    display: 'flex',
    flexWrap: "wrap",
    '@media (max-width: 1100px)': {
      flexDirection: 'column'
    },
  },
  OwnersAreaGroup: {
    paddingRight: '40px',
    '@media (max-width: 1100px)': {
      paddingRight: '0px',
    },
  },
  OwnersAreaImg: {
    width: '50%',
    '@media (max-width: 1099px)': {
      width: '100%',
    },
  },
  vCardButtonNavMain: {
    padding: "10px 10px",
    borderRadius: '50px',
    margin: "10px 0px",
    fill: ({ websiteColors }) => `${websiteColors.accentColor}`,
    cursor: 'pointer'
  },
  vCardButtonNavFlexMain: {
    display: 'flex',
    width: '80px',
    bottom: '0px',
    right: '0px',
    position: 'absolute'
  },
  introTextDealerName: {
    textTransform: 'uppercase',
    fontSize: '38px',
    color: '#000',
    fontFamily: "TacticSansExdBld !important",
  },
  introText: {
    textTransform: 'uppercase',
    fontSize: '32px',
    color: '#00000037',
    padding: '20px 0px',
    fontFamily: "TacticSansExdReg !important",
  },
  activeModelSwitcherTitle: {
    textTransform: 'uppercase',
    fontSize: '32px',
    color: '#000',
    textAlign: 'center',
    padding: '20px 0px',
    fontFamily: 'TacticSansExdReg !important',
  },
  h3Headline: {
    fontSize: '32px',
    fontFamily: "Roboto !important",
    fontWeight: "400",
    color: '#000',
  },
  activeModelSwitcherStartingFrom: {
    textTransform: 'uppercase',
    fontSize: '1rem',
    color: '#000',
    textAlign: 'center',
    padding: '20px 0px',
    fontFamily: 'TacticSansExdReg !important',
  },
  activeModelSwitcherStartingPrice: {
    fontSize: '32px',
    color: '#C4132A',
    fontWeight: '800',
    textAlign: 'center',
    padding: '20px 0px',
    fontFamily: 'TacticSansExdReg !important',
  },
  HomeModelsPriceCTAContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 0px 30px 0px',
    '@media (max-width: 670px)': {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignItems: 'center',
      textAlign: 'center',
    }
  },
  HomeModelsPriceCTA: {
    padding: '0px 15px',
  },
  topSection: {
    backgroundColor: '#eee',
  },
  container: {
    position: 'relative',
    color: 'white',
  },
  containerRight: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    color: 'white',
  },
  bottomLeft: {
    position: 'absolute',
    bottom: '30px',
    left: '16px',
    fontSize: '2rem',
    fontFamily: 'TacticSansExdReg !important',
    '@media (max-width: 640px)': {
      fontSize: '1.5rem',
    }
  },
  bottomLeftBtn: {
    color: '#fff',
    borderRadius: '4px',
    border: '1px solid #fff',
    padding: '16px 48px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    background: 'transparent',
    cursor: 'poiner',
    fontFamily: 'TacticSansExdReg !important',
    fontSize: '1rem',
    fontWeight: '400',
  },
  containerTravelPartner: {
    width: '100%',
    height: '600px',
    position: 'relative',
    color: 'white',
    background: `url(${TravelPartner})`,
    backgroundSize: 'cover',  // Set your desired width value
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  travelPartnerP: {
    fontSize: '1rem',
    fontWeight: '400',
    fontFamily: 'TacticSansExdReg !important',
    '@media (min-width: 670px)': {
      width: '500px',
    }
  }
}));

const HomeComponentChery = () => {
  const history = useHistory();
  const { vehiclesList, newVehiclesList } = useContext(VehicleContext);
  const { globalDealer, websiteColors } = useContext(DealerContext)
  const [slides, setSlides] = useState();
  const [slidesLoading, setSlidesLoading] = useState(true);
  const [slideIndexMain, setSlideIndexMain] = useState(0);
  const mountedRef = useRef(true);
  const [slidePosition, setSlidePosition] = useState(0)
  const [slideIndex, setSlideIndex] = useState(0)
  const classes = useStyles({ websiteColors, slidePosition });
  const [activeModel, setActiveModel] = useState("")
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 1333px)');
  const isDesktop = useMediaQuery('(min-width: 1334px)');

  useEffect(() => {
    setActiveModel(newVehiclesList[0])
  }, [newVehiclesList])

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getSlides = async () => {
      try {
        const params = {
          pageNumber: 0,
          pageSize: 100,
          status: "active",
          dealerId: globalDealer.id,
        };

        const result = await axios({
          method: "GET",
          url: `${BASE_URI}/slide`,
          params,
          cancelToken: source.token,
        });

        let tempSlides = result.data.list.map((itm) => {
          return {
            img: itm.slideImageUrl,
            isVideo: itm.isVideo,
            targetUrl: itm.url
          };
        });

        setSlides(tempSlides);
        setSlidesLoading(false)
      } catch (error) {
        console.warn('There was an error fetching slides', error);
      }
    };

    getSlides().then(() => {
      if (!mountedRef.current) return null;
    });

    return () => {
      mountedRef.current = false;
      source.cancel();
    };
  }, []);

  const handleRedirect = (data) => {
    if (data.isComingSoon) {
      history.push(`/coming-soon/${data?.make}/${data?.ownedModelId}`, data)
    } else {
      history.push(`/show-room/${data?.make}/${data?.ownedModelId}`, data)
    }
  };

  const handleModelSelect = (data) => {
    setActiveModel(data)
  }

  const handleNextSlide = () => {
    if (slideIndexMain < slides.length - 1) {
      setSlideIndexMain(slideIndexMain + 1)
    }
    if (slideIndexMain === slides.length - 1) {
      setSlideIndexMain(0)
    }
  };

  const handleBackSlide = () => {
    if (slideIndexMain > 0) {
      setSlideIndexMain(slideIndexMain - 1)
    }
  };

  const scrollToTopButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  };

  return (
    <div className={classes.root}>
      <div className={classes.slideHolder}>
        {!slidesLoading &&
          <img src={slides[slideIndexMain]?.img} width="100%" className={classes.MainImg} />
        }
        <div className={classes.vCardButtonNavFlexMain}>
          <svg className={classes.vCardButtonNavMain} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleBackSlide() }}><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
          <svg className={classes.vCardButtonNavMain} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleNextSlide() }}><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
        </div>
      </div>
      <div className="topSection">
        <div className={classes.introTextBackground}>
          <h1 className={classes.introText}>Welcome to <br />
            <span className={classes.introTextDealerName}>Chery Randfontein</span></h1>
          <h3 className={classes.h3Headline}><strong>Find your</strong> perfect Chery</h3>
        </div>
        <div className={classes.vCardHeadingOutterHolderMainTop}>
          <div className={classes.vCardHolder}>
            <div className={classes.vCardHolderInner}>
              {newVehiclesList.filter((v) => v.title == activeModel?.title).map((v) => {
                return (
                  <div className={classes.vCard1} onClick={() => {
                    handleRedirect(v);
                    scrollToTopButtonClick(v);
                  }}>
                    <div className={classes.activeModelSwitcherTitle}>
                      {activeModel?.title}
                    </div>
                    <div className={classes.vCard1Img}>
                      <img src={v.image} width="100%" />
                    </div>
                    <div className={classes.HomeModelsPriceCTAContainer}>
                      <div className={classes.HomeModelsPriceCTA}>
                        {activeModel.isComingSoon?
                        <span className={classes.activeModelSwitcherStartingPrice}>{``}</span>: <span className={classes.activeModelSwitcherStartingFrom}>Starting from <br /></span>}
                        {activeModel.isComingSoon?
                        <span className={classes.activeModelSwitcherStartingPrice}>COMING SOON</span>: <span className={classes.activeModelSwitcherStartingPrice}>R{activeModel?.price}</span>}
                      </div>
                      <div className={classes.HomeModelsPriceCTA}>
                        <button className={classes.vCardButtonPrimary}>EXPLORE MORE</button>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className={classes.vCardHeadingOutterHolderC}>
            {newVehiclesList.map((v) => {
              return (
                <div className={v.title == activeModel?.title ? classes.modelActive : classes.model} onClick={() => { handleModelSelect(v); }}>{v.title}</div>
              )
            })}
          </div>
          <div className={classes.vCardButtonNavHolder}>
            <Link
              to="/show-room"
              onClick={(event) => { scrollToTopButtonClick(event) }}
            >
              <span className={classes.viewAllLink}>View All Models</span>
            </Link>
          </div>
        </div>
      </div>
      <div className={classes.aboutCherySection}>
        <div className={classes.vCardHeadingOutterHolderU}>
          <div className={classes.OwnersArea}>
            <div className={classes.OwnersAreaImg}>
              <div className={classes.OwnersAreaGroup}>
                <Link
                  to="/service"
                  onClick={(event) => { scrollToTopButtonClick(event) }}>
                  <div className={classes.container}>
                    <img src={LeftImgTop} width="100%" />
                    <div className={classes.bottomLeft}>Keep your Chery<br /> running fresh <br />
                      <button className={classes.bottomLeftBtn}>Book a Service</button>
                    </div>
                  </div>
                </Link>
              </div>
              <div className={classes.OwnersAreaGroup}>
                <Link
                  to="/parts"
                  onClick={(event) => { scrollToTopButtonClick(event) }}
                >
                  <div className={classes.container}>
                    <img src={LeftImgBot} width="100%" />
                    <div className={classes.bottomLeft}>Genuine Parts<br /> are always better <br />
                      <button className={classes.bottomLeftBtn}>Enquire</button>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className={classes.OwnersAreaImg + " " + classes.OwnersAreaExperience}>
              <Link
                to="/show-room"
                onClick={(event) => { scrollToTopButtonClick(event) }}
              >
                <div className={classes.containerRight}>
                  <img src={RightImg} width="100%" />
                  <div className={classes.bottomLeft}>Experience a <br />Chery Today <br />
                    <button className={classes.bottomLeftBtn}>Book a Test Drive</button>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.containerTravelPartner}>
        <div className={classes.bottomLeft}>YOUR BEST TRAVEL<br /> PARTNER, FOR LIFE<br />
          <p className={classes.travelPartnerP}>MyCheryCare is a unique innovation from Chery that combines a host of free services. These services include a chauffeur service, free maintenance services and convenience features with every new Chery purchased from 1 February 2023</p>
          <Link
            to="/contact-us"
            onClick={(event) => { scrollToTopButtonClick(event) }}
          >
            <span className={classes.bottomLeftBtn}>Contact Us</span>
          </Link>
          <h3><i>#WithCheryWithLove</i></h3>
        </div>
      </div>
    </div>
  );
};

export default HomeComponentChery;


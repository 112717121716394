import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import axios from "axios";
import { BASE_URI } from "../shared/Constants";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { DealerContext } from "../shared/contexts/DealerContext";
import { useHistory } from "react-router-dom";
import ContactSection from "../shared/ContactSection/ContactSection";
import Carmac from '../shared/assets/carmac.png'
import CardImg from '../shared/assets/2022-ford-ranger-sport.png'
import Card3Img from '../shared/assets/ford-pass.png'
import FordPass from '../shared/assets/pdf/2022-07-01-FordPass-A3-brochure.pdf'
import Ford from '../shared/assets/ford_ranger_wildtrak.png'
import FordEverest from '../shared/assets/ford_everest.png'
import useMediaQuery from "../shared/useMediaQuery";

const useStyles = makeStyles(() => ({
  root: {
    background: '#eeeeee',
  },
  img: {
    height: "100%",
    maxHeight: "160px",
    margin: '10px 0px',
    transition: '0.5s ease all',
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.1)"
    },
  },
  list: {
    width: 'calc(100% / 2);',
    textAlign: "left"
  },
  MainImg: {
    filter: 'brightness(0.7)',
  },
  imgSectionHolderCarBack: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    backgroundImage: `url(${Card3Img})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    minHeight: '60vh',
    '@media (max-width: 768px)': {
      backgroundPosition: '33% 100%',
      backgroundColor: '#3434347a',
      backgroundBlendMode: 'overlay',
    },
  },
  BannerCarmac: {
    backgroundImage: `url(${Carmac})`,
    position: 'relative',
    backgroundColor: '#01172fa3',
    backgroundBlendMode: 'overlay',
    minHeight: "70vh",
    display: 'flex',
    alignItems: 'end',
    backgroundSize: 'cover',
    '&::after': {
      content: '" "',
      display: 'block',
      height: 10,
      background: ({ websiteColors }) => `${websiteColors.accentColor}`,
      position: 'absolute',
      right: '0px',
      width: '50%',
      bottom: "0px",
    },
  },
  imgSectionAfterSales: {
    paddingLeft: '40px',
    position: 'relative',
    backgroundBlendMode: 'overlay',
    minHeight: "70vh",
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1000px)': {
      flexDirection: 'column'
    },
    '&::before': {
      content: '" "',
      display: 'block',
      height: 10,
      background: ({ websiteColors }) => `${websiteColors.accentColor}`,
      position: 'absolute',
      left: '0px',
      width: '50%',
      top: "0px",
    },
    '&::after': {
      content: '" "',
      display: 'block',
      height: 10,
      background: ({ websiteColors }) => `${websiteColors.accentColor}`,
      position: 'absolute',
      right: '0px',
      bottom: "0px",
      width: '50%',
    }
  },
  imgSectionAfterSalesEverest: {
    paddingRight: '40px',
    position: 'relative',
    flexDirection: 'row-reverse',
    backgroundBlendMode: 'overlay',
    minHeight: "70vh",
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1000px)': {
      flexDirection: 'column'
    },
    '&::after': {
      content: '" "',
      display: 'block',
      height: 10,
      background: ({ websiteColors }) => `${websiteColors.accentColor}`,
      position: 'absolute',
      left: '0px',
      bottom: "0px",
      width: '50%',
    },
  },
  imgEnd: {
    alignItems: 'self-end',
  },
  AfterSalesHeading: {
    color: '#153160',
    fontSize: '100px',
    '@media (max-width: 959px)': {
      fontSize: '80px',
    }
  },
  AfterSalesHeadingW: {
    color: 'white',
    fontSize: '100px',
    '@media (max-width: 959px)': {
      fontSize: '80px',
    }
  },
  AfterSalesHeadingText: {
    color: '#153160',
    fontSize: '25px',
    margin: 0,
  },
  AfterSalesHeadingTextW: {
    color: 'white',
    fontSize: '25px',
    margin: 0,
  },
  AfterSalesButton: {
    background: '#153160',
    fontSize: '16px',
    border: 'none',
    margin: 0,
    marginTop: '20px',
    padding: "10px 40px",
    borderRadius: '50px',
    cursor: 'pointer',
    color: 'white',
  },
  AfterSalesButtonW: {
    background: 'white',
    fontSize: '16px',
    border: 'none',
    margin: 0,
    marginTop: '20px',
    padding: "10px 40px",
    borderRadius: '50px',
    cursor: 'pointer',
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
  },
  HeadingHolderAfterSales: {
    width: '80%',
    margin: '50px auto',
  },
  ContactSectionHolder: {
    backgroundImage: `url(${CardImg})`,
    backgroundSize: 'cover',
    backgroundColor: '#ffffff78',
    backgroundBlendMode: 'overlay',
    padding: '40px 50px',
    '@media (max-width: 959px)': {
      padding: '40px 10px',
    }
  },
  heading: {
    fontSize: "39px",
    textAlign: 'center'
  },
  imgSectionHolderOverlay: {
    width: '40%',
    padding: '80px',
    '@media (max-width: 768px)': {
      width: 'unset',
      padding: '20px 40px',
    }
  },
  imgSectionHolderOverlayHeading: {
    fontSize: "60px",
  },
  imgSectionHolderOverlayText: {
    fontSize: "18px",
    '@media (max-width: 768px)': {
      width: '50%',
    }
  },
  imgSectionHolderButton: {
    background: ({ websiteColors }) => `${websiteColors.accentColor}`,
    padding: "10px 40px",
    borderRadius: '50px',
    border: 'none',
    marginTop: "20px",
    color: 'white',
  },
  vCardHeadingOutter: {
    fontSize: '40px',
    width: '80%',
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    margin: '40px auto',
  },
  vCardHeadingOutterC: {
    fontSize: '40px',
    width: '80%',
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    margin: '40px auto',
    textAlign: 'center'
  },
  vCardHeadingOutterHolder: {
    position: 'relative'
  },
  vCardHeadingOutterHolderMain: {
    margin: '40px 0px',
  },
  vCardHeadingOutterOverlay: {
    color: "#4242421f",
    margin: '0 auto',
    fontSize: '112px',
    position: 'absolute',
    right: '0',
    top: '0px',
  },
  vCardHolder: {
    overflow: "hidden",
    width: '80%',
    margin: '0 auto'
  },
  vCardHolderInner: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    transition: 'all 0.5s ease',
    transform: ({ slidePosition }) => (!slidePosition == 0 ? `translateX(${slidePosition}%)` : `translateX(0%)`),
    "@media (max-width: 600px)": {
      transform: ({ slidePosition }) => (!slidePosition <= 0 ? `translateX(${slidePosition * 3}%)` : `translateX(0%)`),
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      transform: ({ slidePosition }) => (!slidePosition <= 0 ? `translateX(${slidePosition * 3}%)` : `translateX(0%)`),
    },
  },
  vCard: {
    minWidth: "calc(100% / 3 - 80px)",
    margin: '0px 40px',
    background: 'white',
    borderRadius: '10px',
    border: '1.5px solid',
    padding: '20px',
    "@media (max-width: 600px)": {
      minWidth: "calc(100% / 1 - 20px)",
      margin: '0px 10px',
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      minWidth: "calc(100% / 2 - 20px)",
      margin: '0px 10px',
    },
  },
  vCardHeading: {
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    fontSize: '30px',
  },
  vCardPrice: {
    color: "#a5a4a4",
    fontSize: '20px',
    fontWeight: 'bold'
  },
  vCardPriceFrom: {
    fontSize: '15px',
  },
  vCardFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1.5px solid #a5a4a4',
    marginTop: '30px',
  },
  vCardButton: {
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    padding: "10px 40px",
    borderRadius: '50px',
    border: 'none',
    margin: "10px 0px",
    color: 'white',
    cursor: 'pointer'
  },
  vCardButtonOutline: {
    border: ({ websiteColors }) => `2px soild ${websiteColors.primaryColor}`,
    padding: "10px 40px",
    borderRadius: '50px',
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    margin: "10px 0px",
    cursor: 'pointer'
  },
  vCardButtonNav: {
    border: ({ websiteColors }) => `2px soild ${websiteColors.primaryColor}`,
    padding: "10px 10px",
    borderRadius: '50px',
    margin: "10px 0px",
    fill: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    cursor: 'pointer'
  },
  vCardButtonNavFlex: {
    display: 'flex',
    width: '80px'
  },
  vCardButtonNavHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '40px 0px',
  },
  slideHolder: {
    position: 'relative',
    height: 'calc(100% - 99px)',
    '@media (max-width: 425px)': {
      marginTop: '0px',
    },
  },
  vCardHeading: {
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    fontSize: '30px',
  },
  vCardHeadingUsed: {
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    fontSize: '20px',
  },
  vCardHeadingUsedAccent: {
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontSize: '30px',
    margin: '0px'
  },
  vCardFlexHolder: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: '1.5px solid #a5a4a4',
    paddingTop: '10px',
  },
  vCardFlexItem: {
    textAlign: 'center',
    width: 'calc(100% /3)',
  },
  vCardButton: {
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    padding: "10px 40px",
    borderRadius: '50px',
    border: 'none',
    margin: "10px 0px",
    color: 'white',
    cursor: 'pointer'
  },
  vCardPriceBottom: {
    color: 'grey',
    borderTop: '1.5px solid #a5a4a4',
    fontSize: '14px',
    paddingTop: '10px',
    fontWeight: 'bold',
    margin: "0px"
  },
  vCardPriceUsed: {
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontSize: '40px',
    fontWeight: 'bold',
    margin: "0px",
    paddingTop: '20px',
    paddingBottom: '10px',
  },
  vCardButtonNavFlexMain: {
    display: 'flex',
    width: '80px',
    bottom: '0px',
    right: '0px',
    position: 'absolute'
  },
  vCardButtonNavMain: {
    padding: "10px 10px",
    borderRadius: '50px',
    margin: "10px 0px",
    fill: ({ websiteColors }) => `${websiteColors.accentColor}`,
    cursor: 'pointer'
  },
}));

const HomeComponentFord = () => {
  const history = useHistory();
  const { vehiclesList, newVehiclesList } = useContext(VehicleContext);
  const { globalDealer, websiteColors } = useContext(DealerContext)
  const [slides, setSlides] = useState();
  const [slidesLoading, setSlidesLoading] = useState(true);
  const [slideIndexMain, setSlideIndexMain] = useState(0);
  const mountedRef = useRef(true);
  const [slidePosition, setSlidePosition] = useState(0)
  const [slideIndex, setSlideIndex] = useState(0)
  const classes = useStyles({ websiteColors, slidePosition });
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 1333px)');
  const isDesktop = useMediaQuery('(min-width: 1334px)');

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getSlides = async () => {
      try {
        const params = {
          pageNumber: 0,
          pageSize: 100,
          status: "active",
          dealerId: globalDealer.id
        };

        const result = await axios({
          method: "GET",
          url: `${BASE_URI}/slide`,
          params,
          cancelToken: source.token,
        });

        let tempSlides = result.data.list.map((itm) => {
          return {
            img: itm.slideImageUrl,
            isVideo: itm.isVideo,
            targetUrl: itm.url
          };
        });

        setSlides(tempSlides);
        setSlidesLoading(false)
      } catch (error) {
        console.warn('There was an error fetching slides.', error);
      }
    };

    getSlides().then(() => {
      if (!mountedRef.current) return null;
    });

    return () => {
      mountedRef.current = false;
      source.cancel();
    };
  }, []);

  const handleRedirect = (data) => {
    history.push(`/show-room/${data?.make}/${data?.ownedModelId}`, data)
  };

  const handleBack = () => {
    if (slidePosition < 0) {
      if (isDesktop) {
        setSlidePosition(slidePosition + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }

      if (isMobile) {
        setSlidePosition(slidePosition + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }

      if (isTablet) {
        setSlidePosition(slidePosition + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }
    }
  }

  const handleNext = () => {
    if (isDesktop) {
      if (slideIndex < newVehiclesList.length - 3) {
        setSlidePosition(slidePosition - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === newVehiclesList.length - 3) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }

    if (isMobile) {
      if (slideIndex < newVehiclesList.length - 1) {
        setSlidePosition(slidePosition - (100 / 3));
        setSlideIndex(slideIndex + 1)
      }

      if (slideIndex === newVehiclesList.length - 1) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }

    if (isTablet) {
      if (slideIndex < newVehiclesList.length - 3) {
        setSlidePosition(slidePosition - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === newVehiclesList.length - 3) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }
  }

  const handleRedirectUsed = (data) => {
    history.push(`/pre-owned/${data?.brand}/${data?.stockId}`, data)
  };

  const handleNextSlide = () => {
    if (slideIndexMain < slides.length - 1) {
      setSlideIndexMain(slideIndexMain + 1)
    }
    if (slideIndexMain === slides.length - 1) {
      setSlideIndexMain(0)
    }
  };

  const handleBackSlide = () => {
    if (slideIndexMain > 0) {
      setSlideIndexMain(slideIndexMain - 1)
    }
  };

  const formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 2
  });

  return (
    <div className={classes.root}>
      <div className={classes.slideHolder}>
        {!slidesLoading && slides &&
          <img src={slides[slideIndexMain]?.img} width="100%" className={classes.MainImg} />
        }
        <div className={classes.vCardButtonNavFlexMain}>
          <svg className={classes.vCardButtonNavMain} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleBackSlide() }}><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
          <svg className={classes.vCardButtonNavMain} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleNextSlide() }}><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
        </div>
      </div>
      <div className={classes.vCardHeadingOutterHolderMain}  >
        <div className={classes.vCardHeadingOutterHolder}>
          <h1 className={classes.vCardHeadingOutter}>
            POPULAR MODELS
          </h1>
          <h1 className={classes.vCardHeadingOutterOverlay}>
            POPULAR
          </h1>
        </div>
        <div className={classes.vCardHolder}>
          <div className={classes.vCardHolderInner}>
            {newVehiclesList.map((v) => {
              return (
                <div className={classes.vCard}     >
                  <div>
                    <img src={v.image} width="100%" />
                  </div>
                  <div className={classes.vCardHeading}>
                    {v.title}
                  </div>
                  <div className={classes.vCardFlex}>
                    <h1 className={classes.vCardPrice}>
                      <div className={classes.vCardPriceFrom}>From</div>
                      {formatter.format(v.price)}
                    </h1>
                    <button className={classes.vCardButton} onClick={() => {
                      handleRedirect(v);
                    }}>LEARN MORE</button>
                  </div>
                </div>
              )
            })}
          </div>
          <div className={classes.vCardButtonNavHolder}>
            <Link
              to="/show-room"
            >
              <button className={classes.vCardButtonOutline}>VIEW ALL</button>
            </Link>
            <div className={classes.vCardButtonNavFlex}>
              <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleBack() }}><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
              <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleNext() }}><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.imgSectionHolderCarBack}>
        <div className={classes.imgSectionHolderOverlay}>
          <div className={classes.imgSectionHolderOverlayHeading}>CONNECTS WITH YOU.</div>
          <div className={classes.imgSectionHolderOverlayText}>Available on selected RANGER,EVEREST and ECOSPORT models. *</div>
          <a href={FordPass} target="_blank" download={true} rel="noreferrer">
            <button className={classes.imgSectionHolderButton}>FordPass Brochure</button>
          </a>
        </div>
      </div>
      <div className={classes.imgSectionAfterSales}>
        <div className={classes.HeadingHolderAfterSales}>
          <p className={classes.AfterSalesHeadingText}>Next-Generation</p>
          <div className={classes.AfterSalesHeading}>Ford Ranger</div>
          <p className={classes.AfterSalesHeadingText}>The Ranger you asked for is here.</p>
          <Link
            to="/show-room/FORD/774"
          >
            <button className={classes.AfterSalesButton}>See it now</button>
          </Link>
        </div>
        <div className={classes.imgEnd}>
          <img src={Ford} width="100%" />
        </div>
      </div>
      <div className={classes.imgSectionAfterSalesEverest}>
        <div className={classes.HeadingHolderAfterSales}>
          <p className={classes.AfterSalesHeadingText}>Next-Generation</p>
          <div className={classes.AfterSalesHeading}>Ford Everest</div>
          <p className={classes.AfterSalesHeadingText}>With next-generation technology.</p>
          <Link
            to="/show-room/FORD/760"
          >
            <button className={classes.AfterSalesButton}>See it now</button>
          </Link>
        </div>
        <div className={classes.imgEnd}>
          <img src={FordEverest} width="100%" />
        </div>
      </div>
      <div className={classes.vCardHeadingOutterHolderMain}  >
        <div className={classes.vCardHeadingOutterHolder}>
          <h1 className={classes.vCardHeadingOutterC}>
            Take a look at our Pre-Loved Range
          </h1>
          <h1 className={classes.vCardHeadingOutterOverlay}>
            EVEREST
          </h1>
        </div>
        <div className={classes.vCardHolder}>
          <div className={classes.vCardHolderInner}>
            {vehiclesList?.map((v) => {
              return (
                <div className={classes.vCard} onClick={() => {
                  handleRedirectUsed(v);
                }}>
                  <div>
                    <img src={v.image} width="100%" />
                  </div>
                  <div className={classes.vCardHeadingUsed}>
                    <h1 className={classes.vCardHeadingUsedAccent}>  {v.brand}</h1>
                    <div className={classes.vCardHeadingUsedAccentModel}>{v.model}</div>
                  </div>
                  <div className={classes.vCardFlexHolder}>
                    <div className={classes.vCardFlexItem} >
                      {v.year}
                    </div>
                    <div className={classes.vCardFlexItem} >
                      {v.mileage}
                    </div>
                    <div className={classes.vCardFlexItem} >
                      {v.newUsed}
                    </div>
                  </div>
                  <div className={classes.vCardFlexUsed}>
                    <h1 className={classes.vCardPriceUsed}>
                      R {v.price}
                    </h1>
                    <h1 className={classes.vCardPriceBottom}>
                      R {(v.price / 60).toFixed(0)} | 10% deposit | 60 months | 11% interest
                    </h1>
                  </div>
                </div>
              )
            })}
          </div>
          <div className={classes.vCardButtonNavHolder}>
            <Link
              to="/used-vehicles"
            >
              <button className={classes.vCardButtonOutline}>VIEW ALL</button>
            </Link>
            <div className={classes.vCardButtonNavFlex}>
              <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleBack() }}><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
              <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleNext() }}><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.BannerCarmac}>
        <div className={classes.HeadingHolderAfterSales}>
          <p className={classes.AfterSalesHeadingTextW}>Let keep you going with</p>
          <div className={classes.AfterSalesHeadingW}>Ford Parts & Service</div>
          <p className={classes.AfterSalesHeadingTextW}>We'll help keep your Ford in Great Shape</p>
          <button className={classes.AfterSalesButtonW}>Book Now</button>
        </div>
      </div>
      <div className={classes.ContactSectionHolder}>
        <Typography variant="h1" className={classes.heading} gutterBottom>
          <strong>Get in touch</strong>  with us today
        </Typography>
        <ContactSection />
      </div>
    </div>
  );
};

export default HomeComponentFord;


import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { DealerContext } from "../shared/contexts/DealerContext";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: "100px 15px",
    display: "flex",
    flexDirection: 'column',
    "@media (max-width: 500px)": {
      padding: "0px 0px",
    },
    "@media (min-width:501px) and (max-width: 768px)": {
      padding: "0px 0px",
    },
  },
  innerHolder: {
    display: "flex",
    flexWrap: "wrap",
    margin: " 0 auto",
    padding: "0px 50px",
    width: '80%',
    "@media (max-width: 500px)": {
      width: '100%',
      padding: " 20px 20px",
    },
    "@media (min-width:501px) and (max-width: 768px)": {
      width: '100%',
      padding: " 20px 20px",
    },
  },
  card: {
    width: "calc(100% / 3 - 40px )",
    minWidth: "calc(100% / 3 - 40px )",
    cursor: 'pointer',
    borderRadius: '10px',
    margin: "20px ",
    padding: "40px ",
    background: 'white',
    border: "2px solid #eef6f6",
    "&:hover": {
      background: 'white',
      border: ({ websiteColors }) => `2px solid ${websiteColors.accentColor}`,
    },
    "@media (max-width: 425px)": {
      width: "calc(100% / 1 )",
      minWidth: "calc(100% / 1  )",
      margin: "20px 0px",
    },
    "@media (min-width: 426px) and (max-width: 842px)": {
      width: "calc(100% / 2 - 20px )",
      margin: "  10px ",
    },
  },
  cardTextHolder: {
    padding: "0px",
    color: 'white',
    "@media (max-width: 768px)": {},
  },
  cardTextTitleHolder: {
    marginBottom: '10px'
  },
  cardTextTitle: {
    fontSize: '19px',
    fontWeight: '500',
  },
  imageHolder: {
  },
  cardTextTitle: {
    fontSize: '20px',
    textTransform: 'uppercase',
    margin: '5px 0px',
    color: "black"
  },
  cardTextTitleAccent: {
    fontSize: '20px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
  },
  cardTextTitleAccentTop: {
    fontSize: '25px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    margin: '10px 0px',
  },
  cardTextTitleAccentMore: {
    fontSize: '16px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    textTransform: 'uppercase',
    margin: '5px 0px',
  },
  titleTopAccent: {
    fontSize: '44px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    width: '80%',
    padding: '0px 70px',
    margin: '0 auto',
    fontWeight: 'bold',
    "@media (max-width:768px)": {
      padding: '20px 00px',
    },
  }
}));

const ShowroomRise = () => {
  const history = useHistory();
  const { newVehiclesList } = useContext(VehicleContext);
  const { websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });
  const [vehicles, setVehicles] = useState(newVehiclesList);
  const [heading, setHeading] = useState("FIELD TRACTORS");

  useEffect(() => {
    if (history.location.pathname == "/field-tractors") {
      setVehicles(newVehiclesList.filter((v) => !v.model.includes("Orchard")))
    } else if (history.location.pathname == "/orchard-series") {
      setVehicles(newVehiclesList.filter((v) => v.model.includes("Orchard")))
      setHeading("ORCHARD TRACTORS")
    }
  }, [newVehiclesList]);

  const handleRedirect = (data) => {
    history.push(`/show-room/${data?.make}/${data?.ownedModelId}`, data)
  };

  const formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 2
  });

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h1" className={classes.titleTopAccent}>
          {heading}
        </Typography>
        <div className={classes.innerHolder}>
          {vehicles.flat().map((v, index) => {
            return (
              <div
                className={classes.card}
                onClick={() => {
                  handleRedirect(v);
                }}
              >
                <div className={classes.imageHolder}>
                  <img src={v?.image} width="100%" />
                </div>
                <div className={classes.cardTextHolder}>
                  <div className={classes.cardTextTitleHolder}>
                    <p className={classes.cardTextTitleAccentTop}>{v.title}</p>
                    <h5 className={classes.cardTextTitle}>  <strong><span className={classes.cardTextTitleAccent}>From</span> {formatter.format(v.price)}</strong></h5>
                    <p className={classes.cardTextTitleAccentMore}>More Details</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ShowroomRise;

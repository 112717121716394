import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import data from './EventObjects'
import { DealerContext } from "../shared/contexts/DealerContext";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  rootInner: {
    maxWidth: '1770px',
    margin: '0 auto',
    padding: '100px 15px'
  },
  h1: {
    padding: '1rem 0px',
    textAlign: 'center',
    fontSize: "30px"
  },
  dividerTitle: {
    backgroundColor: ({ websiteColors }) => `${websiteColors.accentColor}`,
    width: '33%',
    marginBottom: '0px',
    height: '3px',
    margin: '0px auto'
  },
  blogCardHolder: {
    margin: '20px auto',
    display: 'flex',
    flexWrap: "wrap",
    paddingBottom: '20px',
  },
  blogCard: {
    width: 'calc(100% / 4 - 40px)',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: "20px",
    '&:hover': {
      boxShadow: ({ websiteColors }) => `0px 0px 2px 2px ${websiteColors.accentColor}`,
      cursor: 'pointer',
    },
    "@media (max-width: 920px)": {
      width: 'calc(100% / 1 - 40px)',
    },
  },
  blogCardBtn: {
    backgroundColor: 'unset',
    width: 'calc(100% / 3)',
    padding: '10px',
    margin: '0px 15px',
    marginBottom: '15px',
    cursor: 'pointer',
  },
  blogCardTextHolder: {
    padding: '0px 15px',
    fontWeight: 'bolder',
    marginBottom: '20px'
  },
  blogCardImg: {
    borderRadius: '5px 5px 0px 0px'
  },
  text: {
    margin: '5px 0px'
  },
  Heading: {
    margin: '5px 0px',
    fontWeight: 'bold',
    fontSize: '18px'
  }
}));

function Events() {
  const { websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });

  return (
    <div className={classes.root}>
      <div className={classes.rootInner}>
        <div className={classes.h1}>Events</div>
        <Divider className={classes.dividerTitle} />
        <div className={classes.blogCardHolder}>
          {data.map((b) => {
            return (
              <div className={classes.blogCard}>
                <img src={b.img} width="100%" className={classes.blogCardImg} />
                <div className={classes.blogCardTextHolder}>
                  <h2 className={classes.Heading}> {b.title}</h2>
                  <p className={classes.text}> Date: {b.date}</p>
                </div>
                <button className={classes.blogCardBtn}>
                  Coming Soon
                </button>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Events
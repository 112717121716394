import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { DealerContext } from "../shared/contexts/DealerContext";

const useStyles = makeStyles(() => ({
  root: {
    padding: '0px 10px'
  },
  btn: {
    padding: "10px 35px",
    border: "none",
    cursor: "pointer",
    background: "#083246",
    margin: "5px",
    borderRadius: "50px",
    color: "white",
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    border: ({ websiteColors }) => `${websiteColors.accentColor} 2px solid`,
    borderRadius: '50px',
  },
}))

const BackToSite = () => {
  const { websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });

  const handleBack = (dealer) => {
    window.location.href = `/`
  }

  return (
    <div className={classes.root}>
      <button className={classes.btn} onClick={() => { handleBack() }}>
        Back to BMA
      </button>
    </div>
  )
}

export default BackToSite
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { DealerContext } from "../shared/contexts/DealerContext";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "100px 15px",
    '@media (max-width: 500px)': {
      display: "block",
    },
  },
  inner: {
    maxWidth: "1170px",
  },
  textIntro: {
    fontSize: "14px",
    padding: "10px 0px",
    marginBottom: "0px",
    display: "block",
  },
  section: {
    width: '50%',
    paddingRight: "15px"
  },
  sectionHolder: {
    display: 'flex'
  },
  titleTopAccent: {
    fontSize: '44px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold'
  }
}));

const AboutUs = () => {

  const { websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });

  return (
    <>
      <div className={classes.root}>
        <div className={classes.inner}>
          <Typography variant="h1" className={classes.titleTopAccent}>
            About Us
          </Typography>
          <div className={classes.sectionHolder}>
            <div className={classes.section}>

              <Typography variant="p" className={classes.textIntro}>
              Established in 1988, Ben Morgenrood Group is a dynamic force in the automotive industry, proudly distributing Ford, GWM, Haval, Mahindra, and Chery vehicles.
              </Typography>

              <Typography variant="p" className={classes.textIntro}>
              The group, named after racing legend and co-founder Ben Morgenrood, extends its legacy from the roots of Randfontein Panel beaters, established in 1975.
              </Typography>

              <Typography variant="p" className={classes.textIntro}>
              In 2013, our commitment to diversity and excellence led us to expand our dealership to include Mahindra, enriching our offerings to cater to a broader range of automotive needs. The latest addition, Chery Randfontein, and our Mahindra Agri franchise, exemplify our dedication to providing a comprehensive vehicle and agricultural solution range.
              </Typography>
            </div>
            <div className={classes.section}>
              <Typography variant="p" className={classes.textIntro}>
              At Ben Morgenrood Group, our commitment to customer satisfaction is reflected in our dedicated on-site workshops and parts departments for all our brands. Whether you're exploring the latest Ford models, the rugged Mahindra lineup, or the innovative Haval and GWM vehicles, our skilled professionals ensure your vehicle receives top-notch service and genuine parts.
              </Typography>

              <Typography variant="p" className={classes.textIntro}>
              From our iconic Ben Morgenrood Ford building illuminating Main Reef Road to our commitment to community engagement, we strive for excellence in every aspect. The Ben Morgenrood Group is not just about vehicles; it's about a rich history, family values, a commitment to shaping the future of automotive excellence, and providing a one-stop destination for all your automotive and agricultural needs.
              </Typography>

              <Typography variant="p" className={classes.textIntro}>
              Join us on a journey where tradition meets innovation, community becomes family, and every need is met under the banner of the Ben Morgenrood Group.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;

import React, { useContext, useEffect, useState } from 'react'
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { MASTER_DEALERID, BASE_URI } from '../shared/Constants'
import { ContactUs as Form } from '@red-build/leadcentre';
import { DealerContext } from '../shared/contexts/DealerContext';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ALL_ACCESSORIES from './all_accessories';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "100px 15px",
    '@media (max-width: 600px)': {
      padding: "0px 0px",
    },
  },
  innerSection: {
    width: "100%",
    maxWidth: "1170px",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 15px",
    background: ({ props }) => `${props.home ? "white" : "unset"}`,
    padding: '20px',
    borderRadius: '10px',
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: "0px 0px",
    },
  },
  section: {
    width: '60%',
    '&:nth-child(1)': {
      marginRight: '40px',
      width: '40%',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',
      '&:nth-child(1)': {
        margin: '0px',
        padding: '0px 20px',
        width: '100%',

      },
    },
  },
  text: {
    fontSize: '16px',
  },
  titleTop: {
    fontSize: '44px',
    fontWeight: 'bold'
  },
  titleTopAccent: {
    fontSize: '52px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  dealerName: {
    fontSize: '18px',
    margin: '5px 0px',
    fontWeight: 'bold!important',
  },
  ctaTop: {
    padding: "10px 35px",
    cursor: "pointer",
    marginTop: "10px",
    marginBottom: "10px",
    color: "white",
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    border: ({ websiteColors }) => `${websiteColors.accentColor} 2px solid`,
    borderRadius: '50px',
  },
}))

const Accessories = (props) => {
  const { globalDealer, dealerList, dealerListDirty, websiteColors } = useContext(DealerContext)
  const [activeDealer, setActiveDealer] = useState();
  const { renderbutton } = props;
  const classes = useStyles({ websiteColors, props });

  useEffect(() => {
    setActiveDealer(dealerListDirty?.filter((d) => d.dealerId == globalDealer?.id)[0])
  }, [dealerListDirty]);

  const renderAccesoryList = (brands) => {
    const accessories = ALL_ACCESSORIES
      .filter(row => [...brands, 'GROUP MERCHANDISE'].includes(row.brand))
      .sort((a, b) => a?.partnumber.localeCompare(b?.partnumber));
    if (accessories.length === 0) return null;
    return (
      <>
        <div className={classes.innerSection}>
          <Typography variant="h1" className={classes.titleTop}>
            All Accessories
          </Typography>
        </div>
        <div className={classes.innerSection}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Part number</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Brand</TableCell>
                  <TableCell>Vehicle</TableCell>
                  <TableCell align="right">Retail Price (R)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accessories
                  .map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.partnumber}
                      </TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell>{row.brand}</TableCell>
                      <TableCell>{row.vehicle}</TableCell>
                      <TableCell align="right">{row.retail}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.section} >
          <Typography variant="h1" className={classes.titleTop}>
            {!props.heading ? "NEED " : props.heading}
          </Typography>
          <Typography variant="h1" className={classes.titleTopAccent}>
            {!props.subHeading ? "Accessories ?" : props.subHeading}
          </Typography>
          <Typography variant="body2" className={classes.text} style={{ paddingBottom: '20px' }}>
            Leave a message, we will get back to you.
          </Typography>
          {
            renderbutton &&
            (<a href={'/Accessories.pdf'} className={classes.ctaTop}>
              View Accessories List
            </a>)
          }
        </div>
        <div className={classes.section}>
          <Form
            dealerId={globalDealer?.id}
            base_uri={BASE_URI}
            leadTypeId={3}
            masterDealerId={MASTER_DEALERID}
            activeDealerId={globalDealer?.id}
            dealerName={globalDealer?.name?.replace("LSG", " ")?.replace("Master", " ")}
            dealers={dealerList}
            dealerType="multi_dealer"
            note={true} />
        </div>
      </div>
      {
        !renderbutton &&
        (renderAccesoryList(activeDealer?.relatedBrands?.filter(rb => rb?.name).map(rb => rb?.name) || []))
      }
    </div>
  )
}

export default Accessories


import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { DealerContext } from "../shared/contexts/DealerContext";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "100px 15px",
    '@media (max-width: 500px)': {
      display: "block",
    },
  },
  inner: {
    maxWidth: "1170px",
  },
  textIntro: {
    fontSize: "14px",
    padding: "10px 0px",
    marginBottom: "0px",
    display: "block",
  },
  section: {
    width: '50%',
    paddingRight: "15px"
  },
  sectionHolder: {
    display: 'flex'
  },
  titleTopAccent: {
    fontSize: '44px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold'
  }
}));

const AboutUsChery = () => {
  const { websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });
  return (
    <>
      <div className={classes.root}>
        <div className={classes.inner}>
          <Typography variant="h1" className={classes.titleTopAccent}>
            About Us
          </Typography>
          <div className={classes.sectionHolder}>
            <div className={classes.section}>
            <Typography variant="p" className={classes.textIntro}>
            Welcome to Chery Randfontein, the latest addition to the esteemed Ben Morgenrood Group. Our doors opened in February of 2024, and since then, we've been dedicated to providing exceptional service and a diverse range of Chery vehicles to our valued customers.
              </Typography>

              <Typography variant="p" className={classes.textIntro}>
              Our Commitment: At Chery Randfontein, our commitment to customer satisfaction is unwavering. As the newest member of the Ben Morgenrood Group, we bring fresh energy and a modern approach to ensure you experience the highest standards of quality and professionalism.
              </Typography>

              <Typography variant="p" className={classes.textIntro}>
              Our Range: Discover the latest Chery models in our showroom, each embodying cutting-edge technology, stylish design, and reliable performance. Whether you're navigating city streets or embarking on family adventures, our range of Chery vehicles offers the perfect match for your lifestyle.
              </Typography>
            </div>
            <div className={classes.section}>
              <Typography variant="p" className={classes.textIntro}>
              On-Site Workshops and Parts Departments: Our commitment extends beyond sales. Chery Randfontein features dedicated on-site workshops and parts departments, staffed by skilled professionals. Whether you require routine maintenance, repairs, or genuine Chery parts, our team is here to keep your vehicle running smoothly.
              </Typography>

              <Typography variant="p" className={classes.textIntro}>
              Embark on the Chery experience at Chery Randfontein, the newest gem in the Ben Morgenrood Group family.
              </Typography>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsChery;

import React, { useContext } from 'react'
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { BASE_URI, MOTORGROUPID } from '../shared/Constants'
import { Service } from '@red-build/leadcentre';
import { DealerContext } from '../shared/contexts/DealerContext'

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingTop: '60px',
    marginBottom: '60px'
  },
  innerSection: {
    width: "100%",
    maxWidth: "1170px",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 15px",
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: "6px 0px",
    },
  },
  section: {
    width: '60%',
    '&:nth-child(1)': {
      marginRight: '40px',
      width: '40%',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',
      '&:nth-child(1)': {
        margin: '0px',
        padding: '0px 20px',
        width: '100%',

      },
    },
  },
  text: {
    fontSize: '16px',
  },
  titleTop: {
    fontSize: '44px',
    fontWeight: 'bold'
  },
  titleTopAccent: {
    fontSize: '52px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold'
  },
  ctaTop: {
    padding: "10px 35px",
    cursor: "pointer",
    marginTop: "10px",
    marginBottom: "10px",
    color: "white",
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    border: ({ websiteColors }) => `${websiteColors.accentColor} 2px solid`,
    borderRadius: '50px',
  },
}))

const BookService = (props) => {
  const { dealerList, globalDealer, websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });
  const { renderbutton } = props;

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.section} >
          <Typography variant="h1" className={classes.titleTop}>
            BOOK A
          </Typography>
          <Typography variant="h1" className={classes.titleTopAccent}>
            SERVICE
          </Typography>
          <Typography variant="body2" className={classes.text} style={{ paddingBottom: "20px" }}>
            We will happily assist in the service of your vehicle, leave your details and one of our service advisors will contact you shortly.
          </Typography>
          {
            renderbutton &&
            (<a href={'/Service-guide.pdf'} className={classes.ctaTop}>
              View Service Guide
            </a>)
          }
        </div>
        <div className={classes.section}>
          <Service
            base_uri={BASE_URI}
            dealerName={globalDealer?.name}
            leadTypeId={5}
            motorGroupId={MOTORGROUPID}
            dealerList={dealerList}
            hasMultiDealers={true}
          />
        </div>
      </div>
    </div>
  )
}

export default BookService
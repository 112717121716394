import React, { useContext, useEffect, useState } from 'react'
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { MASTER_DEALERID, BASE_URI, DEALERID } from '../shared/Constants'
import { ContactUs as Form } from '@red-build/leadcentre';
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "100px 15px",
    '@media (max-width: 600px)': {
      padding: "0px 0px",
    },
  },
  innerSection: {
    width: "100%",
    maxWidth: "1170px",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 15px",
    background: ({ props }) => `${props.home ? "white" : "unset"}`,
    padding: '20px',
    borderRadius: '10px',
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: "0px 0px",
    },
  },
  innerSectionMap: {
    width: "100%",
    maxWidth: "1170px",
    display: "flex",
    flexWrap: 'wrap',
    padding: "6px 15px",
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: "0px 0px",
    },
  },
  innerSectionMapIframe: {
    minWidth: 'calc(100% / 3 - 40px)',
    maxWidth: 'calc(100% / 3 - 40px)',
    margin: '20px',
    background: 'white',
    padding: '0px',
    borderRadius: '4px',
    padding: '15px'
  },
  section: {
    width: '60%',
    '&:nth-child(1)': {
      marginRight: '40px',
      width: '40%',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',
      '&:nth-child(1)': {
        margin: '0px',
        padding: '0px 20px',
        width: '100%',
      },
    },
  },
  text: {
    fontSize: '16px',
  },
  titleTop: {
    fontSize: '44px',
    fontWeight: 'bold'
  },
  titleTopAccent: {
    fontSize: '52px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold'
  },
  black: {
    backgroundColor: ({ websiteColors }) => `${websiteColors.accentColor}`,
    color: 'white!important'
  },
  yellow: {
    backgroundColor: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    fontWeight: 'bold',
    color: 'white!important'
  },
  btn: {
    backgroundColor: '#f0f8ff00',
    border: 'none',
    padding: "10px ",
    cursor: 'pointer',
    color: 'white!important'
  },
  dividerTitle: {
    backgroundColor: ({ websiteColors }) => `${websiteColors.accentColor}`,
    height: '3px',
    margin: '10px auto'
  },
  dealerName: {
    fontSize: '18px',
    margin: '5px 0px',
    fontWeight: 'bold!important',
  },
  flexHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0px',
  }
}))

const ContactUs = (props) => {
  const { globalDealer, dealerList, dealerListDirty, websiteColors } = useContext(DealerContext)
  const [activeDealer, setActiveDealer] = useState()
  const [fordList, setfordList] = useState([])
  const classes = useStyles({ websiteColors, props });

  useEffect(() => {
    if (globalDealer.id == 498) {
      setfordList(dealerListDirty?.filter((d) => d.name.includes("Ford")))
    }
    setActiveDealer(dealerListDirty?.filter((d) => d.dealerId == globalDealer?.id)[0])
  }, [dealerListDirty])


  const cleanName = (dealerName) => {
    return dealerName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-')
  }

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.section} >
          <Typography variant="h1" className={classes.titleTop}>
            {!props.heading ? "LEAVE US A" : props.heading}
          </Typography>
          <Typography variant="h1" className={classes.titleTopAccent}>
            {!props.subHeading ? "MESSAGE" : props.subHeading}
          </Typography>
          <Typography variant="body2" className={classes.text}>
            Leave a message, we will get back to you.
          </Typography>
          <div className={classes.flexHolder}>
            <div>
              <div>
                Contact Us
              </div>
              <div className={classes.fontSmall}>
  {activeDealer?.name && activeDealer.name.toLowerCase().includes("chery") ? (
    <>
      <div>{activeDealer?.newCarsPhoneNumber}</div>
      <div>010 746 5697</div>
    </>
  ) : (
    <div>{activeDealer?.newCarsPhoneNumber || "075 830 3564"}</div>
  )}
</div>

            </div>
            <div>
              <div>Address </div>
              <div className={classes.fontSmall}>{activeDealer?.address?.postalAddress}</div>
            </div>
          </div>
          {globalDealer.id == DEALERID &&
            <iframe src={`https://maps.google.com/maps?q=${activeDealer?.addressLongitude},${activeDealer?.addressLatitude}&via=0&z=16&output=embed`} width="100%" height="335px" frameBorder="none"></iframe>
          }
          {globalDealer.id !== (DEALERID || 498) &&
            <iframe src={`https://maps.google.com/maps?q=${activeDealer?.addressLongitude},${activeDealer?.addressLatitude}&via=0&z=16&output=embed`} width="100%" height="335px" frameBorder="none"></iframe>
          }
        </div>
        <div className={classes.section}>
          <Form
            dealerId={globalDealer?.id}
            base_uri={BASE_URI}
            leadTypeId={3}
            masterDealerId={MASTER_DEALERID}
            activeDealerId={globalDealer?.id}
            dealerName={globalDealer?.name?.replace("LSG", " ")?.replace("Master", " ")}
            dealers={dealerList}
            dealerType="multi_dealer"
            note={true} />
        </div>
      </div>
      {!props.hidden && globalDealer.id == DEALERID &&
        <div className={classes.innerSectionMap}>
          {dealerList?.map((d) => {
            return (
              <div className={classes.innerSectionMapIframe}>
                <p variant="h6" className={classes.dealerName}> {d?.name}</p>
                <hr className={classes.dividerTitle} />
                <div   >Address </    div>
                <div className={classes.fontSmall}>{d?.address?.postalAddress}</div>
                <br />
                <div >Business Hours</div>
                <div className={classes.fontSmall}>Monday - Friday: 8:00 - 17:00</div>
                <div className={classes.fontSmall}>Saturday: 8:00 - 13:00</div>
                <div className={classes.black}>
                  <button className={classes.btn} onClick={() => window.location.href = `/dealer/${cleanName(d?.name)}/used-vehicles`}>View Used Vehicles From {d?.name}</button>
                </div>
                <div className={classes.yellow}>
                  <button className={classes.btn} onClick={() => window.location.href = `/dealer/${cleanName(d?.name)}`}>Go To The {d?.name} Website</button>
                </div>
                <iframe src={`https://maps.google.com/maps?q=${d?.addressLongitude},${d?.addressLatitude}&via=0&z=16&output=embed`} width="100%" height="300px" frameBorder="none"></iframe>
              </div>
            )
          })}
        </div>
      }
      {globalDealer.id == 498 &&
        <div className={classes.innerSectionMap}>
          {fordList?.map((d) => {
            return (
              <div className={classes.innerSectionMapIframe}>
                <p variant="h6" className={classes.dealerName}> {d?.name}</p>
                <hr className={classes.dividerTitle} />
                <div>Address </div>
                <div className={classes.fontSmall}>{d?.address?.postalAddress}</div>
                <br />
                <div >Business Hours</div>
                <div className={classes.fontSmall}>Monday - Friday: 8:00 - 17:00</div>
                <div className={classes.fontSmall}>Saturday: 8:00 - 13:00</div>
                <div className={classes.black}>
                  <button className={classes.btn} onClick={() => window.location.href = `/dealer/${cleanName(d?.name)}/used-vehicles`}>View Used Vehicles From {d?.name}</button>
                </div>
                <div className={classes.yellow}>
                  <button className={classes.btn} onClick={() => window.location.href = `/dealer/${cleanName(d?.name)}`}>Go To The {d?.name} Website</button>
                </div>
                <iframe src={`https://maps.google.com/maps?q=${d?.addressLongitude},${d?.addressLatitude}&via=0&z=16&output=embed`} width="100%" height="380px" frameBorder="none"></iframe>
              </div>
            )
          })}
        </div>
      }
    </div>
  )
}

export default ContactUs


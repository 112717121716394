import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { DealerContext } from "../shared/contexts/DealerContext";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "100px 15px",
    '@media (max-width: 500px)': {
      display: "block",
    },
  },
  inner: {
    maxWidth: "1170px",
  },
  textIntro: {
    fontSize: "14px",
    padding: "10px 0px",
    marginBottom: "0px",
    display: "block",
  },
  textIntroHeadline: {
    fontSize: "18px",
    fontWeight: "800",
    padding: "10px 0px 0px 0px;",
    marginBottom: "0px",
    display: "block",
  },
  section: {
    width: '50%',
    paddingRight: "15px"
  },
  sectionHolder: {
    display: 'flex'
  },
  titleTopAccent: {
    fontSize: '44px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold'
  }
}));

const AboutUsMahindraVehicles = () => {

  const { websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });

  return (
    <>
      <div className={classes.root}>
        <div className={classes.inner}>
          <Typography variant="h1" className={classes.titleTopAccent}>
            About Us
          </Typography>
          <div className={classes.sectionHolder}>
            <div className={classes.section}>
            <Typography variant="p" className={classes.textIntroHeadline}>Welcome</Typography>
              <Typography variant="p" className={classes.textIntro}>
              Welcome to Mahindra Randfontein, a cornerstone of the esteemed Ben Morgenrood Group. Our commitment to the community goes beyond providing exceptional service and a diverse range of Mahindra vehicles. As Mahindra Randfontein, we are proud to be an integral part of the local landscape, dedicated to making a positive impact.
              </Typography>

              <Typography variant="p" className={classes.textIntroHeadline}>Community-Centric Approach:</Typography>
              <Typography variant="p" className={classes.textIntro}>
              At Mahindra Randfontein, we believe in giving back to the community that has embraced us. Our commitment to community involvement goes hand in hand with our mission to provide not just vehicles, but a holistic automotive experience that contributes positively to the lives of those we serve.
              </Typography>

              <Typography variant="p" className={classes.textIntroHeadline}>Mahindra M-Care Fest Winners:</Typography>
              <Typography variant="p" className={classes.textIntro}>
              We are honoured to have been recognized at the annual Mahindra M-Care Fest, where Mahindra Randfontein stood out as a winner. This achievement is a testament to our commitment to customer care and service excellence. However, we acknowledge that our success is intricately tied to the active involvement and support of our local community.
              </Typography>

              <Typography variant="p" className={classes.textIntroHeadline}>Giving Back:</Typography>
              <Typography variant="p" className={classes.textIntro}>
              Mahindra Randfontein is not just a dealership; we are a partner in community development. Our initiatives aim to make a meaningful difference, whether through local events, charitable contributions, or collaborative projects that uplift the lives of those around us. We believe in fostering a sense of belonging and shared success.
              </Typography>
            </div>
            <div className={classes.section}>
            <Typography variant="p" className={classes.textIntroHeadline}>Explore Our Mahindra Range:</Typography>
              <Typography variant="p" className={classes.textIntro}>
              Step into our showroom and explore the latest Mahindra models, each embodying innovation, rugged performance, and practical design. Whether you're in search of a reliable SUV or a versatile utility vehicle, our range caters to a variety of needs.
              </Typography>

              <Typography variant="p" className={classes.textIntroHeadline}>On-Site Services:</Typography>
              <Typography variant="p" className={classes.textIntro}>
              Our commitment goes beyond sales. Mahindra Randfontein features dedicated on-site workshops and parts departments staffed by skilled professionals. From routine maintenance to genuine parts, our team is here to keep your Mahindra vehicle running smoothly. Join us at Mahindra Randfontein, where the Mahindra experience is not just about vehicles but also about being an active participant in the well-being of our community. Your support has played a crucial role in our success, and together, we look forward to many more milestones.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsMahindraVehicles;

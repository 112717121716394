import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { BASE_URI } from "../shared/Constants";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { DealerContext } from "../shared/contexts/DealerContext";
import { useHistory } from "react-router-dom";
import RightImg from '../shared/assets/haval_h6_8.png'
import LeftImgTop from '../shared/assets/top.png'
import LeftImgBot from '../shared/assets/bot.png'
import useMediaQuery from "../shared/useMediaQuery";
import Grudge from '../shared/assets/Grudge.png'

const useStyles = makeStyles(() => ({
  root: {
    background: "whitesmoke",
  },
  img: {
    height: "100%",
    maxHeight: "160px",
    margin: '10px 0px',
    transition: '0.5s ease all',
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.1)"
    },
  },
  list: {
    width: 'calc(100% / 2);',
    textAlign: "left"
  },
  MainImg: {
    filter: 'brightness(0.7)',
  },
  vCardHeadingOutterC: {
    fontSize: '40px',
    width: 'fit-content',
    color: "black",
    borderBottom: ({ websiteColors }) => `2px solid ${websiteColors.accentColor}`,
    marginTop: "40px",
  },
  OwnersAreaHeading: {
    fontSize: '40px',
    color: "black",
    marginTop: "10px",
    marginBottom: '10px',
  },
  vCardHeadingOutterHolder: {
    position: 'relative',
    display: 'flex',
    width: '80%',
    margin: '0 auto',
  },
  vCardHeadingOutterHolderU: {
    position: 'relative',
    width: '80%',
    margin: '0 auto',
  },
  vCardHeadingOutterHolderC: {
    position: 'relative',
    display: 'flex',
    width: '80%',
    margin: '0 auto',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  vCardHeadingOutterHolderMain: {
    padding: '40px 0px',
  },
  vCardHeadingOutterHolderMainTop: {
    padding: '40px 0px',
    background: `url(${Grudge})`,
    backgroundSize: 'contain',
    backgroundColor: 'whitesmoke',
    backgroundBlendMode: "color-burn",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  vCardHolder: {
    overflow: "hidden",
    width: '80%',
    margin: '0 auto'
  },
  vCardHolderInner: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    transition: 'all 0.5s ease',
    transform: ({ slidePosition }) => (!slidePosition == 0 ? `translateX(${slidePosition}%)` : `translateX(0%)`),
    "@media (max-width: 600px)": {
      transform: ({ slidePosition }) => (!slidePosition <= 0 ? `translateX(${slidePosition}%)` : `translateX(0%)`),
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      transform: ({ slidePosition }) => (!slidePosition <= 0 ? `translateX(${slidePosition}%)` : `translateX(0%)`),
    },
  },
  vCard: {
    minWidth: "calc(100% / 3 - 80px)",
    margin: '0px 40px',
    background: 'white',
    borderRadius: '10px',
    border: '1.5px solid',
    padding: '20px',
    "@media (max-width: 600px)": {
      minWidth: "calc(100% / 1 - 20px)",
      margin: '0px 10px',
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      minWidth: "calc(100% / 2 - 20px)",
      margin: '0px 10px',
    },
  },
  vCard1Img: {
    width: "30%",
    margin: '0 auto',
    cursor: 'pointer',
    "@media (max-width: 600px)": {
      width: "90%",
    },
  },
  vCard1: {
    minWidth: "calc(100% / 1 )",
    cursor: 'pointer',
    borderRadius: '10px',
    "@media (max-width: 600px)": {
      minWidth: "calc(100% / 1 - 20px)",
      margin: '0px 10px',
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      minWidth: "calc(100% / 1 - 20px)",
      margin: '0px 10px',
    },
  },
  vCardHeadingUsed: {
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    fontSize: '20px',
  },
  vCardHeadingUsedAccent: {
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontSize: '30px',
    margin: '0px'
  },
  vCardHeadingUsedAccentModel: {
    color: 'grey',
    fontSize: '16px',
    margin: '0px',
    paddingBottom: "10px"
  },
  vCardPriceUsed: {
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontSize: '40px',
    fontWeight: 'bold',
    margin: "0px",
    paddingTop: '20px',
    paddingBottom: '10px',
  },
  vCardPriceBottom: {
    color: 'grey',
    borderTop: '1.5px solid #a5a4a4',
    fontSize: '14px',
    paddingTop: '10px',
    fontWeight: 'bold',
    margin: "0px"
  },
  vCardFlexUsed: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  vCardFlexHolder: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: '1.5px solid #a5a4a4',
    paddingTop: '10px',
  },
  vCardFlexItem: {
    textAlign: 'center',
    width: 'calc(100% /3)',
  },
  vCardButtonOutline: {
    border: ({ websiteColors }) => `2px solid ${websiteColors.accentColor}`,
    padding: "10px 50px",
    borderRadius: '50px',
    color: "black",
    margin: "10px 0px",
    background: 'transparent',
    cursor: 'pointer'
  },
  vCardButtonNav: {
    padding: "10px 10px",
    borderRadius: '50px',
    margin: "10px 0px",
    fill: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    cursor: 'pointer'
  },
  vCardButtonNavFlex: {
    display: 'flex',
    width: '80px'
  },
  vCardButtonNavHolder: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  vCardButtonNavHolderB: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '40px'
  },
  model: {
    color: 'black',
    padding: '10px 20px',
    width: 'calc(100% /4)',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '18px',
  },
  modelActive: {
    color: 'black',
    padding: '10px 20px',
    width: 'calc(100% /4)',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '18px',
    borderBottom: ({ websiteColors }) => `4px solid ${websiteColors.accentColor}`,
  },
  slideHolder: {
    position: 'relative',
    height: 'calc(100% - 99px)',
    '@media (max-width: 425px)': {
      marginTop: '0px',
    },
  },
  activeModelHeading: {
    color: 'black',
    textAlign: 'center',
    fontSize: '50px',
    '@media (max-width: 613px)': {
      fontSize: '40px',
    },
  },
  activeModelDescHolder: {
    display: 'flex',
    justifyContent: "center",
    flexWrap: 'wrap',
  },
  activeModelDesc: {
    color: 'black',
    textAlign: 'center',
    fontSize: '30px',
    margin: "0px 50px",
    paddingBottom: '50px',
    '&::after': {
      content: '" "',
      display: 'block',
      height: 4,
      background: ({ websiteColors }) => `${websiteColors.accentColor}`,
      left: '0px',
      width: '50%',
      margin: '40px auto'
    }
  },
  activeModelDescS: {
    color: 'black',
    textAlign: 'center',
    fontSize: '30px',
    margin: "0px 50px",
    paddingBottom: '50px',
    cursor: 'pointer',
  },
  activeModelDescSActive: {
    color: 'black',
    textAlign: 'center',
    fontSize: '30px',
    margin: "0px 50px",
    cursor: 'pointer',
    paddingBottom: '50px',
    '&::after': {
      content: '" "',
      display: 'block',
      height: 4,
      background: ({ websiteColors }) => `${websiteColors.accentColor}`,
      left: '0px',
      margin: '10px auto'
    }
  },
  activeModelButtonHolder: {
    display: 'flex',
    justifyContent: 'center',
    padding: "30px 0px",
    flexWrap: 'wrap',
  },
  activeModelButton: {
    color: 'black',
    fontSize: '20px',
    padding: '10px 80px',
    cursor: 'pointer'
  },
  activeModelInfoHolder: {
    display: "flex",
    padding: "0px 50px",
    alignItems: 'center',
    '@media (max-width: 613px)': {
      flexWrap: 'wrap',
    },
  },
  activeModelInfoHolderHeading: {
    color: "black",
    padding: "0px 50px",
    fontSize: '40px',
    '@media (max-width: 613px)': {
      padding: "0px 0px",
    },
    '&::before': {
      content: '" "',
      display: 'block',
      height: 2,
      background: ({ websiteColors }) => `${websiteColors.accentColor}`,
      margin: '40px 0px',
      right: '0px',
      width: '50%',
      bottom: "0px",
    },
  },
  activeModelInfoHolderText: {
    color: "black",
    fontSize: '20px',
    padding: "30px 50px",
    '@media (max-width: 613px)': {
      padding: "30px 0px",
    },
  },
  activeModelInfoSub: {
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontSize: '20px',
    padding: "30px 50px"
  },
  activeModelInfoHolderImg: {
    minWidth: "50%"
  },
  OwnersArea: {
    display: 'flex',
    flexWrap: "wrap",
    '@media (max-width: 613px)': {
      flexDirection: 'column'
    },
  },
  OwnersAreaGroup: {
    paddingRight: '40px',
    '@media (max-width: 613px)': {
      paddingRight: '0px',
    },
  },
  OwnersAreaImg: {
    width: '50%',
    '@media (max-width: 613px)': {
      width: 'unset',
    },
  },
  vCardButtonNavMain: {
    padding: "10px 10px",
    borderRadius: '50px',
    margin: "10px 0px",
    fill: ({ websiteColors }) => `${websiteColors.accentColor}`,
    cursor: 'pointer'
  },
  vCardButtonNavFlexMain: {
    display: 'flex',
    width: '80px',
    bottom: '0px',
    right: '0px',
    position: 'absolute'
  }
}));

const HomeComponentGwm = () => {
  const history = useHistory();
  const { vehiclesList, newVehiclesList } = useContext(VehicleContext);
  const { globalDealer, websiteColors } = useContext(DealerContext)
  const [slides, setSlides] = useState();
  const [slidesLoading, setSlidesLoading] = useState(true);
  const [slideIndexMain, setSlideIndexMain] = useState(0);
  const mountedRef = useRef(true);
  const [slidePosition, setSlidePosition] = useState(0)
  const [slideIndex, setSlideIndex] = useState(0)
  const classes = useStyles({ websiteColors, slidePosition });
  const [activeModel, setActiveModel] = useState("")
  const [activeVehicles, setActiveVehicles] = useState("")
  const [activeGallery, setActiveGallery] = useState("")
  const [activeGalleryText, setActiveGalleryText] = useState("")
  const [activeGalleryHeading, setActiveGalleryHeading] = useState("")
  const [docs, setDocs] = useState("")
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 1333px)');
  const isDesktop = useMediaQuery('(min-width: 1334px)');

  useEffect(() => {
    setActiveModel(newVehiclesList[0])
    axios({
      method: "GET",
      url: `${BASE_URI}/DealerModelInformation/GetForDealerModel/${newVehiclesList[0]?.ownedModelId}`,
    })
      .then((response) => {
        setActiveVehicles(response.data);
        setDocs(response.data.brochures[0]?.document?.filePath);
        setActiveGallery(response.data?.contentImages[0]?.image?.imageUrl)
        setActiveGalleryHeading(response.data.contentTitle1)
        setActiveGalleryText(response.data.contentDesc1)
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  }, [newVehiclesList])

  const updateVehicle = (data) => {
    axios({
      method: "GET",
      url: `${BASE_URI}/DealerModelInformation/GetForDealerModel/${data?.ownedModelId}`,
    })
      .then((response) => {
        setActiveVehicles(response.data);
        setActiveGallery(response.data?.contentImages[0]?.image?.imageUrl)
        setActiveGalleryHeading(response.data.contentTitle1)
        setActiveGalleryText(response.data.contentDesc1)
        setDocs(response.data.brochures[0]?.document?.filePath);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getSlides = async () => {
      try {
        const params = {
          pageNumber: 0,
          pageSize: 100,
          status: "active",
          dealerId: globalDealer.id,
        };

        const result = await axios({
          method: "GET",
          url: `${BASE_URI}/slide`,
          params,
          cancelToken: source.token,
        });

        let tempSlides = result.data.list.map((itm) => {
          return {
            img: itm.slideImageUrl,
            isVideo: itm.isVideo,
            targetUrl: itm.url
          };
        });
        setSlides(tempSlides);
        setSlidesLoading(false)
      } catch (error) {
        console.warn('There was an error fetching slides.', error);
      }
    };

    getSlides().then(() => {
      if (!mountedRef.current) return null;
    });
    return () => {
      mountedRef.current = false;
      source.cancel();
    };
  }, []);

  const handleRedirect = (data) => {
    history.push(`/show-room/${data?.make}/${data?.ownedModelId}`, data)
  };

  const handleRedirectUsed = (data) => {
    history.push(`/pre-owned/${data?.brand}/${data?.stockId}`, data)
  };

  const handleBack = () => {
    if (slidePosition < 0) {
      if (isDesktop) {
        setSlidePosition(slidePosition + (100))
        setSlideIndex(slideIndex - 1)
      }

      if (isMobile) {
        setSlidePosition(slidePosition + (100))
        setSlideIndex(slideIndex - 1)
      }

      if (isTablet) {
        setSlidePosition(slidePosition + (100))
        setSlideIndex(slideIndex - 1)
      }
    }
  }

  const handleNextUsed = () => {
    if (isDesktop) {
      if (slideIndex < vehiclesList.length - 3) {
        setSlidePosition(slidePosition - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === vehiclesList.length - 3) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }

    if (isMobile) {
      if (slideIndex < vehiclesList.length - 1) {
        setSlidePosition(slidePosition - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }

      if (slideIndex === vehiclesList.length - 1) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }

    if (isTablet) {
      if (slideIndex < vehiclesList.length - 3) {
        setSlidePosition(slidePosition - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === vehiclesList.length - 3) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }
  }

  const handleModelSelect = (data) => {
    setActiveModel(data)
    updateVehicle(data)
  }

  const handleInfoUpdate = (title, text, img) => {
    setActiveGalleryText(text)
    setActiveGalleryHeading(title)
    setActiveGallery(img)
  }

  const handleNextSlide = () => {
    if (slideIndexMain < slides.length - 1) {
      setSlideIndexMain(slideIndexMain + 1)
    }
    if (slideIndexMain === slides.length - 1) {
      setSlideIndexMain(0)
    }
  };

  const handleBackSlide = () => {
    if (slideIndexMain > 0) {
      setSlideIndexMain(slideIndexMain - 1)
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.slideHolder}>
        {!slidesLoading &&
          <img src={slides[slideIndexMain]?.img} width="100%" className={classes.MainImg} />
        }
        <div className={classes.vCardButtonNavFlexMain}>
          <svg className={classes.vCardButtonNavMain} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleBackSlide() }}><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
          <svg className={classes.vCardButtonNavMain} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleNextSlide() }}><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
        </div>
      </div>
      {/*<div className={classes.vCardHeadingOutterHolderMainTop}  >
        <div className={classes.vCardHeadingOutterHolderC}>
          {newVehiclesList.map((v) => {
            return (
              <div className={v.title == activeModel?.title ? classes.modelActive : classes.model} onClick={() => { handleModelSelect(v) }}>{v.title}</div>
            )
          })}
        </div>
        <div className={classes.vCardHolder}>
          <div className={classes.vCardHolderInner}>
            {newVehiclesList.filter((v) => v.title == activeModel?.title).map((v) => {
              return (
                <div className={classes.vCard1} onClick={() => {
                  handleRedirect(v)
                }}>
                  <div className={classes.vCard1Img}>
                    <img src={v.image} width="100%" />
                  </div>
                </div>
              )
            })}
          </div>
          <div className={classes.vCardButtonNavHolder}>
            <Link
              to="/show-room"
            >
              <button className={classes.vCardButtonOutline}>VIEW ALL</button>
            </Link>
          </div>
        </div>
      </div>*/}
      <div className={classes.activeModelInfo}>
        <div className={classes.activeModelHeading}>
          {activeVehicles?.introductionTitle}
        </div>
        <div className={classes.activeModelButtonHolder}>
          <div className={classes.activeModelButton}>
            View Deals
          </div>
          <a href={docs} download>
            <div className={classes.activeModelButton}>
              Download Brochure
            </div>
          </a>
          <div className={classes.activeModelButton}>
            Book a Test Drive
          </div>
        </div>
        <div className={classes.activeModelDesc}>
          {activeVehicles?.introductionDesc}
        </div>
        <div className={classes.activeModelDescHolder}>
          <div className={activeGalleryHeading == activeVehicles?.contentTitle1 ? classes.activeModelDescSActive : classes.activeModelDescS} onClick={() => { handleInfoUpdate(activeVehicles?.contentTitle1, activeVehicles?.contentDesc1, activeVehicles?.contentImages[0]?.image?.imageUrl) }}>
            {activeVehicles?.contentTitle1}
          </div>
          <div className={activeGalleryHeading == activeVehicles?.contentTitle2 ? classes.activeModelDescSActive : classes.activeModelDescS} onClick={() => { handleInfoUpdate(activeVehicles?.contentTitle2, activeVehicles?.contentDesc2, activeVehicles?.contentImages[1]?.image?.imageUrl) }}>
            {activeVehicles?.contentTitle2}
          </div>
          <div className={activeGalleryHeading == activeVehicles?.contentTitle3 ? classes.activeModelDescSActive : classes.activeModelDescS} onClick={() => { handleInfoUpdate(activeVehicles?.contentTitle3, activeVehicles?.contentDesc3, activeVehicles?.contentImages[2]?.image?.imageUrl) }}>
            {activeVehicles?.contentTitle3}
          </div>
        </div>
        <div className={classes.activeModelInfoHolder}>
          <div>
            <div className={classes.activeModelInfoSub}>
              01
            </div>
            <div className={classes.activeModelInfoHolderHeading}>
              {activeGalleryHeading}
            </div>
            <div className={classes.activeModelInfoHolderText}>
              {activeGalleryText}
            </div>
          </div>
          <div className={classes.activeModelInfoHolderImg}>
            <img src={activeGallery} width="100%" />
          </div>
        </div>
      </div>
      <div className={classes.vCardHeadingOutterHolderMain}  >
        <div className={classes.vCardHeadingOutterHolder}>
          <h1 className={classes.vCardHeadingOutterC}>
            Pre-Owned Vehicles
          </h1>
        </div>
        <div className={classes.vCardHolder}>
          <div className={classes.vCardHolderInner}>
            {vehiclesList?.map((v) => {
              return (
                <div className={classes.vCard} onClick={() => {
                  handleRedirectUsed(v);
                }}>
                  <div>
                    <img src={v.image} width="100%" />
                  </div>
                  <div className={classes.vCardHeadingUsed}>
                    <h1 className={classes.vCardHeadingUsedAccent}>  {v.brand}</h1>
                    <div className={classes.vCardHeadingUsedAccentModel}>{v.model}</div>
                  </div>
                  <div className={classes.vCardFlexHolder}>
                    <div className={classes.vCardFlexItem} >
                      {v.year}
                    </div>
                    <div className={classes.vCardFlexItem} >
                      {v.mileage}
                    </div>
                    <div className={classes.vCardFlexItem} >
                      {v.newUsed}
                    </div>
                  </div>
                  <div className={classes.vCardFlexUsed}>
                    <h1 className={classes.vCardPriceUsed}>
                      R {v.price}
                    </h1>
                    <h1 className={classes.vCardPriceBottom}>
                      R {(v.price / 60).toFixed(0)} | 10% deposit | 60 months | 11% interest
                    </h1>
                  </div>
                </div>
              )
            })}
          </div>
          <div className={classes.vCardButtonNavHolderB}>
            <Link
              to="/used-vehicles"
            >
              <button className={classes.vCardButtonOutline}>VIEW ALL</button>
            </Link>
            <div className={classes.vCardButtonNavFlex}>
              <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleBack() }}><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
              <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleNextUsed() }}><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={classes.vCardHeadingOutterHolderU}>
          <h1 className={classes.vCardHeadingOutterC}>
            Owners Area
          </h1>
          <div className={classes.OwnersArea}>
            <div className={classes.OwnersAreaImg}>
              <div className={classes.OwnersAreaGroup}>
                <Link
                  to="/service"
                >
                  <img src={LeftImgTop} width="100%" />
                  <h1 className={classes.OwnersAreaHeading}>
                    {"Service ❯"}
                  </h1>
                </Link>
              </div>
              <div className={classes.OwnersAreaGroup}>
                <Link
                  to="/parts"

                >
                  <img src={LeftImgBot} width="100%" />
                  <h1 className={classes.OwnersAreaHeading}>
                    {"Genuine Parts ❯"}
                  </h1>
                </Link>
              </div>
            </div>
            <div className={classes.OwnersAreaImg}>
              <Link
                to="/contact-us"
              >
                <img src={RightImg} width="100%" />
                <h1 className={classes.OwnersAreaHeading}>
                  {"Contact Us ❯"}
                </h1>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeComponentGwm;


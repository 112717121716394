import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { DealerContext } from '../shared/contexts/DealerContext'

const useStyles = makeStyles(() => ({
  menuHodlerOuter: {
    width: "100%",
    position: 'sticky',
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    top: "0px",
    zIndex: "55",
    background: '#212121'
  },
  menu: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 768px)": {
      padding: "10px",
    },
  },
  menuHodler: {
    display: "flex",
    "@media (max-width: 1000px)": {
      display: "none",
    },
  },
  Link: {
    padding: "19px 15px",
    color: "white",
    fontSize: "14px",
    "&:hover": {
      color: "white",
      cursor: "pointer",
    },
  },
  LinkM: {
    padding: "19px 15px",
    color: "white",
    "&:hover": {
      color: "white",
      cursor: "pointer",
    },
  },
  BurgerHolder: {
    position: "relative",
    display: "none",
    "@media (max-width: 1000px)": {
      display: "block",
      position: "relative",
    },
  },
  Burger: {
    display: "none",
    "@media (max-width: 1000px)": {
      display: "block",
    },
  },
  pattie: {
    minWidth: "20px",
    minHeight: "2px",
    margin: "4px",
    background: "white",
  },
  img: {
    width: "190px",
    "@media (max-width: 768px)": {
      width: "180px",
    },
    "@media (min-width: 769px) and (max-width: 1200px)": {
      width: "200px",
    },
  },
  dropDown: {
    position: "fixed",
    width: "258px",
    right: "0px",
    top: 0,
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    padding: "10px",
    paddingTop: "25%",
    overflow: 'auto',
    height: '100vh',
    zIndex: '3'
  },
  AfterSalesDrop: {
    position: 'absolute',
    background: '#ce0100'
  },
  AfterSalesDropM: {
    background: '#505050'
  },
  AfterSalesDropLink: {
    padding: "19px 30px",
    color: "white",
    fontSize: "14px",
    "&:hover": {
      color: "white",
      cursor: "pointer",
    },
  },
}));

const TopBarChery = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenuAfterSales, setOpenMenuAfterSales] = useState(false);
  let { websiteColors } = useContext(DealerContext);
  const [Logo, setLogo] = useState('');

  const classes = useStyles({ websiteColors });

  useEffect(() => {
    setLogo(websiteColors.logo)
  }, [websiteColors]);

  const handleMenu = () => {
    if (openMenu) {
      setOpenMenu(false);
    } else {
      setOpenMenu(true);
    }
  };

  const handleMenuAfterSales = () => {
    if (openMenuAfterSales) {
      setOpenMenuAfterSales(false);
    } else {
      setOpenMenuAfterSales(true);
    }
  };

  const aLinks = [
    {
      link: '/show-room',
      title: 'New Cars'
    },
    {
      link: '/used-vehicles',
      title: 'Used Cars'
    },
    {
      link: '/special-deals',
      title: 'Special Deals'
    }
  ];

  const aDropdownLinks = [
    {
      link: '/parts',
      title: 'Parts'
    },
    {
      link: '/service',
      title: 'Service'
    },
    {
      link: '/accessories',
      title: 'Accessories'
    }
  ];

  const aLinks2 = [
    {
      link: '/apply-for-finance',
      title: 'Apply Online'
    },
    {
      link: '/sell-your-vehicle',
      title: 'Sell My Car'
    },
    {
      link: '/contact-us',
      title: 'Contact Us'
    },
    {
      link: '/about',
      title: 'About Us'
    }
  ];

  const scrollToTopButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  };

  return (
    <>
      <Box className={classes.menuHodlerOuter}>
        <Box className={classes.menu}>
          <div className={classes.img}>
            <Link to="/" onClick={(event) => { scrollToTopButtonClick(event) }}>
              <img src={Logo} width="100%"></img>
            </Link>
          </div>
          <div className={classes.BurgerHolder}>
            <div
              className={classes.Burger}
              onClick={() => {
                handleMenu();
              }}
            >
              <div className={classes.pattie}></div>
              <div className={classes.pattie}></div>
              <div className={classes.pattie}></div>
            </div>
          </div>

          <Box className={classes.menuHodler}>
            {aLinks.map((l) => (
              <Link to={l.link} onClick={(event) => { scrollToTopButtonClick(event) }}>
                <Typography className={classes.Link}>{l.title}</Typography>
              </Link>
            ))}
            <div
              onClick={() => {
                handleMenuAfterSales();
              }}
            >
              <Typography className={classes.Link}>
                After Sales
              </Typography>
              {openMenuAfterSales ?
                <div className={classes.AfterSalesDrop}>
                  {aDropdownLinks.map((l) => (
                    <Link to={l.link} onClick={(event) => { scrollToTopButtonClick(event) }}>
                      <Typography className={classes.AfterSalesDropLink}>{l.title}</Typography>
                    </Link>
                  ))}
                </div>
                : ""}
            </div>
            {aLinks2.map((l) => (
              <Link to={l.link} onClick={(event) => { scrollToTopButtonClick(event) }}>
                <Typography className={classes.Link}>{l.title}</Typography>
              </Link>
            ))}
          </Box>
        </Box>
      </Box>
      {openMenu && (
        <div className={classes.dropDown}>
          <Link to="/">
            <Typography
              className={classes.LinkM}
              onClick={() => {
                handleMenu();
                scrollToTopButtonClick();
              }}
            >
              Home
            </Typography>
          </Link>
          {aLinks.map((l) => (
            <Link
              to={l.link}
              onClick={() => {
                handleMenu();
                scrollToTopButtonClick();
              }}
            >
              <Typography className={classes.LinkM}>{l.title}</Typography>
            </Link>
          ))}
          <div
            onClick={() => {
              handleMenuAfterSales();
            }}
          >
            <Typography className={classes.LinkM}>
              After Sales
            </Typography>
            {openMenuAfterSales ?
              <div className={classes.AfterSalesDropM}>

                {aDropdownLinks.map((l) => (
                  <Link
                    to={l.link}
                    onClick={() => {
                      handleMenu();
                      scrollToTopButtonClick();
                    }}>
                    <Typography className={classes.AfterSalesDropLink}>{l.title}</Typography>
                  </Link>
                ))}
              </div>
              : ""}
          </div>
          {aLinks2.map((l) => (
            <Link
              to={l.link}
              onClick={() => {
                handleMenu();
                scrollToTopButtonClick();
              }}
            >
              <Typography className={classes.LinkM}>{l.title}</Typography>
            </Link>
          ))}
        </div>
      )}
    </>
  );
};

export default TopBarChery;

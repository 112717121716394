import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { Link, useHistory } from "react-router-dom";
import FacebookIcon from '@mui/icons-material/Facebook';
import { BASE_URI } from '../shared/Constants';
import axios from 'axios';
import { DealerContext } from '../shared/contexts/DealerContext';
import { VehicleContext } from '../shared/contexts/VehicleContext';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: 'center',
    margin: '0px 0px',
    flexDirection: 'column',
    marginBottom: '0px',
    padding: '40px 100px',
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    '@media (min-width:0px) and (max-width:768px)': {
      padding: '20px 15px'
    }
  },
  innerHolder: {
    width: "100%",
    justifyContent: 'space-evenly',
    display: "flex",
    flexDirection: 'row',
    '@media (min-width:0px) and (max-width:768px)': {
      flexDirection: 'column'
    }
  },
  section: {
    width: 'calc(100%/3)',
    '@media (min-width:0px) and (max-width:768px)': {
      width: "100%",
      padding: '0px 15px'
    }
  },
  GroupedSection: {
    display: 'flex',
    width: 'calc(100%/3)',
    flexDirection: 'column',
    '@media (min-width:0px) and (max-width:768px)': {
      width: "100%",
      padding: '0px 15px'
    }
  },
  heading: {
    color: 'white',
    marginTop: '21px',
    marginBottom: '30px',
    fontSize: '16px',
    textTransform: 'uppercase'
  },
  headingText: {
    fontSize: '16px!important',
    margin: '10px 0px',
    color: 'white',
    cursor: 'pointer'
  },
  img: {
    display: 'block',
    margin: '0px auto'
  },
  socialLinkHolder: {
    display: 'flex'
  },
  socialLink: {
    width: '50px!important',
    color: 'black!important',
    cursor: 'pointer',
    '@media (min-width:769px) and (max-width:1024px)': {
      width: '50px!important',
    }
  },
  socialLinkIcon: {
    width: '80%!important',
    height: '80%!important',
  },
  LogoHolder: {
    display: 'block'
  },
  LegalSection: {
    borderTop: '2px solid #df0534',
    paddingTop: '20px',
    marginTop: '20px',
    justifyContent: 'space-between',
    color: 'white',
    '@media (min-width:0px) and (max-width:768px)': {
      flexDirection: 'column-reverse'
    }
  },
  LegalSectionFootNote: {
    paddingTop: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    color: 'white',
    '@media (min-width:0px) and (max-width:768px)': {
      flexDirection: 'column-reverse'
    }
  },
  LegalSectionG: {
    display: 'flex',
    color: 'white',
  }
}))

const FooterMahindraRise = () => {
  const { globalDealer, websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });
  const [Logo, setLogo] = useState('');
  const { newVehiclesList } = useContext(VehicleContext);
  const history = useHistory();
  useEffect(() => {
    axios({
      method: 'GET',
      url: `${BASE_URI}/DealerWebsites/${globalDealer.id} `
    }).then((response) => {
      setLogo(response.data.image)
    });
  }, [globalDealer]);

  const handleRedirect = (data) => {
    history.push(`/show-room/${data?.make}/${data?.ownedModelId}`, data)
  };

  return (
    <div className={classes.root}>
      <div className={classes.innerHolder}>
        <div className={classes.section}>
          <Typography variant='h1' className={classes.heading} >
            <strong>Links</strong>
          </Typography>
          <Link to="/orchard-series">
            <Typography variant='h1' className={classes.headingText} >
              Orchard Series
            </Typography>
          </Link>
          <Link to="/field-tractors">
            <Typography variant='h1' className={classes.headingText} >
              Field Tractors
            </Typography>
          </Link>
          <Link to="/about">
            <Typography variant='h1' className={classes.headingText} >
              About
            </Typography>
          </Link>
          <Link
            onClick={() => {
              window.location.href = "/dealer/ben-morgenrood-mahindra---randfontein"
            }}
          >
            <Typography variant='h1' className={classes.headingText} >
              Mahindra SA
            </Typography>
          </Link>
          <Link to="/special-deals">
            <Typography variant='h1' className={classes.headingText} >
              Promotions
            </Typography>
          </Link>
          <Link to="/contact-us">
            <Typography variant='h1' className={classes.headingText} >
              Contact Us
            </Typography>
          </Link>
        </div>
        <div className={classes.section}>
          <Typography variant='h1' className={classes.heading} >
            <strong>Tractors</strong>
          </Typography>
          {newVehiclesList.map((v) => {
            return (
              <Typography
                variant='h1'
                className={classes.headingText}
                onClick={() => {
                  handleRedirect(v)
                }}
              >
                {v.title}
              </Typography>
            )
          })}
        </div>
        <div className={classes.GroupedSection}>
          <div>
            <Typography variant='h1' className={classes.heading} >
              <strong>Corporate</strong>
            </Typography>
            <Link to="/about">
              <Typography variant='h1' className={classes.headingText} >
                About Us
              </Typography>
            </Link>
          </div>
        </div>
        <div className={classes.section}>
          <Typography variant='h1' className={classes.heading} >
            <strong>Social Media</strong>
          </Typography>
          <div className={classes.socialLinkHolder}>
            <div className={classes.socialLink}>
              <a href={globalDealer?.social?.facebook} target="_blank" style={{ color: 'white' }} rel="noreferrer">
                <FacebookIcon className={classes.socialLinkIcon} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.LegalSection}>
        <div className={classes.LegalSectionFootNote}>
          <div>
            Mahindra Agri {new Date().getFullYear()} Terms & Conditions
          </div>
          <div className={classes.LegalSectionG}>
            <Link
              to="/"
              className={classes.LogoHolder}
            >
              <img src={Logo} width="175px"></img>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterMahindraRise;
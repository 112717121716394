import React from 'react'
import DealsFilter from './DealsFilter';
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 0px'
  },
  background: {
    padding: '40px 15px'
  },
  cardHolder: {
    maxWidth: '1170px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: '0 auto',
  },
  card: {
    width: '100%',
    margin: '10px',
    maxWidth: 'calc(100% / 2 - 20px)',
    cursor: 'pointer',
    "@media (max-width: 425px)": {
      maxWidth: 'calc(100% / 1 - 20px)',
    },
    "@media (min-width: 769px) and (max-width: 1200px)": {
      maxWidth: 'calc(100% / 2 - 20px)',
    },
  },
}));

export default function SpecialDeals() {
  const classes = useStyles();
  const history = useHistory();
  const sortDirection = 'Desc';
  const pageNumber = 1;
  const { deals } = DealsFilter(pageNumber, sortDirection);

  const handlePageChange = (deal) => {
    history.push(`/special-deals/${deal?.make}/${deal?.offerId}`, deal)
  }

  return (
    <div className={classes.root}>
      <div className={classes.background}>
        <div className={classes.cardHolder} >
          {
            (deals.length > 0) &&
            deals.map((deal, index) =>
              <div key={index} className={classes.card} >
                <img onClick={() => { handlePageChange(deal) }} src={deal?.imageUrl} width="100%" />
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}


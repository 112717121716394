import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { BASE_URI } from "../shared/Constants";
import { DealerContext } from "../shared/contexts/DealerContext";
import { useHistory } from "react-router-dom";
import { ContactUs as Form } from '@red-build/leadcentre';
import Tracker1 from '../shared/assets/tracker1.png'
import Tracker2 from '../shared/assets/tracker2.png'
import CardImg from '../shared/assets/mBanner.png'
import useMediaQuery from "../shared/useMediaQuery";
import { Implements } from "../Implements/Implements";

const useStyles = makeStyles(() => ({
  root: {
    background: "#f3f3f3",
  },
  img: {
    height: "100%",
    maxHeight: "160px",
    margin: '10px 0px',
    transition: '0.5s ease all',
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.1)"
    },
  },
  list: {
    width: 'calc(100% / 2);',
    textAlign: "left"
  },
  MainImg: {
    filter: 'brightness(0.7)',
  },
  AboutTextHolder: {
    display: 'flex',
    justifyContent: 'right',
    position: 'relative',
  },
  AboutText: {
    width: '60%',
    textAlign: 'right',
    fontSize: '16px',
    '@media (max-width: 614px)': {
      width: 'unset'
    }
  },
  ContactSectionHolder: {
    backgroundImage: `url(${CardImg})`,
    backgroundSize: 'cover',
    backgroundColor: '#34343461',
    minHeight: '80vh',
    backgroundBlendMode: 'overlay',
    padding: '40px 50px',
    backgroundPosition: 'center',
    '@media (max-width: 959px)': {
      padding: '40px 10px',
      minHeight: '40vh',
    }
  },
  vCardHeadingOutterHolderFlexRise: {
    margin: '0px auto',
    marginTop: '80px',
    background: '#f3f3f3'
  },
  vCardHeadingOutterHolderFlexRiseInner: {
    width: '80%',
    margin: '0px auto',
    background: '#f3f3f3'
  },
  vCardHeadingOutterHolderRiseCardHolder: {
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      marginLeft: 'unset',
    },
  },
  vCardHeadingOutterHolderRiseCard: {
    border: '2px solid #df0534',
    width: 'calc(100% / 3)',
    background: 'white',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: "pointer",
    '@media (max-width: 768px)': {
      marginBottom: '40px',
      width: '100%',
    },
    '@media (min-width: 769px) and (max-width: 1000px)': {
      width: 'calc(100% / 2)',
    }
  },
  vCardHeadingOutterHolderRiseCardMenu: {
    background: '#df0534',
    width: 'calc(100% / 3)',
    padding: '4em',
    color: 'white',
    '@media (max-width: 768px)': {
      marginBottom: '0px',
      width: 'unset',
    },
    '@media (min-width: 769px) and (max-width: 1000px)': {
      width: 'calc(100% / 2)',
    }
  },
  vCardHeadingOutterHolderRiseCardMenuHeading: {
    fontSize: '4em',
    color: 'white',
    margin: '0px',
    marginBottom: '40px'
  },
  vCardHeadingOutterHolderRiseCardMenuLink: {
    fontSize: '30px',
    borderBottom: '2px solid white',
    color: 'white',
    margin: '0px'
  },
  vCardHeadingOutterHolderFlexRiseHeading: {
    color: '#df0534',
    fontSize: '40px',
    fontWeight: 'normal',
    textAlign: 'center',
    margin: '0px',
    padding: '40px 0px',
  },
  vCardHolder: {
    display: "flex",
    alignItems: "end",
    justifyContent: "space-between",
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'unset',
      width: 'unset',
    },
  },
  vCardHolderI: {
    overflow: "hidden",
    width: '80%',
    margin: '0 auto'
  },
  vCardHolderBtn: {
    color: "white",
    background: "#df0534",
    padding: '10px 40px',
    borderRadius: '50px',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
  },
  vCardHolderDivider: {
    height: "10px",
    width: '90%',
    margin: '0 auto',
    marginBottom: "20px",
    clipPath: 'polygon(5% 1%, 100% 0%, 95% 100%, 0% 100%)',
    background: '#df0534',
    '@media (max-width: 768px)': {
      width: 'unset',
    },
  },
  vCardHolderInner: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    transition: 'all 0.5s ease',
    transform: ({ slidePosition }) => (!slidePosition == 0 ? `translateX(${slidePosition}%)` : `translateX(0%)`),
    "@media (max-width: 600px)": {
      transform: ({ slidePosition }) => (!slidePosition <= 0 ? `translateX(${slidePosition * 3}%)` : `translateX(0%)`),
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      transform: ({ slidePosition }) => (!slidePosition <= 0 ? `translateX(${slidePosition * 3}%)` : `translateX(0%)`),
    },
  },
  vCard: {
    minWidth: "calc(100% / 3 - 80px)",
    margin: '0px 40px',
    background: 'white',
    borderRadius: '20px',
    cursor: "pointer",
    padding: '20px',
    "@media (max-width: 600px)": {
      minWidth: "calc(100% / 1 - 20px)",
      margin: '0px 10px',
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      minWidth: "calc(100% / 2 - 20px)",
      margin: '0px 10px',
    },
  },
  vCardButton: {
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    padding: "10px 40px",
    borderRadius: '50px',
    border: 'none',
    margin: "10px 0px",
    color: 'white',
    cursor: 'pointer'
  },
  vCardButtonOutline: {
    border: ({ websiteColors }) => `2px soild ${websiteColors.primaryColor}`,
    padding: "10px 40px",
    borderRadius: '50px',
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    margin: "10px 0px",
    cursor: 'pointer'
  },
  vCardButtonNav: {
    width: '50px',
    border: ({ websiteColors }) => `2px soild ${websiteColors.primaryColor}`,
    padding: "10px 10px",
    borderRadius: '50px',
    margin: "10px 0px",
    fill: ({ websiteColors }) => `${websiteColors.accentColor}`,
    cursor: 'pointer'
  },
  vCardButtonNavFlex: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  vCardButtonNavHolderI: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '40px 0px',
  },
  slideHolder: {
    position: 'relative',
    '@media (max-width: 425px)': {
      marginTop: '0px',
    },
  },
  vCardHeading: {
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    fontSize: '30px',
  },
  vCardFlex: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  vCardButton: {
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    padding: "10px 40px",
    borderRadius: '50px',
    border: 'none',
    margin: "10px 0px",
    color: 'white',
    cursor: 'pointer'
  },
  vCardButtonNavFlexMain: {
    display: 'flex',
    width: '80px',
    bottom: '0px',
    right: '0px',
    position: 'absolute'
  },
  vCardButtonNavMain: {
    padding: "10px 10px",
    borderRadius: '50px',
    margin: "10px 0px",
    fill: ({ websiteColors }) => `${websiteColors.accentColor}`,
    cursor: 'pointer'
  },
  AboutTextHolder: {
    width: "80%",
    margin: '0 auto'
  },
  AboutText: {
    fontSize: '24px',
    textAlign: 'center',
    padding: '40px 0px',
  },
  vCardHeadingAccent: {
    color: '#df0534',
    margin: '0px',
    fontSize: '33px',
  },
  popUpHolder: {
    width: '100%',
    height: "100%",
    background: '#00000045',
    position: 'fixed',
    zIndex: '4',
    top: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  popUpHolderInner: {
    maxWidth: '20%',
    height: "50%",
    background: 'white',
    borderRadius: '5px',
    padding: "15px",
    overflow: 'auto',
    '@media (max-width: 768px)': {
      maxWidth: '90%',
    },
    '@media (min-width: 769px) and (max-width: 1024px)': {
      maxWidth: '70%',
    },
  },
  popUpHolderInnerClose: {
    width: '25px',
    height: "25px",
    background: 'red',
    color: "white",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: "50%",
    cursor: 'pointer'
  },
  popUpHolderInnerImage: {
    margin: '0 auto',
    display: 'block'
  }
}));

const HomeComponentMahindraRise = () => {
  const history = useHistory();
  const { globalDealer, websiteColors, dealerList } = useContext(DealerContext)
  const [slides, setSlides] = useState();
  const mountedRef = useRef(true);
  const [slidePosition, setSlidePosition] = useState(0)
  const [slideIndex, setSlideIndex] = useState(0)
  const classes = useStyles({ websiteColors, slidePosition });
  const [slidesLoading, setSlidesLoading] = useState(true);
  const [popUp, setPopUp] = useState(false);
  const [contactpopUp, setContactPopUp] = useState(false);
  const [popUpData, setPopUpData] = useState([]);
  const [slideIndexMain, setSlideIndexMain] = useState(0);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 1333px)');
  const isDesktop = useMediaQuery('(min-width: 1334px)');

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getSlides = async () => {
      try {
        const params = {
          pageNumber: 0,
          pageSize: 100,
          status: "active",
          dealerId: globalDealer.id
        };

        const result = await axios({
          method: "GET",
          url: `${BASE_URI}/slide`,
          params,
          cancelToken: source.token,
        });

        let tempSlides = result.data.list.map((itm) => {
          return {
            img: itm.slideImageUrl,
            isVideo: itm.isVideo,
            targetUrl: itm.url
          };
        });
        setSlides(tempSlides);
        setSlidesLoading(false)
      } catch (error) {
        console.warn('Failed fetching slides', error);
      }
    };

    getSlides().then(() => {
      if (!mountedRef.current) return null;
    });

    return () => {
      mountedRef.current = false;
      source.cancel();
    };
  }, []);

  const handleRPopUp = (data, type) => {
    if (type == "info") {
      setPopUp(true)
      setPopUpData(data)
    }

    if (type == "contact") {
      setContactPopUp(true)
      setPopUpData(data)
    }
  };

  const handleRedirectOrchad = (path) => {
    history.push(`${path}`)
  };

  const handleBack = () => {
    if (slidePosition < 0) {
      if (isDesktop) {
        setSlidePosition(slidePosition + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }
      if (isMobile) {
        setSlidePosition(slidePosition + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }
      if (isTablet) {
        setSlidePosition(slidePosition + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }
    }
  }

  const handleNext = () => {
    if (isDesktop) {
      if (slideIndex < Implements.length - 3) {
        setSlidePosition(slidePosition - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === Implements.length - 3) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }

    if (isMobile) {
      if (slideIndex < Implements.length - 1) {
        setSlidePosition(slidePosition - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === Implements.length - 1) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }

    if (isTablet) {
      if (slideIndex < Implements.length - 3) {
        setSlidePosition(slidePosition - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === Implements.length - 3) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }
  }

  const handleNextSlide = () => {
    if (slideIndexMain < slides.length - 1) {
      setSlideIndexMain(slideIndexMain + 1)
    }
    if (slideIndexMain === slides.length - 1) {
      setSlideIndexMain(0)
    }
  };

  const handleBackSlide = () => {
    if (slideIndexMain > 0) {
      setSlideIndexMain(slideIndexMain - 1)
    }
  };

  return (
    <div className={classes.root}>
      {popUp &&
        <div className={classes.popUpHolder}>
          <div className={classes.popUpHolderInner}>
            <div className={classes.popUpHolderInnerClose} onClick={() => { setPopUp(false) }}>X</div>
            <img src={popUpData.image} width='80%' className={classes.popUpHolderInnerImage} />
            <ul>
              {popUpData.desc1 !== '' && <li>{popUpData.desc1} </li>}
              {popUpData.desc2 !== '' && <li>{popUpData.desc2}</li>}
              {popUpData.desc3 !== '' && <li>{popUpData.desc3}</li>}
              {popUpData.desc4 !== '' && <li>{popUpData.desc4}</li>}
              {popUpData.desc5 !== '' && <li>{popUpData.desc5}</li>}
            </ul>
          </div>
        </div>
      }
      {contactpopUp &&
        <div className={classes.popUpHolder}>
          <div className={classes.popUpHolderInner}>
            <div className={classes.popUpHolderInnerClose} onClick={() => { setContactPopUp(false) }}>X</div>
            <Form
              dealerId={globalDealer?.id}
              base_uri={BASE_URI}
              leadTypeId={5}
              activeDealerId={globalDealer?.id}
              dealerName={globalDealer?.name?.replace("LSG", " ")?.replace("Master", " ")}
              dealers={dealerList}
              dealerType="multi_dealer"
              note={true} />
          </div>
        </div>
      }
      <div className={classes.slideHolder}>
        {!slidesLoading &&
          <img src={slides[slideIndexMain]?.img} width="100%" className={classes.MainImg} />
        }
        <div className={classes.vCardButtonNavFlexMain}>
          <svg className={classes.vCardButtonNavMain} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleBackSlide() }}><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
          <svg className={classes.vCardButtonNavMain} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleNextSlide() }}><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
        </div>
      </div>
      <div>
        <div className={classes.vCardHeadingOutterHolderFlexRise}>
          <div className={classes.vCardHeadingOutterHolderFlexRiseInner}>
            < h1 className={classes.vCardHeadingOutterHolderFlexRiseHeading}>
              OUR FARM EQUIPMENT RANGE
            </h1>
            <div className={classes.vCardHeadingOutterHolderRiseCardHolder}>
              <div className={classes.vCardHeadingOutterHolderRiseCard} onClick={() => { handleRedirectOrchad('/orchard-series') }}>
                <img src={Tracker1} width="90%" />
                <div className={classes.vCardHolder}>
                  <div>
                    <h1 className={classes.vCardHeadingAccent}>ORCHARD</h1>
                    <h1 className={classes.vCardHeadingAccent}>TRACTORS</h1>
                  </div>
                  <div>
                    <div className={classes.vCardHolderDivider}></div>
                    <button className={classes.vCardHolderBtn} >
                      <strong>Explore</strong>  Series
                    </button>
                  </div>
                </div>
              </div>
              <div className={classes.vCardHeadingOutterHolderRiseCard} onClick={() => { handleRedirectOrchad('/field-tractors') }}>
                <img src={Tracker2} width="90%" />
                <div className={classes.vCardHolder}>
                  <div>
                    <h1 className={classes.vCardHeadingAccent}>FIELD</h1>
                    <h1 className={classes.vCardHeadingAccent}>TRACTORS</h1>
                  </div>
                  <div>
                    <div className={classes.vCardHolderDivider}></div>
                    <button className={classes.vCardHolderBtn} >
                      <strong>Explore</strong>  Series
                    </button>
                  </div>
                </div>
              </div>
              <div className={classes.vCardHeadingOutterHolderRiseCardMenu}>
                <h1 className={classes.vCardHeadingOutterHolderRiseCardMenuHeading}>
                  QUICK LINKS
                </h1>
                <Link
                  to="/about"
                >
                  <h1 className={classes.vCardHeadingOutterHolderRiseCardMenuLink}>
                    About Us
                  </h1>
                </Link>
                <Link
                  to="/contact-us"
                >
                  <h1 className={classes.vCardHeadingOutterHolderRiseCardMenuLink}>
                    Contact Us
                  </h1>
                </Link>
                <Link
                  to="/special-deals"
                >
                  <h1 className={classes.vCardHeadingOutterHolderRiseCardMenuLink}>
                    Promotions
                  </h1>
                </Link>
              </div>
            </div>
          </div>
          < h1 className={classes.vCardHeadingOutterHolderFlexRiseHeading}>
            OUR RANGE OF IMPLEMENTS
          </h1>
          <div className={classes.vCardHolderI}>
            <div className={classes.vCardHolderInner}>
              {Implements.map((v) => {
                return (
                  <div className={classes.vCard}     >
                    <div>
                      <img src={v.image} width="100%" />
                    </div>
                    <div className={classes.vCardHeading}>
                      {v.title}
                    </div>
                    <div className={classes.vCardFlex}>
                      <button className={classes.vCardButton} onClick={() => {
                        handleRPopUp(v, "info");
                      }}>LEARN MORE</button>

                      <button className={classes.vCardButton} onClick={() => {
                        handleRPopUp(v, "contact");
                      }}>ENQUIRE</button>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className={classes.vCardButtonNavHolderI}>
              <div className={classes.vCardButtonNavFlex}>
                <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleBack() }}><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
                <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleNext() }}><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
              </div>
            </div>
          </div>
        </div>
        < h1 className={classes.vCardHeadingOutterHolderFlexRiseHeading}>
          ABOUT MAHINDRA SA
        </h1>
        <div className={classes.ContactSectionHolder}>
        </div>
        <div className={classes.AboutTextHolder}>
          < p className={classes.AboutText}>
            Mahindra SA was established in 1996, bringing both our global name and expertise to South Africa. Since then,

            we have become one of the country’s fastest-growing passenger vehicle and bakkie brands. Our success also

            led to the opening of our first local assembly plant in 2018. We strive to continue to make our Mahindra mark,

            offering the people of South Africa world-class products. ‘Rise’ has not only been Mahindra’s message but has

            also become an underpinned philosophy of our vehicle behaviour. We are a vehicle brand that redefines

            possibilities, continues to build and improvise, seeking new and innovative ways to connect with consumers,

            but most of all, we Rise to the challenge. Inspired by the spirit to continue to Rise and be up for any challenge,

            our goal is to positively impact our partners, stakeholders, and communities
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeComponentMahindraRise;


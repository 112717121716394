import React from 'react'
import { makeStyles } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import Parts from "../shared/assets/aftersales_parts.ab1ac801.jpeg"
import Service from "../shared/assets/aftersales_service.5e135bbc.jpg"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '100px 40px'
  },
  background: {
  },
  cardImage: {
    borderRadius: '10px'
  },
  cardText: {
    fontSize: '24px',
    margin: '0px',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  cardHolder: {
    maxWidth: '1170px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: '0 auto',
  },
  card: {
    width: '100%',
    margin: '10px',
    maxWidth: 'calc(100% / 2 - 20px)',
    cursor: 'pointer',
    "@media (max-width: 425px)": {
      maxWidth: 'calc(100% / 1 - 20px)',
    },
    "@media (min-width: 769px) and (max-width: 1200px)": {
      maxWidth: 'calc(100% / 2 - 20px)',
    },
  },
}));

const AfterSales = () => {
  const classes = useStyles();
  const history = useHistory();
  const handlePageChange = (link) => {
    history.push(`${link}`)
  }

  return (
    <div className={classes.root}>
      <div className={classes.background}>
        <div className={classes.cardHolder} >
          <div className={classes.card} >
            <img onClick={() => { handlePageChange("parts") }} src={Parts} width="100%" className={classes.cardImage} />
            <p className={classes.cardText}>Need Parts?</p>
          </div>
          <div className={classes.card} >
            <img onClick={() => { handlePageChange("service") }} src={Service} width="100%" className={classes.cardImage} />
            <p className={classes.cardText}>Want To Book A Service?</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AfterSales
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { DEALERID, BASE_URI } from "../../shared/Constants";
import { ContactUs as Form } from "@red-build/leadcentre";
import { DealerContext } from "../contexts/DealerContext";

const useStyles = makeStyles(() => ({
  root: {
    color: "black",
    display: "flex",
    width: "100%",
    margin: "0 auto",
    justifyContent: ({ align }) => `${align ? align : "end"}`,
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  section: {
    width: "100%",
    maxWidth: "calc(100%/3)",
    "@media (max-width: 768px)": {
      width: "calc(100%/1)",
      padding: "40px 10px",
      lineHeight: "unset",
      maxWidth: 'unset',
    },
    "@media (min-width: 769px) and (max-width: 1200px)": {
      width: "calc(100%/2)",
    },
    "@media (min-width: 1900px) ": {
      lineHeight: '4'
    },
  },
  section: {
    width: "100%",
    maxWidth: "calc(100%/3)",
    "@media (max-width: 768px)": {
      width: "calc(100%/1)",
      padding: "40px 10px",
      lineHeight: "unset",
      maxWidth: 'unset',
    },
    "@media (min-width: 769px) and (max-width: 1200px)": {
      width: "calc(100%/2)",
    },
    "@media (min-width: 1900px) ": {
      lineHeight: '4'
    },
  },
  section2: {
    width: "100%",
    maxWidth: "calc(100%/3)",
    "@media (max-width: 768px)": {
      width: "calc(100%/1)",
      padding: "40px 10px",
      lineHeight: "unset",
      maxWidth: 'unset',
      margin: "unset",
    },
    "@media (min-width: 769px) and (max-width: 1200px)": {
      width: "calc(100%/2)",
    },
    "@media (min-width: 1900px) ": {
      lineHeight: '4'
    },
  },
  headingForm: {
    padding: "15px 20px",
    background: "#2c88d9",
    color: "white",
  },
}));

const ContactSection = ({ align }) => {
  const { dealerList, websiteColors } = useContext(DealerContext)
  const classes = useStyles({ ...websiteColors, align });

  return (
    <div className={classes.root}>
      <div className={classes.section2}>
        <Form
          dealerId={DEALERID}
          base_uri={BASE_URI}
          leadTypeId={3}
          dealers={dealerList}
          dealerName={"Bma Group"}
        />
      </div>
    </div>
  );
};

export default ContactSection;

import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { Link } from "react-router-dom";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { BASE_URI } from '../shared/Constants';
import axios from 'axios';
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: 'center',
    margin: '0px 0px',
    borderTop: '8px #25a0da solid',
    flexDirection: 'column',
    marginBottom: '0px',
    padding: '40px 100px',
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    '@media (min-width:0px) and (max-width:768px)': {
      padding: '20px 15px'
    }
  },
  innerHolder: {
    width: "100%",
    justifyContent: 'space-evenly',
    display: "flex",
    flexDirection: 'row',
    '@media (min-width:0px) and (max-width:768px)': {
      flexDirection: 'column'
    }
  },
  section: {
    width: 'calc(100%/3)',
    '@media (min-width:0px) and (max-width:768px)': {
      width: "100%",
      padding: '0px 15px'
    }
  },
  GroupedSection: {
    display: 'flex',
    width: 'calc(100%/3)',
    flexDirection: 'column',
    '@media (min-width:0px) and (max-width:768px)': {
      width: "100%",
      padding: '0px 15px'
    }
  },
  heading: {
    color: 'white',
    marginTop: '21px',
    marginBottom: '10px',
    fontSize: '24px',
  },
  headingText: {
    fontSize: '16px!important',
    margin: '10px 0px',
    color: 'white',
  },
  img: {
    display: 'block',
    margin: '0px auto'
  },
  legal: {
    fontSize: '8pt'
  },
  socialLinkHolder: {
    display: 'flex'
  },
  socialLink: {
    width: 'calc(100%/11)!important',
    color: 'black!important',
    '@media (min-width:769px) and (max-width:1024px)': {
      width: 'calc(100%/6)!important',
    }
  },
  socialLinkIcon: {
    width: '80%!important',
    height: '80%!important',
  },
  LogoHolder: {
    marginTop: '50px',
    display: 'block'
  }
}))

const FooterFord = () => {
  const { globalDealer, websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });
  const [Logo, setLogo] = useState('');

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${BASE_URI}/DealerWebsites/${globalDealer.id} `
    }).then((response) => {
      setLogo(response.data.image)
    });

  }, [globalDealer]);

  return (
    <div className={classes.root}>
      <div className={classes.innerHolder}>
        <div className={classes.section}>
          <Typography variant='h1' className={classes.heading} >
            <strong>Legal</strong>
          </Typography>
          <Link to="/requestergws">
            <Typography variant='h1' className={classes.headingText} >
              popia
            </Typography>
          </Link>
          <Link to="/terms">
            <Typography variant='h1' className={classes.headingText} >
              terms & conditions
            </Typography>
          </Link>
          <Link to="/cookies">
            <Typography variant='h1' className={classes.headingText} >
              cookie policy
            </Typography>
          </Link>
        </div>
        <div className={classes.section}>
          <Typography variant='h1' className={classes.heading} >
            <strong>Tools</strong>
          </Typography>
          <Link to="/show-room">
            <Typography variant='h1' className={classes.headingText}>
              new vehicles
            </Typography>
          </Link>
          <Link to="/used-vehicles">
            <Typography variant='h1' className={classes.headingText} >
              search pre-owned vehicles
            </Typography>
          </Link>
          <Link to="/special-deals">
            <Typography variant='h1' className={classes.headingText} >
              our special deals
            </Typography>
          </Link>
        </div>
        <div className={classes.GroupedSection}>
          <div>
            <Typography variant='h1' className={classes.heading} >
              <strong>Financial Services  </strong>
            </Typography>
            <Link to="/apply-for-finance">
              <Typography variant='h1' className={classes.headingText} >
                apply for finance
              </Typography>
            </Link>
          </div>
        </div>
        <div className={classes.section}>
          <Typography variant='h1' className={classes.heading}>
            <strong>Connect Socially  </strong>
          </Typography>
          <div className={classes.socialLinkHolder}>
            <div className={classes.socialLink}>
              <a href={globalDealer?.social?.facebook} target="_blank" style={{ color: 'white' }} rel="noreferrer">
                <FacebookIcon className={classes.socialLinkIcon} />
              </a>
            </div>
            <div className={classes.socialLink}>
              <a href={globalDealer?.social?.instagram} target="_blank" style={{ color: 'white' }} rel="noreferrer">
                <InstagramIcon className={classes.socialLinkIcon} />
              </a>
            </div>
          </div>
          <Link
            to="/"
            className={classes.LogoHolder}
          >
            <img src={Logo} width="175px"></img>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default FooterFord
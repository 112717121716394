import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, MenuItem } from "@material-ui/core";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { DealerContext } from "../shared/contexts/DealerContext";
import { Formik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios"
import { BASE_URI } from "../shared/Constants";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import useMediaQuery from "../shared/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    transition: 'all 0.5s ease!important',
    backgroundPosition: "100% 80%",
    backgroundSize: "cover",
    alignItems: "center",
    display: "flex",
    height: "90vh",
    padding: '20px',
    backgroundColor: '#00000085',
    backgroundBlendMode: 'overlay',
  },
  rootInner: {
    width: "100%",
    maxWidth: "570px",
    marginLeft: "50px",
    background:
      "#0c0c0c",
    borderRadius: "1rem!important",
    boxShadow: "0 0 15px #0000001c",
    "@media (max-width: 959px)": {
      margin: "0px",
    },
  },
  modelHolder: {
    display: "flex",
    padding: '18px 0px',
    transform: (slidePosition) => (!slidePosition == 0 ? `translateX(${slidePosition}%)` : `translateX(0%)`),
    "@media (max-width: 600px)": {
      transform: (slidePosition) => (!slidePosition <= 0 ? `translateX(${slidePosition * 4}%)` : `translateX(0%)`),
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      transform: (slidePosition) => (!slidePosition <= 0 ? `translateX(${slidePosition * 2}%)` : `translateX(0%)`),
    },
  },
  model: {
    marginRight: "8px",
    cursor: "pointer",
    color: "white",
    textTransform: 'uppercase',
    margin: '0px 10px',
    minWidth: 'calc(100% / 4 - 20px)',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "@media (max-width: 600px)": {
      minWidth: "calc(100% / 1 - 20px)",
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      minWidth: "calc(100% / 2 - 20px)",
    },
  },
  modelActive: {
    marginRight: "8px",
    textTransform: 'uppercase',
    color: "#c7001f",
    margin: '0px 10px',
    minWidth: 'calc(100% / 4 - 20px)',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "@media (max-width: 600px)": {
      minWidth: "calc(100% / 1 - 20px)",
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      minWidth: "calc(100% / 2 - 20px)",
    },
  },
  rootInnerTitle: {
    fontSize: "32px",
    fontWeight: "bold",
    color: 'white'
  },
  rootInnerTitleAccent: {
    fontSize: "21px",
    fontWeight: "bold",
    color: "#c7001f",
    marginBottom: "16px",
  },
  inputGroup: {
    display: "flex",
    justifyContent: "space-between",
  },
  inputS: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
    width: "calc(100% / 2 - 10px)",
  },
  textHolder: {
    padding: "15px",
    borderRadius: "0rem 0rem 10px 10px!important",
  },
  formHolder: {
    background: "white",
    padding: "15px",
    borderRadius: "0rem 0rem 10px 10px!important",
  },
  Link: {
    border: "none",
    borderRadius: '50px',
    padding: "10px 40px",
    background: "#b81b22",
    color: "white",
    cursor: "pointer",
    margin: "15px 0 0",
    "&:hover": {
      background: "  #9b161c",
      boxShadow: "0 0 5px 0  #9b161c",
    },
  },
  cardHolderOutter: {
    position: 'relative',
    overflow: 'hidden',
    alignItems: 'center',
    margin: '0 auto',
  },
  cardBack: {
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 50,
    left: 10,
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  cardNext: {
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 50,
    right: 10,
    top: '50%',
    transform: 'translate(50%, -50%)',
  },
  icon: {
    fontSize: '50px',
    color: '#c7001f',
  },
}));

const BookATestDrive = () => {
  const history = useHistory()
  const paramss = useParams()
  const { newVehiclesList } = useContext(VehicleContext);
  const { dealerList } = useContext(DealerContext);
  const [activeModel, setActiveModel] = useState();
  const [vehiclesList, setVehiclesList] = useState(newVehiclesList);
  const [vehicleVariant, setVehicleVariant] = useState([]);
  const [vehicleVariantActive, setVehicleVariantActive] = useState([]);
  const [slidePosition, setSlidePosition] = useState(0)
  const [slideIndex, setSlideIndex] = useState(0)
  const classes = useStyles(slidePosition);

  useEffect(() => {
    history.push(`/book-a-testdrive/${newVehiclesList[0]?.ownedModelId}`)
    setVehiclesList(newVehiclesList);
  }, [newVehiclesList]);

  useEffect(() => {
    let params = {
      makeId: 8104,
      dealerModelId: paramss?.stockId,
    };

    axios({
      method: "GET",
      url: `${BASE_URI}/dealervariants`,
      params,
    })
      .then((response) => {
        setVehicleVariant(response.data.list)
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });

    axios({
      method: "GET",
      url: `${BASE_URI}/DealerModelInformation/GetForDealerModel/${paramss?.stockId}`,
    })
      .then((response) => {
        setVehicleVariantActive(response.data.contentImages)
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  }, [paramss])

  useEffect(() => {
    for (let index = 0; index < vehiclesList.length; index++) {
      const element = vehiclesList[index];
      if (paramss?.stockId == element?.ownedModelId) {
        element.active = true;
        setActiveModel(element)
      }
    }
  }, [vehiclesList])

  const handleModelChange = (v) => {
    history.push(`/book-a-testdrive/${v.ownedModelId}`)
    for (let index = 0; index < vehiclesList.length; index++) {
      const element = vehiclesList[index];
      element.active = false;
    }

    v.active = true;
    setActiveModel(v)
    setVehiclesList(vehiclesList);
  };

  const handleBack = () => {
    if (slidePosition !== 0) {
      setSlidePosition(slidePosition + 25)
      setSlideIndex(slideIndex - 1)
    }
  }

  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 1333px)');
  const isDesktop = useMediaQuery('(min-width: 1334px)');

  const handleNext = () => {
    if (isDesktop) {
      if (slideIndex < vehiclesList.length - 4) {
        setSlidePosition(slidePosition - 25)
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === vehiclesList.length - 4) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }

    if (isMobile) {
      if (slideIndex < vehiclesList.length - 1) {
        setSlidePosition(slidePosition - 25)
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === vehiclesList.length - 1) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }

    if (isTablet) {
      if (slideIndex < vehiclesList.length - 2) {
        setSlidePosition(slidePosition - 25)
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === vehiclesList.length - 2) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }
  }

  return (
    <div className={classes.root} style={{ backgroundImage: `url(${vehicleVariantActive[0]?.image?.imageUrl})` }}>
      <div className={classes.rootInner}>
        <div className={classes.textHolder}>
          <Typography className={classes.rootInnerTitle}>
            SELECT A MODEL
          </Typography>
          <Typography className={classes.rootInnerTitleAccent}>
            {activeModel?.title}
          </Typography>
          <div className={classes.cardHolderOutter}>
            <div className={classes.cardBack} onClick={() => { handleBack() }}><ChevronLeftIcon className={classes.icon} /></div>
            <div className={classes.cardNext} onClick={() => { handleNext() }}><ChevronRightIcon className={classes.icon} /></div>
            <div className={classes.modelHolder}>
              {vehiclesList?.map((vehicle, index) => {
                return (
                  <div
                    className={
                      vehicle.active == true ? classes.modelActive : classes.model
                    }
                    onClick={() => {
                      handleModelChange(vehicle);
                    }}
                  >
                    {vehicle?.title.replace("Mitsubishi", "").replace("MITSUBISHI", "")}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={classes.formHolder}>
          <Formik
            initialValues={{ email: "", password: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className={classes.inputGroup}>
                  <div className={classes.inputS}>
                    <TextField
                      id="name"
                      label="Name"
                      variant="outlined"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name && errors.name}
                  </div>
                  <div className={classes.inputS}>
                    <TextField
                      id="branch"
                      label="Branch"
                      variant="outlined"
                      fullWidth
                      required
                      select
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.branch}
                    >
                      {dealerList?.map((d) => {
                        return (
                          <MenuItem value={d?.dealerId}>{d?.name}</MenuItem>
                        );
                      })}
                    </TextField>
                    {errors.branch && touched.branch && errors.branch}
                  </div>
                </div>
                <div className={classes.inputGroup}>
                  <div className={classes.inputS}>
                    <TextField
                      id="email"
                      label="Email"
                      variant="outlined"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && touched.email && errors.email}
                  </div>
                  <div className={classes.inputS}>
                    <TextField
                      id="phone"
                      label="Phone"
                      required
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                    />
                    {errors.phone && touched.phone && errors.phone}
                  </div>
                </div>
                <div>
                  <TextField
                    id="variant"
                    label="Select a Variant"
                    variant="outlined"
                    fullWidth
                    required
                    select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.variant}
                  >
                    {vehicleVariant?.map((d) => {
                      return (
                        <MenuItem value={d?.title}>{d?.title}</MenuItem>
                      );
                    })}
                  </TextField>
                  {errors.variant && touched.variant && errors.variant}
                </div>
                <button
                  className={classes.Link}
                  type="submit"
                  disabled={isSubmitting}
                >
                  SUBMIT
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default BookATestDrive;

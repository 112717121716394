import React from "react";
import { Route } from 'react-router-dom';
import HomeComponentMahindra from './HomeComponent/HomeComponentMahindra';
import ScrollUpButton from "react-scroll-up-button";
import TopBarMahindra from './TopBar/TopBarMahindra';
import FooterMahindra from './Footer/FooterMahindra'
import Showroom from './Showroom/Showroom';
import SellVehicle from './SellVehicle/SellVehicle';
import DetailedVehicle from './DetailedVehicle/DetailedVehicle';
import VehicleFinance from './Finance/VehicleFinance';
import ContactUs from './ContactUs/ContactUs';
import SpecialDeals from './SpecialDeals/SpecialDeals';
import DetailedSpecialDeals from './DetailedSpecialDeals/DetailedSpecialDeals';
import AboutUs from "./AboutUs/AboutUsMahindraVehicles";
import UsedVehicles from './UsedVehicles/UsedVehicles'
import Popi from './Legal/Popia';
import Cookies from './Legal/Cookies';
import Terms from './Legal/Terms';
import DetailedUsedVehicle from './DetailedUsedVehicle/DetailedUsedVehicle';
import Service from './BookService/BookService';
import Parts from './Parts/Parts'
import BackToSite from "./shared/BackToSite";
import AfterSales from "./AfterSales/AfterSales";
import Accessories from './Accessories/Accessories'

export default function DashboardContainerMahindra() {
  return (
    <React.Fragment>
      <main>
        <BackToSite />
        <TopBarMahindra link1Text='HOME' link2Text='Vehicles in stock' link3Text='Sell' link4Text='Finance' link5Text='Contact Us' link6Text='Compare' link7Text='Favourites' />
        <Route exact path="/" render={(props) => <HomeComponentMahindra />} />
        <Route exact path="/show-room" render={(props) => <Showroom />} />
        <Route exact path="/after-sales" render={(props) => <AfterSales />} />
        <Route exact path="/used-vehicles" render={(props) => <UsedVehicles />} />
        <Route exact path="/about" render={(props) => <AboutUs />} />
        <Route path="/show-room/:model/:stockId" render={(props) => <DetailedVehicle />} />
        <Route path="/pre-owned/:model/:stockId" render={(props) => <   DetailedUsedVehicle />} />
        <Route path="/special-deals/:model/:stockId" render={(props) => <DetailedSpecialDeals />} />
        <Route exact path="/sell-your-vehicle" render={(props) => <SellVehicle />} />
        <Route exact path="/special-deals" render={(props) => <SpecialDeals />} />
        <Route exact path='/service' component={Service} />
        <Route exact path='/parts' component={Parts} />
        <Route exact path='/apply-for-finance' component={VehicleFinance} />
        <Route exact path='/contact-us' component={ContactUs} />
        <Route path='/requestergws' component={Popi} />
        <Route path='/cookies' component={Cookies} />
        <Route path='/terms' component={Terms} />
        <Route path='/accessories' component={Accessories} />
        <FooterMahindra />
        <ScrollUpButton />
      </main>
    </React.Fragment>
  );
}
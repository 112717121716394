import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { BASE_URI } from "../shared/Constants";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { DealerContext } from "../shared/contexts/DealerContext";
import { useHistory } from "react-router-dom";
import Carmac from '../shared/assets/image2M.png'
import DealsFilter from '../SpecialDeals/DealsFilter';
import Service from '../shared/assets/service.png'
import Card2 from '../shared/assets/card2.png'
import Card3 from '../shared/assets/card3.png'
import useMediaQuery from "../shared/useMediaQuery";

const useStyles = makeStyles(() => ({
  root: {
    background: '#eeeeee',
  },
  img: {
    height: "100%",
    maxHeight: "160px",
    margin: '10px 0px',
    transition: '0.5s ease all',
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.1)"
    },
  },
  list: {
    width: 'calc(100% / 2);',
    textAlign: "left"
  },
  MainImg: {
    filter: 'brightness(0.7)',
  },
  BannerCarmac: {
    position: 'relative',
    display: 'flex',
    alignItems: 'end',
    backgroundSize: 'cover',
  },
  vCardHeadingOutter: {
    fontSize: '40px',
    width: '80%',
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    margin: '40px auto',
    textAlign: 'center'
  },
  vCardHeadingOutterC: {
    fontSize: '40px',
    width: '80%',
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    margin: '40px auto',
    textAlign: 'center'
  },
  vCardHeadingOutterHolder: {
    position: 'relative'
  },
  vCardHeadingOutterHolderMain: {
    margin: '40px 0px',
  },
  vCardHolder: {
    overflow: "hidden",
    width: '80%',
    margin: '0 auto'
  },
  vCardHolderInner: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    transition: 'all 0.5s ease',
    transform: ({ slidePosition }) => (!slidePosition == 0 ? `translateX(${slidePosition}%)` : `translateX(0%)`),
    "@media (max-width: 600px)": {
      transform: ({ slidePosition }) => (!slidePosition <= 0 ? `translateX(${slidePosition * 3}%)` : `translateX(0%)`),
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      transform: ({ slidePosition }) => (!slidePosition <= 0 ? `translateX(${slidePosition * 3}%)` : `translateX(0%)`),
    },
  },
  vCardHolderInnerUsed: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    transition: 'all 0.5s ease',
    transform: ({ slidePositionUsed }) => (!slidePositionUsed == 0 ? `translateX(${slidePositionUsed}%)` : `translateX(0%)`),
    "@media (max-width: 600px)": {
      transform: ({ slidePositionUsed }) => (!slidePositionUsed <= 0 ? `translateX(${slidePositionUsed * 3}%)` : `translateX(0%)`),
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      transform: ({ slidePositionUsed }) => (!slidePositionUsed <= 0 ? `translateX(${slidePositionUsed * 3}%)` : `translateX(0%)`),
    },
  },
  vCardHolderInnerSpecials: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    transition: 'all 0.5s ease',
    transform: ({ slidePositionSpecials }) => (!slidePositionSpecials == 0 ? `translateX(${slidePositionSpecials}%)` : `translateX(0%)`),
    "@media (max-width: 600px)": {
      transform: ({ slidePositionSpecials }) => (!slidePositionSpecials <= 0 ? `translateX(${slidePositionSpecials * 3}%)` : `translateX(0%)`),
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      transform: ({ slidePositionSpecials }) => (!slidePositionSpecials <= 0 ? `translateX(${slidePositionSpecials * 3}%)` : `translateX(0%)`),
    },
  },
  vCard: {
    minWidth: "calc(100% / 3 - 80px)",
    margin: '0px 40px',
    borderRadius: '10px',
    padding: '20px',
    "@media (max-width: 600px)": {
      minWidth: "calc(100% / 1 - 20px)",
      margin: '0px 10px',
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      minWidth: "calc(100% / 2 - 20px)",
      margin: '0px 10px',
    },
  },
  vCardUsed: {
    minWidth: "calc(100% / 3 - 80px)",
    margin: '0px 40px',
    background: '#d9d9d9',
    borderRadius: '10px',
    lineHeight: '0px',
    "@media (max-width: 600px)": {
      minWidth: "calc(100% / 1 - 20px)",
      margin: '0px 10px',
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      minWidth: "calc(100% / 2 - 20px)",
      margin: '0px 10px',
    },
  },
  vCardUsedTextHolder: {
    lineHeight: "20px",
    padding: '20px',
  },
  vCardHeading: {
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    fontSize: '30px',
  },
  vCardButton: {
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    padding: "10px 40px",
    borderRadius: '50px',
    border: 'none',
    margin: "10px 0px",
    color: 'white',
    cursor: 'pointer'
  },
  vCardButtonOutline: {
    border: ({ websiteColors }) => `2px soild ${websiteColors.primaryColor}`,
    padding: "10px 40px",
    borderRadius: '50px',
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    margin: "10px 0px",
    cursor: 'pointer'
  },
  vCardButtonNav: {
    border: ({ websiteColors }) => `2px soild ${websiteColors.primaryColor}`,
    padding: "10px 10px",
    borderRadius: '50px',
    margin: "10px 0px",
    fill: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    cursor: 'pointer'
  },
  vCardButtonNavMain: {
    padding: "10px 10px",
    borderRadius: '50px',
    margin: "10px 0px",
    fill: ({ websiteColors }) => `${websiteColors.accentColor}`,
    cursor: 'pointer'
  },
  vCardButtonNavFlex: {
    display: 'flex',
    width: '80px'
  },
  vCardButtonNavFlexMain: {
    display: 'flex',
    width: '80px',
    bottom: '0px',
    right: '0px',
    position: 'absolute'
  },
  vCardButtonNavHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '40px 0px',
  },
  slideHolder: {
    position: 'relative',
    height: 'calc(100% - 99px)',
    '@media (max-width: 425px)': {
      marginTop: '0px',
    },
  },
  vCardHeading: {
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    fontSize: '30px',
  },
  vCardHeadingUsed: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    lineHeight: "30px",
    padding: "10px",
    color: 'white',
    fontSize: '25px',
  },
  vCardHeadingUsedAccent: {
    fontStyle: 'italic',
    color: 'white',
    fontSize: '14px',
    margin: '0px'
  },
  vCardButton: {
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    padding: "10px 40px",
    borderRadius: '50px',
    border: 'none',
    margin: "10px 0px",
    color: 'white',
    cursor: 'pointer'
  },
  vCardPriceBottom: {
    color: 'grey',
    fontSize: '14px',
    paddingTop: '10px',
    fontWeight: 'bold',
    margin: "0px"
  },
  vCardPriceUsed: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: "0px",
    paddingBottom: '30px',
  },
  vCardUsedDealer: {
    borderBottom: ({ websiteColors }) => ` 2px solid ${websiteColors.primaryColor}`,
    padding: '5px 0px',
    width: '50%',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: "0px",
  },
  interestedInFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '@media (max-width: 425px)': {
      flexDirection: 'column'
    },
  },
  interestedInFlexItm: {
    width: 'calc(100% / 3 - 80px)',
    margin: "0px 40px",
    transition: 'all 0.5s ease-in',
    lineHeight: '0px',
    '&:hover': {
      transform: 'scale(1.05)',
    },
    '@media (max-width: 613px)': {
      width: 'unset',
      margin: "20px 40px ",
    },
    '@media (min-width: 614px) and (max-width: 1023px)': {
      width: 'calc(100% / 2 - 40px)',
      margin: "20px 20px ",
    },
  },
  interestedInFlexItmTextHolder: {
    background: '#d9d9d9',
    textAlign: 'center',
    padding: '10px',
    lineHeight: '40px',
  },
  interestedInFlexItmHeading: {
    margin: '0',
    fontSize: '30px',
  },
  interestedInFlexItmCta: {
    margin: '0',
  },
}));

const HomeComponentMazda = () => {
  const history = useHistory();
  const { vehiclesList, newVehiclesList } = useContext(VehicleContext);
  const { globalDealer, websiteColors } = useContext(DealerContext)
  const [slides, setSlides] = useState();
  const [slidesLoading, setSlidesLoading] = useState(true);
  const [slideIndexMain, setSlideIndexMain] = useState(0);
  const mountedRef = useRef(true);
  const [slidePosition, setSlidePosition] = useState(0)
  const [slidePositionUsed, setSlidePositionUsed] = useState(0)
  const [slidePositionSpecials, setSlidePositionSpecials] = useState(0)
  const sortDirection = 'Desc';
  const pageNumber = 1;
  const [slideIndex, setSlideIndex] = useState(0)
  const classes = useStyles({ websiteColors, slidePosition, slidePositionUsed, slidePositionSpecials });
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 1333px)');
  const isDesktop = useMediaQuery('(min-width: 1334px)');
  const { deals } = DealsFilter(pageNumber, sortDirection)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getSlides = async () => {
      try {
        const params = {
          pageNumber: 0,
          pageSize: 100,
          status: "active",
          dealerId: globalDealer.id
        };

        const result = await axios({
          method: "GET",
          url: `${BASE_URI}/slide`,
          params,
          cancelToken: source.token,
        });

        let tempSlides = result.data.list.map((itm) => {
          return {
            img: itm.slideImageUrl,
            isVideo: itm.isVideo,
            targetUrl: itm.url
          };
        });
        setSlides(tempSlides);
        setSlidesLoading(false)
      } catch (error) {
        console.warn('Failed fetching slides.', error);
      }
    };

    getSlides().then(() => {
      if (!mountedRef.current) return null;
    });

    return () => {
      mountedRef.current = false;
      source.cancel();
    };
  }, []);

  const handleRedirect = (data) => {
    history.push(`/show-room/${data?.make}/${data?.ownedModelId}`, data)
  };

  const handleBack = () => {
    if (slidePosition < 0) {
      if (isDesktop) {
        setSlidePosition(slidePosition + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }

      if (isMobile) {
        setSlidePosition(slidePosition + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }

      if (isTablet) {
        setSlidePosition(slidePosition + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }
    }
  }

  const handleBackSpecial = () => {
    if (slidePosition < 0) {
      if (isDesktop) {
        setSlidePositionSpecials(slidePositionSpecials + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }

      if (isMobile) {
        setSlidePositionSpecials(slidePositionSpecials + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }

      if (isTablet) {
        setSlidePositionSpecials(slidePositionSpecials + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }
    }
  }

  const handleBackUsed = () => {
    if (slidePosition < 0) {
      if (isDesktop) {
        setSlidePositionUsed(slidePositionUsed + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }

      if (isMobile) {
        setSlidePositionUsed(slidePositionUsed + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }

      if (isTablet) {
        setSlidePositionUsed(slidePositionUsed + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }
    }
  }

  const handleNext = () => {
    if (isDesktop) {
      if (slideIndex < newVehiclesList.length - 3) {
        setSlidePosition(slidePosition - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === newVehiclesList.length - 3) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }

    if (isMobile) {
      if (slideIndex < newVehiclesList.length - 1) {
        setSlidePosition(slidePosition - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === newVehiclesList.length - 1) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }

    if (isTablet) {
      if (slideIndex < newVehiclesList.length - 3) {
        setSlidePosition(slidePosition - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === newVehiclesList.length - 3) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }

  }
  const handleNextSpecial = () => {
    if (isDesktop) {
      if (slideIndex < deals.length - 3) {
        setSlidePositionSpecials(slidePositionSpecials - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === deals.length - 3) {
        setSlidePositionSpecials(0)
        setSlideIndex(0)
      }
    }

    if (isMobile) {
      if (slideIndex < deals.length - 1) {
        setSlidePositionSpecials(slidePositionSpecials - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === deals.length - 1) {
        setSlidePositionSpecials(0)
        setSlideIndex(0)
      }
    }

    if (isTablet) {
      if (slideIndex < deals.length - 3) {
        setSlidePositionSpecials(slidePositionSpecials - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === deals.length - 3) {
        setSlidePositionSpecials(0)
        setSlideIndex(0)
      }
    }
  }

  const handleRedirectSpecial = (data) => {
    history.push(`/special-deals/${data?.make}/${data?.id}`, data)
  }

  const handleNextUsed = () => {
    if (isDesktop) {
      if (slideIndex < vehiclesList.length - 3) {
        setSlidePositionUsed(slidePositionUsed - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === vehiclesList.length - 3) {
        setSlidePositionUsed(0)
        setSlideIndex(0)
      }
    }

    if (isMobile) {
      if (slideIndex < vehiclesList.length - 1) {
        setSlidePositionUsed(slidePositionUsed - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === vehiclesList.length - 1) {
        setSlidePositionUsed(0)
        setSlideIndex(0)
      }
    }

    if (isTablet) {
      if (slideIndex < vehiclesList.length - 3) {
        setSlidePositionUsed(slidePositionUsed - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === vehiclesList.length - 3) {
        setSlidePositionUsed(0)
        setSlideIndex(0)
      }
    }
  }

  const handleRedirectUsed = (data) => {
    history.push(`/pre-owned/${data?.brand}/${data?.stockId}`, data)
  };

  const handleNextSlide = () => {
    if (slideIndexMain < slides.length - 1) {
      setSlideIndexMain(slideIndexMain + 1)
    }
    if (slideIndexMain === slides.length - 1) {
      setSlideIndexMain(0)
    }
  };
  const handleBackSlide = () => {
    if (slideIndexMain > 0) {
      setSlideIndexMain(slideIndexMain - 1)
    }
  };

  const handleCarRedirect = (type) => {
    switch (type) {
      case "Service":
        history.push(`/service`)
        break;
      case "New Vehicles":
        history.push(`/show-room`)
        break;
      case "Lastest News":
        history.push(`/pre-owned`)
        break;
      default:
        break;
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.slideHolder}>
        {!slidesLoading &&
          <img src={slides[slideIndexMain]?.img} width="100%" className={classes.MainImg} />
        }
        <div className={classes.vCardButtonNavFlexMain}>
          <svg className={classes.vCardButtonNavMain} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleBackSlide() }}><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
          <svg className={classes.vCardButtonNavMain} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleNextSlide() }}><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
        </div>
      </div>
      <div className={classes.vCardHeadingOutterHolderMain}  >
        <div className={classes.vCardHeadingOutterHolder}>
          <h1 className={classes.vCardHeadingOutter}>
            MAZDA VEHICLE RANGE
          </h1>
        </div>
        <div className={classes.vCardHolder}>
          <div className={classes.vCardHolderInner}>
            {newVehiclesList.map((v) => {
              return (
                <div className={classes.vCard} onClick={() => {
                  handleRedirect(v);
                }} >
                  <div>
                    <img src={v.image} width="100%" />
                  </div>
                  <div className={classes.vCardHeading}>
                    {v.title}
                  </div>
                </div>
              )
            })}
          </div>
          <div className={classes.vCardButtonNavHolder}>
            <Link
              to="/used-vehicles"
            >
              <button className={classes.vCardButtonOutline}>VIEW ALL</button>
            </Link>
            <div className={classes.vCardButtonNavFlex}>
              <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleBack() }}><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
              <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleNext() }}><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.vCardHeadingOutterHolderMain}  >
        <div className={classes.vCardHeadingOutterHolder}>
          <h1 className={classes.vCardHeadingOutter}>
            LATEST SPECIALS
          </h1>
        </div>
        <div className={classes.vCardHolder}>
          <div className={classes.vCardHolderInnerSpecials}>
            {deals.map((v) => {
              return (
                <div className={classes.vCard} onClick={() => {
                  handleRedirectSpecial(v);
                }} >
                  <div>
                    <img src={v.imageUrl} width="100%" />
                  </div>
                </div>
              )
            })}
          </div>
          <div className={classes.vCardButtonNavHolder}>
            <div>
            </div>
            <div className={classes.vCardButtonNavFlex}>
              <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleBackSpecial() }}><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
              <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleNextSpecial() }}><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.vCardHeadingOutterHolderMain}  >
        <div className={classes.vCardHeadingOutterHolder}>
          <h1 className={classes.vCardHeadingOutterC}>
            BROWSE PRE-OWNED VEHICLES
          </h1>
        </div>
        <div className={classes.vCardHolder}>
          <div className={classes.vCardHolderInnerUsed}>
            {vehiclesList?.map((v) => {
              return (
                <div className={classes.vCardUsed} onClick={() => {
                  handleRedirectUsed(v);
                }}>
                  <div>
                    <img src={v.image} width="100%" />
                  </div>
                  <div className={classes.vCardHeadingUsed}>
                    <h1 className={classes.vCardHeadingUsedAccent}>  {v.brand}</h1>
                    <div className={classes.vCardHeadingUsedAccentModel}> R {v.price}</div>
                  </div>
                  <div className={classes.vCardUsedTextHolder}>
                    <div className={classes.vCardFlexUsed}>
                      <h1 className={classes.vCardPriceUsed}>
                        {v.title}
                      </h1>
                      <p className={classes.vCardUsedDealer}>
                        {v.dealershipName}
                      </p>
                      <h1 className={classes.vCardPriceBottom}>
                        {v.year} | {v.mileage} km | {v.transmission}| {v.fuelType}
                      </h1>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className={classes.vCardButtonNavHolder}>
            <Link
              to="/used-vehicles"
            >
              <button className={classes.vCardButtonOutline}>VIEW ALL</button>
            </Link>
            <div className={classes.vCardButtonNavFlex}>
              <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleBackUsed() }}><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
              <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleNextUsed() }}><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.vCardHeadingOutterHolderMain}  >
        <div className={classes.vCardHolder}  >
          <div className={classes.vCardHeadingOutterHolder}>
            <h1 className={classes.vCardHeadingOutterC}>
              YOU MAY BE INTERESTED IN
            </h1>
          </div>
          <div className={classes.interestedInFlex}>
            <div className={classes.interestedInFlexItm} onClick={() => { handleCarRedirect("Service") }}>
              <img src={Service} width="100%" />
              <div className={classes.interestedInFlexItmTextHolder}>
                <h2 className={classes.interestedInFlexItmHeading}>Service</h2>
                <p >Book your next service at Mazda.</p>
                <h2 className={classes.interestedInFlexItmCta}>LEARN MORE</h2>
              </div>
            </div>
            <div className={classes.interestedInFlexItm} onClick={() => { handleCarRedirect("New Vehicles") }}>
              <img src={Card2} width="100%" />
              <div className={classes.interestedInFlexItmTextHolder}>
                <h2 className={classes.interestedInFlexItmHeading}>New Vehicles</h2>
                <p >Browse the entire Mazda new vehicle range.</p>
                <h2 className={classes.interestedInFlexItmCta}>LEARN MORE</h2>
              </div>
            </div>
            <div className={classes.interestedInFlexItm} onClick={() => { handleCarRedirect("Lastest News") }}>
              <img src={Card3} width="100%" />
              <div className={classes.interestedInFlexItmTextHolder}>
                <h2 className={classes.interestedInFlexItmHeading}>Lastest News</h2>
                <p >View the lastest Mazda news and stay informed</p>
                <h2 className={classes.interestedInFlexItmCta}>LEARN MORE</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.BannerCarmac}>
        <img src={Carmac} width="100%" />
      </div>
    </div>
  );
};

export default HomeComponentMazda;


import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URI, PAGE_SIZE } from '../shared/Constants'
import { DealerContext } from '../shared/contexts/DealerContext'

export default function DealsFilter(pageNumber, sortDirection) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [deals, setDeals] = useState([])
  const [hasMore, setHasMore] = useState(false)
  let { globalDealer } = useContext(DealerContext);
  let currentDate = new Date()
  let params = {}

  useEffect(() => {
    setDeals([])
  }, [sortDirection])

  useEffect(() => {
    let cancel
    setLoading(true)
    setError(false)

    params = {
      pageNumber,
      pageSize: PAGE_SIZE,
      status: 'active',
      dealerId: globalDealer.id,
    }

    axios({
      method: 'GET',
      url: `${BASE_URI}/Offers`,
      params,
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then((response) => {
      setHasMore(response.data?.list.length > 0)
      setLoading(false)
      setDeals(response.data?.list)
    }).catch(error => {
      if (axios.isCancel(error)) return
      setLoading(false)
    })
    return () => cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, sortDirection])

  return { loading, error, deals, hasMore }
}
import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { Link, useHistory } from "react-router-dom";
import FacebookIcon from '@mui/icons-material/Facebook';
import { BASE_URI } from '../shared/Constants';
import axios from 'axios';
import { DealerContext } from '../shared/contexts/DealerContext';
import { VehicleContext } from '../shared/contexts/VehicleContext';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: 'center',
    margin: '0px 0px',
    flexDirection: 'column',
    marginBottom: '0px',
    padding: '40px 100px',
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    '@media (min-width:0px) and (max-width:768px)': {
      padding: '20px 15px'
    }
  },
  innerHolder: {
    width: "100%",
    justifyContent: 'space-evenly',
    display: "flex",
    flexDirection: 'row',
    '@media (min-width:0px) and (max-width:768px)': {
      flexDirection: 'column'
    }
  },
  section: {
    width: 'calc(100%/3)',
    '@media (min-width:0px) and (max-width:768px)': {
      width: "100%",
      padding: '0px 15px'
    }
  },
  GroupedSection: {
    display: 'flex',
    width: 'calc(100%/3)',
    flexDirection: 'column',
    '@media (min-width:0px) and (max-width:768px)': {
      width: "100%",
      padding: '0px 15px'
    }
  },
  heading: {
    color: 'white',
    marginTop: '21px',
    marginBottom: '20px',
    fontSize: '16px',
  },
  headingText: {
    fontSize: '16px!important',
    margin: '10px 0px',
    color: 'white',
  },
  headingTextP: {
    fontSize: '16px!important',
    margin: '10px 0px',
    color: 'white',
    padding: '0px 25px',
    paddingLeft: '0px',
  },
  socialLinkHolder: {
    display: 'flex'
  },
  socialLink: {
    width: '50px!important',
    color: 'black!important',
    '@media (min-width:769px) and (max-width:1024px)': {
      width: '50px!important',
    }
  },
  socialLinkIcon: {
    width: '80%!important',
    height: '80%!important',
  },
  LegalSection: {
    borderTop: '2px solid grey',
    paddingTop: '20px',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    color: 'white',
    '@media (min-width:0px) and (max-width:616px)': {
      flexDirection: 'column',
    }
  },
  LegalSectionG: {
    display: 'flex',
    color: 'white',
    '@media (min-width:0px) and (max-width:616px)': {
      flexDirection: 'column',
    }
  }
}))

const FooterMazda = () => {
  const { globalDealer, websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });
  const { newVehiclesList } = useContext(VehicleContext);
  const history = useHistory();

  const handleRedirect = (data) => {
    history.push(`/show-room/${data?.make}/${data?.ownedModelId}`, data)
  };

  return (
    <div className={classes.root}>
      <div className={classes.innerHolder}>
        <div className={classes.section}>
          <Typography variant='h1' className={classes.heading} >
            <strong>VEHICLES</strong>
          </Typography>
          {newVehiclesList.map((v) => {
            return (
              <Typography
                variant='h1'
                className={classes.headingText}
                onClick={() => {
                  handleRedirect(v)
                }}
              >
                {v.title}
              </Typography>
            )
          })}
        </div>
        <div className={classes.section}>
          <Typography variant='h1' className={classes.heading} >
            <strong>ENQUIRE</strong>
          </Typography>
          <Link to="/book-a-testdrive">
            <Typography variant='h1' className={classes.headingText} >
              Book a Test Drive
            </Typography>
          </Link>
          <Link to="/service">
            <Typography variant='h1' className={classes.headingText} >
              Book a Service
            </Typography>
          </Link>
          <Link to="/parts">
            <Typography variant='h1' className={classes.headingText} >
              Parts Enquiry
            </Typography>
          </Link>
          <Link to="/special-deals">
            <Typography variant='h1' className={classes.headingText} >
              Promotions
            </Typography>
          </Link>
        </div>
        <div className={classes.section}>
          <Typography variant='h1' className={classes.heading} >
            <strong>CONTACT US</strong>
          </Typography>
          <Link to="/contact-us">
            <Typography variant='h1' className={classes.headingText} >
              Contact Us
            </Typography>
          </Link>
        </div>
        <div className={classes.GroupedSection}>
          <div>
            <Typography variant='h1' className={classes.heading} >
              <strong>SOCIAL</strong>
            </Typography>
            <div className={classes.socialLinkHolder}>
              <div className={classes.socialLink}>
                <a href={globalDealer?.social?.facebook} target="_blank" style={{ color: 'white' }} rel="noreferrer">
                  <FacebookIcon className={classes.socialLinkIcon} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.LegalSection}>
        <div>
          Images for illustration purposes only.
        </div>
        <div className={classes.LegalSectionG}>
          <div>
            <Link to="/cookies">
              <Typography variant='h1' className={classes.headingTextP} >
                Cookies
              </Typography>
            </Link>
          </div>
          <div>
            <Link to="/privacy">
              <Typography variant='h1' className={classes.headingTextP} >
                Privacy Policy
              </Typography>
            </Link>
          </div>
          <div>
            <Link to="/requestergws">
              <Typography variant='h1' className={classes.headingTextP} >
                PAIA
              </Typography>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterMazda
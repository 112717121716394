import React, { useEffect, useState, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { BASE_URI } from "../shared/Constants";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { DealerContext } from "../shared/contexts/DealerContext";
import { useHistory } from "react-router-dom";
import ManCar from '../shared/assets/ManCar.png'
import useMediaQuery from "../shared/useMediaQuery";

const useStyles = makeStyles(() => ({
  root: {
    background: "#2f2f2f",
  },
  img: {
    height: "100%",
    maxHeight: "160px",
    margin: '10px 0px',
    transition: '0.5s ease all',
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.1)"
    },
  },
  list: {
    width: 'calc(100% / 2);',
    textAlign: "left"
  },
  MainImg: {
    filter: 'brightness(0.7)',
  },
  vCardHeadingOutter: {
    fontSize: '40px',
    width: '80%',
    color: 'white',
    margin: '0px auto',
    paddingBottom: '40px',
  },
  vCardHeadingOutterAcc: {
    fontSize: '40px',
    paddingBottom: '5px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    borderBottom: '4px solid white',
  },
  vCardHeadingOutterHolder: {
    position: 'relative'
  },
  vCardHeadingOutterHolderFlex: {
    display: 'flex',
    marginRight: '0',
    marginLeft: '180px',
    '@media (max-width: 1000px)': {
      flexDirection: 'column',
      marginLeft: 'unset',
    }
  },
  vCardHeadingOutterHolderLeft: {
    width: '50%',
    '@media (max-width: 1000px)': {
      width: 'unset',
      padding: '0px 4em',
    }
  },
  vCardHeadingOutterHolderRight: {
    width: '50%',
    '@media (max-width: 1000px)': {
      width: 'unset',
      paddingLeft: '6em',
    }
  },
  vCardHeadingOutterHolderMain: {
    margin: '0px 0px',
    background: '#2f2f2f',
    padding: '40px 0px',
  },
  vCardHeadingOutterHolderMainB: {
    margin: '0px 0px',
    background: '    #191717',
    padding: '40px 0px',
  },
  vCardHolder: {
    overflow: "hidden",
    width: '80%',
    margin: '0 auto',
    position: 'relative'
  },
  vCardHolderInner: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    transition: 'all 0.5s ease',
    transform: ({ slidePosition }) => (!slidePosition == 0 ? `translateX(${slidePosition}%)` : `translateX(0%)`),
    "@media (max-width: 600px)": {
      transform: ({ slidePosition }) => (!slidePosition <= 0 ? `translateX(${slidePosition * 3}%)` : `translateX(0%)`),
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      transform: ({ slidePosition }) => (!slidePosition <= 0 ? `translateX(${slidePosition * 3}%)` : `translateX(0%)`),
    },
  },
  vCard: {
    minWidth: "calc(100% / 3 - 80px)",
    margin: '0px 40px',
    background: 'white',
    borderRadius: '20px',
    cursor: "pointer",
    padding: '20px',
    "@media (max-width: 600px)": {
      minWidth: "calc(100% / 1 - 20px)",
      margin: '0px 10px',
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      minWidth: "calc(100% / 2 - 20px)",
      margin: '0px 10px',
    },
  },
  vCardUsed: {
    minWidth: "calc(100% / 3 - 80px)",
    margin: '0px 40px',
    background: 'white',
    borderRadius: '20px',
    cursor: "pointer",
    padding: '0px',
    "@media (max-width: 600px)": {
      minWidth: "calc(100% / 1 - 20px)",
      margin: '0px 10px',
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      minWidth: "calc(100% / 2 - 20px)",
      margin: '0px 10px',
    },
  },
  vCardPriceMain: {
    margin: '0px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontSize: '20px',
    fontWeight: 'bold'
  },
  vCardFlexMain: {
    display: 'flex',
    textTransform: 'uppercase',
    alignItems: 'center',
  },
  vCardButton: {
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    padding: "10px 40px",
    borderRadius: '50px',
    border: 'none',
    margin: "10px 0px",
    color: 'white',
    cursor: 'pointer'
  },
  vCardButtonNav: {
    width: '50px',
    border: ({ websiteColors }) => `2px soild ${websiteColors.primaryColor}`,
    padding: "10px 10px",
    borderRadius: '50px',
    margin: "10px 0px",
    fill: ({ websiteColors }) => `${websiteColors.accentColor}`,
    cursor: 'pointer'
  },
  vCardButtonNavFlex: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  vCardButtonNavHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '40px 0px',
    position: 'absolute',
    top: '50%',
    width: '100%',
    transform: 'translate(0%, -50%)',
  },
  slideHolder: {
    position: 'relative',
    '@media (max-width: 425px)': {
      marginTop: '0px',
    },
  },
  vCardHeading: {
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    fontSize: '30px',
  },
  vCardHeadingA: {
    color: 'white',
    fontSize: '40px',
  },
  vCardHeadingText: {
    color: 'white',
    padding: '20px 0px',
    fontSize: '18px',
  },
  vCardFlexHolder: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10px',
  },
  vCardFlexHolderMain: {
    padding: '10px',
    paddingTop: '0px',
  },
  vCardFlexItem: {
    textAlign: 'center',
    padding: "0px 10px",
  },
  vCardButton: {
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    padding: "10px 20px",
    borderRadius: '50px',
    border: 'none',
    margin: "10px 0px",
    color: 'white',
    cursor: 'pointer'
  },
  vCardPriceUsed: {
    background: ({ websiteColors }) => `${websiteColors.accentColor}`,
    color: "white",
    padding: '0px 20px',
    width: 'fit-content',
    fontSize: '40px',
    fontWeight: 'bold',
    margin: "10px",
    paddingTop: '0px',
    marginBottom: '10px',
    marginTop: '0px',
  },
  vCardFlexHolderMainPerMonth: {
    margin: "20px",
    paddingTop: '0px',
    marginBottom: '10px',
    marginTop: '0px',
  },
  vCardFlexHolderMainPerMonthText: {
    margin: "10px 0px",
  },
  vCardPriceUsedImgHolder: {
    lineHeight: '0px',
  },
  vCardPriceUsedImg: {
    borderRadius: '20px 20px 0px 0px'
  },
  vCardButtonNavFlexMain: {
    display: 'flex',
    width: '80px',
    bottom: '0px',
    right: '0px',
    position: 'absolute'
  },
  vCardButtonNavMain: {
    padding: "10px 10px",
    borderRadius: '50px',
    margin: "10px 0px",
    fill: ({ websiteColors }) => `${websiteColors.accentColor}`,
    cursor: 'pointer'
  },
}));

const HomeComponentMahindra = () => {
  const history = useHistory();
  const { vehiclesList, newVehiclesList } = useContext(VehicleContext);
  const { globalDealer, websiteColors } = useContext(DealerContext)
  const [slides, setSlides] = useState();
  const mountedRef = useRef(true);
  const [slidePosition, setSlidePosition] = useState(0)
  const [slideIndex, setSlideIndex] = useState(0)
  const classes = useStyles({ websiteColors, slidePosition });
  const [slidesLoading, setSlidesLoading] = useState(true);
  const [slideIndexMain, setSlideIndexMain] = useState(0);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 1333px)');
  const isDesktop = useMediaQuery('(min-width: 1334px)');

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getSlides = async () => {
      try {
        const params = {
          pageNumber: 0,
          pageSize: 100,
          status: "active",
          dealerId: globalDealer.id
        };

        const result = await axios({
          method: "GET",
          url: `${BASE_URI}/slide`,
          params,
          cancelToken: source.token,
        });

        let tempSlides = result.data.list.map((itm) => {
          return {
            img: itm.slideImageUrl,
            isVideo: itm.isVideo,
            targetUrl: itm.url
          };
        });

        setSlides(tempSlides);
        setSlidesLoading(false)
      } catch (error) {
        console.warn('There was an error fetching slides.', error);
      }
    };

    getSlides().then(() => {
      if (!mountedRef.current) return null;
    });

    return () => {
      mountedRef.current = false;
      source.cancel();
    };
  }, []);

  const handleRedirect = (data) => {
    history.push(`/show-room/${data?.make}/${data?.ownedModelId}`, data)
  };

  const handleBack = () => {
    if (slidePosition < 0) {
      if (isDesktop) {
        setSlidePosition(slidePosition + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }

      if (isMobile) {
        setSlidePosition(slidePosition + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }

      if (isTablet) {
        setSlidePosition(slidePosition + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }
    }
  }

  const formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 2
  });

  const handleNext = () => {
    if (isDesktop) {
      if (slideIndex < newVehiclesList.length - 3) {
        setSlidePosition(slidePosition - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === newVehiclesList.length - 3) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }

    if (isMobile) {
      if (slideIndex < newVehiclesList.length - 1) {

        setSlidePosition(slidePosition - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === newVehiclesList.length - 1) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }

    if (isTablet) {
      if (slideIndex < newVehiclesList.length - 3) {
        setSlidePosition(slidePosition - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === newVehiclesList.length - 3) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }

  }

  const handleRedirectUsed = (data) => {
    history.push(`/pre-owned/${data?.brand}/${data?.stockId}`, data)
  };

  const handleNextSlide = () => {
    if (slideIndexMain < slides.length - 1) {
      setSlideIndexMain(slideIndexMain + 1)
    }
    if (slideIndexMain === slides.length - 1) {
      setSlideIndexMain(0)
    }
  };

  const handleBackSlide = () => {
    if (slideIndexMain > 0) {
      setSlideIndexMain(slideIndexMain - 1)
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.slideHolder}>
        {!slidesLoading &&
          <img src={slides[slideIndexMain]?.img} width="100%" className={classes.MainImg} />
        }
        <div className={classes.vCardButtonNavFlexMain}>
          <svg className={classes.vCardButtonNavMain} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleBackSlide() }}><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
          <svg className={classes.vCardButtonNavMain} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleNextSlide() }}><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
        </div>
      </div>
      <div className={classes.vCardHeadingOutterHolderMain}  >
        <div className={classes.vCardHeadingOutterHolder}>
          <h1 className={classes.vCardHeadingOutter}>
            <span className={classes.vCardHeadingOutterAcc}>RANGE</span> OF VEHICLES
          </h1>
        </div>
        <div className={classes.vCardHolder}>
          <div className={classes.vCardHolderInner}>
            {newVehiclesList.map((v) => {
              return (
                <div className={classes.vCard} onClick={() => { handleRedirect(v) }}>
                  <div >
                    <img src={v.image} width="100%" />
                  </div>
                  <div className={classes.vCardHeading}>
                    {v.title}
                  </div>
                  <div className={classes.vCardFlexMain}>
                    <h1 className={classes.vCardPriceMain}>
                      From{' '}{formatter.format(v.price)}
                    </h1>
                  </div>
                </div>
              )
            })}
          </div>
          <div className={classes.vCardButtonNavHolder}>
            <div className={classes.vCardButtonNavFlex}>
              <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleBack() }}><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
              <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleNext() }}><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={classes.vCardHeadingOutterHolderFlex}>
          <div className={classes.vCardHeadingOutterHolderLeft}>
            <h1 className={classes.vCardHeadingA}>
              <span className={classes.vCardHeadingOutterAcc}>ABOUT</span> MAHINDRA SOUTH AFRICA
            </h1>
            <p className={classes.vCardHeadingText}>
              What began as a steel-trading venture in 1945, steadily turned into a global brand, spanning nations and industries. This is a story with an upward curve, narrating how an Indian company paved its way to become a global powerhouse.
              Mahindra SA was established in 1996, bringing both our global name and expertise to South Africa. Since then, we have become the country’s fastest growing passenger vehicle and bakkie brand. Our success also led to the opening of our first local assembly plant in 2018.
              We strive to continue to make our Mahindra mark, offering the people of South Africa world-class SUV, bakkie and commercial vehicles.
            </p>
          </div>
          <div className={classes.vCardHeadingOutterHolderRight}>
            <img src={ManCar} width="100%" />
          </div>
        </div>
      </div>
      <div className={classes.vCardHeadingOutterHolderMainB}  >
        <div className={classes.vCardHeadingOutterHolder}>
          <h1 className={classes.vCardHeadingOutter}>
            <span className={classes.vCardHeadingOutterAcc}>USED</span> CARS
          </h1>
        </div>
        <div className={classes.vCardHolder}>
          <div className={classes.vCardHolderInner}>
            {vehiclesList?.map((v) => {
              return (
                <div className={classes.vCardUsed} onClick={() => {
                  handleRedirectUsed(v);
                }}>
                  <div className={classes.vCardPriceUsedImgHolder} >
                    <img className={classes.vCardPriceUsedImg} src={v.image} width="100%" />
                  </div>
                  <div className={classes.vCardFlexUsed}>
                    <div className={classes.vCardFlexHolderMain}>
                      <h1 className={classes.vCardPriceUsed}>
                        R {v.price}
                      </h1>
                      <div className={classes.vCardFlexHolderMainPerMonth}>
                        <h1 className={classes.vCardFlexHolderMainPerMonthText}>
                          R {(v.price / 12).toFixed(0)} p/m
                        </h1>
                        <div>
                          {v.title}
                        </div>
                        <div>
                          {v.dealershipName}
                        </div>
                      </div>
                      <div className={classes.vCardFlexHolder}>
                        <div className={classes.vCardFlexItem} >
                          {v.newUsed}
                        </div>
                        <div className={classes.vCardFlexItem} >
                          {v.year}
                        </div>
                        <div className={classes.vCardFlexItem} >
                          {v.mileage} km
                        </div>
                        <div className={classes.vCardFlexItem} >
                          {v.transmission}
                        </div>
                        <div className={classes.vCardFlexItem} >
                          {v.fuelType}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className={classes.vCardButtonNavHolder}>
            <div className={classes.vCardButtonNavFlex}>
              <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleBack() }}><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
              <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleNext() }}><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeComponentMahindra;


import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { DealerContext } from "../shared/contexts/DealerContext";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "100px 15px",
    '@media (max-width: 500px)': {
      display: "block",
    },
  },
  inner: {
    maxWidth: "1170px",
  },
  textIntro: {
    fontSize: "14px",
    padding: "10px 0px",
    marginBottom: "0px",
    display: "block",
  },
  textIntroHeadline: {
    fontSize: "18px",
    fontWeight: "800",
    padding: "10px 0px 0px 0px;",
    marginBottom: "0px",
    display: "block",
  },
  section: {
    width: '50%',
    paddingRight: "15px"
  },
  sectionHolder: {
    display: 'flex'
  },
  titleTopAccent: {
    fontSize: '44px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold'
  }
}));

const AboutUsRise = () => {
  const { websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });

  return (
    <>
      <div className={classes.root}>
        <div className={classes.inner}>
          <Typography variant="h1" className={classes.titleTopAccent}>
            About Us
          </Typography>
          <div className={classes.sectionHolder}>
            <div className={classes.section}>
            <Typography variant="p" className={classes.textIntroHeadline}>Welcome</Typography>
              <Typography variant="p" className={classes.textIntro}>
              Welcome to Mahindra Agri Randfontein, a proud member of the esteemed Ben Morgenrood Group, dedicated to providing exceptional service and a comprehensive range of Mahindra Agri solutions to our valued customers.
              </Typography>

              <Typography variant="p" className={classes.textIntroHeadline}>Our Commitment:</Typography>
              <Typography variant="p" className={classes.textIntro}>
              At Mahindra Agri Randfontein, we prioritize your agricultural needs. As part of the Ben Morgenrood Group, we bring a fresh perspective and modern solutions to ensure your agricultural experience with Mahindra is unparalleled.
              </Typography>
            </div>
            <div className={classes.section}>
            <Typography variant="p" className={classes.textIntroHeadline}>Explore Our Solutions:</Typography>
              <Typography variant="p" className={classes.textIntro}>
              Visit our facility to explore the latest Mahindra Agri solutions, each representing advanced technology, durability, and efficiency. Whether you're a farmer, rancher, or agribusiness professional, our range caters to diverse agricultural needs.
              </Typography>

              <Typography variant="p" className={classes.textIntroHeadline}>On-Site Support:</Typography>
              <Typography variant="p" className={classes.textIntro}>
              From routine maintenance to genuine parts, our team is here to keep your Mahindra Agri equipment running at its best.Experience the reliability of Mahindra Agri at Mahindra Agri Randfontein, where agricultural innovation meets the excellence of the Ben Morgenrood Group.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsRise;

import Tiller from '../shared/assets/Implements/Alabora Orchard Type Rotary Tiller.png'
import Cultivator from '../shared/assets/Implements/Cultivator.png'
import Chisel from '../shared/assets/Implements/Deep Chisel.png'
import Harrow from '../shared/assets/Implements/Disc Harrow.png'
import GobleDiscHarrow from '../shared/assets/Implements/Goble Disc Harrow.png'
import HorizontalFeedMixer from '../shared/assets/Implements/Horizontal Feed Mixer.png'
import InterRowRotaryTiller from '../shared/assets/Implements/Inter-Row Rotary Tiller.png'
import PowerHarrow from '../shared/assets/Implements/Power Harrow.png'
import RotaryTiller from '../shared/assets/Implements/Rotary Tiller.png'

export let Implements = [
    {
        image:Tiller,
        title:'Orchard Rotary Tiller',
        desc1:'Thanks to the hydraulic shiſt system, the driver can easily command the machine from inside the tractor cab if the machine meets with an obstacle.',
        desc2:'ArmaTrac orchard type rotary tillers has are designed as heavy duty to work at all kind of conditions.',
        desc3:'ArmaTrac orchard type rotary tillers model with the sensor system is designed especially for the orchards to tillage between trees easily.',
        desc4:'ArmaTrac Orchard Type Rotary Tillers’ strong and durable blades are produced with the unique design and the rigid materials to work successfully in all kinds of soil conditions and it easily shreds and mixes the soil.',
        desc5:''
    },
    {
        image:Cultivator,
        title:'Cultivator',
        desc1:'The hydraulic piston for KV9 series is an optional feature. It provides easy working depth adjustment during the tillage.',
        desc2:'The blade ring rollers, which is standard for KV11 series, has 20pcs and 540mm diameter discs to shred clods (packed earth/harsh soil). The disks are mounted on AISI 1040 45mm diameter shaft.',
        desc3:'',
        desc4:'',
        desc5:''
    },
    {
        image:Chisel,
        title:'Deep Chisel',
        desc1:'Thanks to the hydraulic pistons in the Deep Chisel models, the working depth can be adjusted easily whilst driving to save time and labour force.',
        desc2:'It has a strong hull structure and is resistant to harsh soil conditions.',
        desc3:'Deep Chisels have an adjustable three-point linkage system with three different height settings.',
        desc4:'',
        desc5:''
    },
    {
        image:Harrow,
        title:'Disc Harrow',
        desc1:'With hydraulic pistons in the Disc Harrow models, the depth of the work can easily be adjusted whilst in operation. It has a strong connection system that connects to the three-point suspension arms.',
        desc2:'It has a strong body structure and is resistant to harsh soil conditions.',
        desc3:'',
        desc4:'',
        desc5:''
    },
    {
        image:GobleDiscHarrow,
        title:'Goble Disc Harrow',
        desc1:'The ArmaTrac Goble Disc Harrows provide high efficiency, low fuel consumption and, with its changeable battery angles, hydraulic working, carriage position selections and adjustable drawbar, flexibility for the farmers.',
        desc2:'It is possible to change the working angle of 2 disc batteries with a hydraulic system. The batteries turn to the opposite side of each other. It is possible to change the working angle up to 40°. During operation, any stress on the hydraulic system is blocked by the mechanical locking mechanism.',
        desc3:'',
        desc4:'',
        desc5:''
    },
    {
        image:HorizontalFeedMixer,
        title:'Horizontal Feed Mixer',
        desc1:'The main application of the machine is for the homogeneous mixing of different feed types for livestock.',
        desc2:'',
        desc3:'',
        desc4:'',
        desc5:''
    },
    {
        image:InterRowRotaryTiller,
        title:'Inter-Row Rotary Tiller',
        desc1:'Wide range of models available (15 different models)',
        desc2:'Widespread and experienced Service Network',
        desc3:'Widely available Spare Parts',
        desc4:'',
        desc5:''
    },
    {
        image:PowerHarrow,
        title:'Power Harrow',
        desc1:'Thanks to the friction clutch system used in upright models, sudden loads from the tractor during transmission of the tailpipe are prevented from damaging the transmission. Long-lasting and safe operation is provided at this vault.',
        desc2:'It has a strong body structure and is resistant to harsh soil conditions.',
        desc3:'',
        desc4:'',
        desc5:''
    },
    {
        image:RotaryTiller,
        title:'Rotary Tiller',
        desc1:'Wide range of models',
        desc2:'Strong, solid and durable body structure',
        desc3:'Long-lasting blades',
        desc4:'Widespread and experienced service network',
        desc5:'Availability of spare parts'
    },
]


import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URI, MASTER_CONFIGURATOR_DEALERID, DEALERID, MOTORGROUPID } from '../Constants.js'
import { DealerContext } from './DealerContext';

export const VehicleContext = createContext();

export const VehicleProvider = ({ children }) => {
  const [vehiclesList, setVehiclesList] = useState();
  const [brandedList, setBrandedList] = useState([]);
  const [newVehiclesList, setNewVehiclesList] = useState([]);
  const { globalDealer } = useContext(DealerContext)

  function removeDuplicates(data, key) {
    return [
      ...new Map(
        data.map(x => [key(x), x])
      ).values()
    ]
  }

  useEffect(() => {
    const params = {
      pageNumber: 1,
      pageSize: 1000,
    }
    if (globalDealer?.id !== DEALERID) {
      params.dealerId = globalDealer.id;
    } else {
      params.motorGroupId = MOTORGROUPID;
    }

    axios({
      method: 'GET',
      url: `${BASE_URI}/stock`,
      params,

    }).then((response) => {
      let data = response?.data?.list
      setVehiclesList(removeDuplicates(data, it => it.stockNo));
      setBrandedList(removeDuplicates(response.data?.list));
    }).catch(error => {
      if (axios.isCancel(error)) return
    })
  }, [])


  const getNewVehicles = async (dealerID, globalDealer) => {
    const params = {
      dealerId: dealerID,
      pageNumber: 1,
      pageSize: 1001,
    };

    return axios({
      method: 'GET',
      url: `${BASE_URI}/dealermodels`,
      params,
    }).then((response) => {
      let arr = []
      for (let index = 0; index < globalDealer?.makes.length; index++) {
        const element = globalDealer?.makes[index];
        arr.push(response.data?.list.filter((v) => v.makeId == element.id))
      }
      return (arr.flat().filter((v) => v.id !== 414)) //Removing Ford Ecosport from this dealer.
    }).catch(error => {
      if (axios.isCancel(error)) return
    })
  };

  const getAllNewVehicles = async (dealerID, globalDealer) => {
    let aModels = [];
    const masterVehicles = await getNewVehicles(MASTER_CONFIGURATOR_DEALERID, globalDealer);
    const dealerVehicles = await getNewVehicles(dealerID, globalDealer);
    if (masterVehicles || dealerVehicles) {
      setNewVehiclesList([...masterVehicles, ...dealerVehicles].filter((v) => {
        if (aModels.includes(v?.ownedModelId)) {
          return false
        } else {
          aModels.push(v?.ownedModelId);
          return true
        }
      }));
    }
  };



  useEffect(() => {
    getAllNewVehicles(globalDealer?.dealerId, globalDealer);
  }, [globalDealer])

  return (
    <VehicleContext.Provider value={{ vehiclesList, setVehiclesList, brandedList, newVehiclesList }}>
      {children}
    </VehicleContext.Provider>
  )
};